<div *ngIf="isAuthenticatedUser" class="userAdmin-page">

  <div class="md-content layout-padding background-grey1">

    <div style="display:flex; flex-direction: row-reverse;" class="layout-flex-row layout-padding" layout-align="end start" *ngIf="responsiveUI.isDesktop">
      <button class="md-button newUserButton" (click)="goToNewUserPage()">Add New User</button>
    </div>

    <div [ngStyle]="{'padding': (responsiveUI.isMobile) ? '10px' : '0px'}">

      <!--desktop and mobile search module-->
      <div flex class="dashboard-module hide-xs" *ngIf="(!totalRecords || (totalRecords > 0)) 
          && !(showResults && responsiveUI.isPortrait && responsiveUI.isMobile)">
        <div layout-align="center center" class="layout-flex-row dashboard-module-title-background">
          <h1 *ngIf="responsiveUI.isMobile && responsiveUI.isPortrait" class="h1-no-margins">User Search</h1>
          <h3 *ngIf="!responsiveUI.isMobile || !responsiveUI.isPortrait" class="dashboard-module-title-text-2">User Search</h3>
        </div>

        <div class="dashboard-module-body" [ngStyle]="{'padding-top': (responsiveUI.isMobile) ? '10px' : '0px'}">

            <div [ngClass]="{'layout-flex-column': responsiveUI.isMobile}" class="custom-input-container">

              <div class="layout-flex-row" layout-align="center center">
                <p style="flex: auto" class="layout-padding-r layout-padding-l layout-padding-t search-form-instructions">Ensure that
                  your search contains at least one of the following elements: First Name, Last Name, or Username.
                </p>
                <div class="align-right">
                  <button class="layout-padding-l-10 layout-padding-r align-right clear-button" type="button" (click)="clearSearchFields()" [disabled]="!search" tabindex="0" aria-label="Clear">
                    <i class="fa fa-times" aria-hidden="true"></i>
                    <span>Clear</span>
                  </button>
                </div>
              </div>
            
              <form name="student-search-form" id="student-search-form" style="margin-top: -14px;"
                [ngStyle]="{'padding-top': (responsiveUI.isMobile) ? '10px' : '0px'}">
                <!--div layout-gt-xs="row"-->
                <div [ngClass]="{'layout-flex-column': responsiveUI.isMobile, 'layout-flex-row': !responsiveUI.isMobile}">
                  <div class="layout-padding-r layout-padding-l" style="display: flex; flex-direction: column;" [ngClass]="{'non-mobile-text-width': !responsiveUI.isMobile}">
                    <custom-mat-input 
                      customlabel="First Name"
                      [(ngModel)]="search.firstName"
                      name="firstName"
                      [userform]="false"
                      (ngsubmit)="userSearch()">
                    </custom-mat-input>
                    <custom-mat-input 
                      customlabel="Last Name"
                      [(ngModel)]="search.lastName"
                      name="lastName"
                      [userform]="false"
                      (ngsubmit)="userSearch()">
                    </custom-mat-input>
                  </div>

                  <div class="layout-padding-l layout-flex-column" [ngClass]="{'non-mobile-text-width': !responsiveUI.isMobile, 'mobile-text-padding': responsiveUI.isMobile}">
                    <custom-mat-input 
                      customlabel="Username"
                      [(ngModel)]="search.username"
                      name="username"
                      [userform]="false"
                      (ngsubmit)="userSearch()">
                    </custom-mat-input>
                  </div>
                </div>

                <div layout="row" class="input-container-margins layout-padding-t" 
                  [ngClass]="{'no-top-bottom-right-margin-padding' : responsiveUI.isMobile, 'no-top-bottom-margin-padding': (!responsiveUI.isMobile)}" 
                  style="display: flex; flex-direction: row-reverse;" [ngStyle]="{'padding-top': (responsiveUI.isMobile) ? '10px' : '0px'}">
                  <button
                    class="md-button"
                    [ngClass]="{'no-top-bottom-right-margin-padding': responsiveUI.isMobile }"
                    style="margin: 6px 8px;"
                    type="Submit"
                    (click)="userSearch()"
                    [disabled]="!(search && (search.username || search.firstName || search.lastName))">Search
                  </button>
                </div>
              </form>

            </div>
        </div>
      </div>

      <!--using the following for testing purposes only-->
      <!--<p>Page Number {{pageNum}}</p>-->

      <!--mobile view search results (Portrait mode only) -->
      <div flex="100" *ngIf="(responsiveUI.isMobile && responsiveUI.isPortrait) && totalRecords > 0 && showResults">
        <h1 *ngIf="totalRecords > 1">{{totalRecords}}
          Results</h1>
        <h1 *ngIf="totalRecords === 1">{{totalRecords}}
          Result</h1>
        <a (click)="searchAgain()">
          <i class="fa fa-chevron-left" aria-hidden="true"></i>
          <span>Search Again</span>
        </a>
        <div class="nav-bar"></div>

        <div [ngClass]="{'results-border-save': (userIndex === indx), 'results-border': (userIndex !== indx)}" *ngFor="let user of userList; let indx = index">
          <div (click)="showUserDetailsFn(indx)" class="flexSpaceBetween" style="padding: 10px;">
            <div class="layout-flex-column">
              <label class="uppercase" style="font-weight: 700;" flex>{{user.lastName}}, {{user.firstName}}</label>
              <div flex>
                <p class="small small-grey2andahalf">Username:</p>
                <p class="small">{{user.username}}</p>
              </div>
              <div flex>
                <p class="small small-grey2andahalf">Email:</p>
                <p class="small">{{user.email}}</p>
              </div>
            </div>
            <div>
              <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(userIndex === indx)"></i>
              <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="userIndex === indx"></i>
            </div>
          </div>

          <div *ngIf="userIndex === indx">
            <div class="layout-flex-column">
              <div class="saveRecordRow layout-flex-row" layout-align="center center" style="justify-content: center; padding-bottom: 10px;">
                <div class="center">
                  <button class="md-button no-margins" (click)="resetPassword(user.username)" style="margin-bottom: 0px;">Reset Password</button>
                  <a (click)="viewDetails(user.username)">View User Details</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!--desktop/tablet/mobile(landscape) search results-->
      <!--no results found-->
      <div *ngIf="totalRecords === 0" class="center">
        <h1 flex>No results found</h1>
      </div>

      <div class="layout-margin-2" *ngIf="(responsiveUI.isDesktop || responsiveUI.isTablet || (responsiveUI.isMobile && !responsiveUI.isPortrait)) && totalRecords > 0">

        <div class="removeBorders">
          <div class="table tableHeader">
            <div layout="row" class="tableRow headerRow">
              <h1 *ngIf="totalRecords > 1">{{totalRecords}}
                Results</h1>
              <h1 *ngIf="totalRecords === 1">{{totalRecords}}
                Result</h1>
            </div>
          </div>
        </div>

        <div class="headerTitles layout-flex-row">
          <p class="block2 userCol" >User(s)</p>
          <p class="block2 userNameCol">Username</p>
          <p class="block2 userEmailCol">Email</p>
          <p class="block2 userStatusCol">Status</p>
        </div>

        <div class="table2Row layout-flex-row" *ngFor="let user of userList">
          <div class="flex-inline top-bottom-margin-15" style="width: 100%;">
            <a class="userCol" (click)="viewDetails(user.username)" width="30%" aria-label="User" tabindex="0">{{user.lastName}}, {{user.firstName}}</a>
            <p class="small userNameCol" width="15%">{{user.username}}</p>
            <p class="small userEmailCol" width="40%">{{user.email}}</p>
            <p class="small userStatusCol">{{user.disableUser}}</p>
          </div>
        </div>

      </div>

      <div class="center">
        <button class="md-button" *ngIf="totalRecords - pageSize*pageNum >= 1" (click)="loadMoreResults()">
          Load More
        </button>
      </div>

    </div>

  </div>
</div>