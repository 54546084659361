import { AfterContentInit, AfterViewInit, Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { Router } from '@angular/router';
import { WorklistService } from 'src/app/services/worklist.service';
import { ToastService } from 'src/app/services/toast.service';
import * as moment from 'moment';
import { SearchService } from 'src/app/services/search.service';

@Component({
  selector: 'home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit  {

  user: any;
  tabIndex: string = '0';
  authRoleClasses = {
    studentSearch: true,
    sendInfo: true,
    worklist: false,
    stateAdmin: true,
    regionalAdmin: "",
    districtAdmin: "",
    primarySecondary: false
  }
  currUserName = "";
  mobile = false;
  dR = {
    daysSinceCreation: '',
    formattedDate: '',
    daysToResolve: ''
  }
  student = {
    key: '',
    master: {
      key: '',
      demographics: {
        birthDate: '',
        gender: '',
        submittingStateAbbv: '',
        firstName: '',
        midName: '',
        lastName1: '',
        lastName2: ''
      }
    },
    demographics: {
      birthDate: '',
      gender: '',
      submittingStateAbbv: '',
      firstName: '',
      midName: '',
      lastName1: '',
      lastName2: '',
      remobirthDate: ''
    }
  }
  recordSearch = false;
  userSearch: {
    fname: string,
    lname: string,
    username: string
  }
  private showDate: boolean
  private flagCount: number = 0;
  private isFlagCountUpdated: boolean = false;
  dataRequestItems: any[] = new Array();
  mergeWorklist: any[] = new Array();
  splitWorklist: any[] = new Array();
  validateMergeItems: any[] = new Array();
  validateSplitItems: any[] = new Array();
  residencyChangeItems: any[] = new Array();
  nominateMatchItems: any[] = new Array();
  errors: any;

  constructor(private userService: UserService,
    private authService: AuthService,
    private cookieService: CookieService,
    public responsiveUI: ResponsiveUIService,
    private _router: Router,
    private _AuthService: AuthService,
    private _Worklist: WorklistService,
    private _Toast: ToastService,
    private _Search: SearchService){
  } 

  ngOnInit(): void {
    console.log('home page OnInit() is called');

    this.clearUserSearchFields();
    this.getUser();
  }

  getUser() {
    console.log('Home comp - isAuthenticated? ' + this.authService.isAuthenticated());

    if (this.authService.isAuthenticated()) {
      let userObj: any = this.cookieService.getObject('user');
      if (userObj) {
        this.user = userObj.name;
        this.checkRoleClasses();
      } else {
        console.error('User is authenticated but user is not stored in the cookie. Need to verify.');
      }
    } else {
      this.userService.getCurrentUser().then( data => {
        let userData: any = data;
        this.user = userData.firstName + ' ' + userData.lastName;
        this.checkRoleClasses();
      });
    }
  }

  searchUser() {
    this._router.navigate(['userAdmin'], {
      queryParams: {
        firstName: this.userSearch.fname,
        lastName: this.userSearch.lname,
        username: this.userSearch.username
      }
    });
  }

  clearUserSearchFields() {
    this.userSearch = {
      fname: '',
      lname: '',
      username: ''
    };
  }

  checkRoleClasses() {
    this.flagCount = 0;
    this._AuthService.isRoleClassAuthorized(['studentSearch', 'sendInfo', 'worklist', 'stateAdmin', 'regionalAdmin', 'districtAdmin', 'primarySecondary']).then( data => {
      let res: any = data;
      this.authRoleClasses = res;
      var roles: any = this.authRoleClasses;
      if (roles.primarySecondary == true) {
        this.showDate = !this.showDate;
      }

      if (this.authRoleClasses.studentSearch && this.authRoleClasses.sendInfo && this.responsiveUI.isDesktop){
        this.getDataRequestList();
      }else{
        this.dataRequestItems = [];
      }

      if (this.authRoleClasses.worklist && !this.responsiveUI.isDesktop){
        this.getWorklistItems();
      }else{
        this.dataRequestItems = [];
        this.mergeWorklist = [];
        this.splitWorklist = [];
        this.validateMergeItems = [];
        this.validateSplitItems = [];
        this.residencyChangeItems = [];
        this.nominateMatchItems = [];
      }

      if (!this.authRoleClasses.studentSearch && (this.authRoleClasses.stateAdmin || this.authRoleClasses.regionalAdmin || this.authRoleClasses.districtAdmin)){
        this.tabIndex = '1';
      }
    });
  }

  getDataRequestList() {
    console.log('increment flagcount getdtrqst');
    this.flagCount++;
    this.isFlagCountUpdated = true;
    let apiCall = this._Worklist.getDataRequests();
    let subscribed = apiCall.subscribe({
      next: data => {
        let res: any = data;
        console.debug(res);
        this.dataRequestItems = res.dataRequestItems;
        this.dataRequestItems.forEach((i) => {
          if (i.dateCreated) {
            var splitDate = i.dateCreated.split(' ')[0]
            // calculate # of days since DR item was created
            i.daysSinceCreation = moment().diff(i.dateCreated, 'days') + ' days ago';
            //format date for display purposes
            i.formattedDate = moment(splitDate, 'YYYY-MM-DD').format('MM/DD/YYYY')
          } else {
            i.daysSinceCreation = null;
            i.formattedDate = null;
          }
        })

        subscribed.unsubscribe();
      },
      error: error => {
        console.error(error);
        this.errors = error;
        this._Toast.showToast(error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }

  getWorklistItems() {
    console.log('increment flagcount getwrklstitms');
    this.flagCount++;
    this.isFlagCountUpdated = true;
    let apiCall = this._Worklist.getWorklistItems();
    let subscribed = apiCall.subscribe({
      next: data => {
        let res: any = data;
        this.dataRequestItems = res.dataRequestItems;
        this.mergeWorklist = res.nominateMergeItems;
        this.splitWorklist = res.nominateSplitItems;
        this.validateMergeItems = res.validateMergeItems;
        this.validateSplitItems = res.validateSplitItems;
        this.residencyChangeItems = res.residencyChangeItems;
        this.nominateMatchItems = res.nominateMatchItems;

        subscribed.unsubscribe();
      },
      error: error => {
        console.error(error);
        this.errors = error.errors;
        this._Toast.showToast(error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }

  viewStudentDetails(student) {
    let userActivity = {
      msixID: student.msixId,
      pageName: 'APP_STU_VW_CONS',
      activityNotes: '',
      sessionID: ''
    }

    if (student.stuName == undefined) {
      student.stuName = student.lastName1 + ' ' + student.lastName2 + ' ' + student.suffix + ', ' + student.firstName + ' ' + student.midName;
    }

    this._Search.logUserActivity(userActivity);
    this._router.navigate(['studentDetails'], {
      queryParams: {
        msixId: student.msixId,
        name: student.stuName
      }
    });
  }

  viewDetails(student) {
    var stuNameConcatenated = student.demographics.lastName1 + ' ' + student.demographics.lastName2 + ' ' + student.demographics.suffix + ', ' + student.demographics.firstName + ' ' + student.demographics.midName;
    let userActivity = {
      msixID: student.demographics.msixId,
      pageName: 'APP_STU_VW_CONS',
      activityNotes: '',
      sessionID: ''
    }

    if(student && student.demographics.msixId){
      this._Search.logUserActivity(userActivity);

      this._router.navigate(['studentDetails'], {
        queryParams: {
          msixId: student.demographics.msixId,
          name: stuNameConcatenated
        }
      });
    }
  }

  removeFromSplitList(student) {
    var worklistID = student.key;
    var stateStudentKey = student.demographics.stateStudentKey;
    var splitWorklist = this.splitWorklist;

    let worklistItemsAPI = this._Worklist.removeFromWorklist(worklistID, stateStudentKey);
    let subscribed = worklistItemsAPI.subscribe({
      next: data => {
        this._Worklist.getWorklistCount();

        let counter = splitWorklist.length;

        while(counter--) {
          if (splitWorklist[counter].key == worklistID) {
            splitWorklist.splice(counter, 1)
          }
        }
        this._Toast.showToast('Student removed from split worklist');

        subscribed.unsubscribe();
      },
      error: error => {
        console.log(error);
        
        this._Toast.showToast(error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }

  removeFromMergeList(student) {
    var worklistID = student.key;
    var stateStudentKey = student.demographics.stateStudentKey;
    var mergeWorklist = this.mergeWorklist;
    
    let worklistItemsAPI = this._Worklist.removeFromWorklist(worklistID, stateStudentKey);
    let subscribed = worklistItemsAPI.subscribe({
      next: data => {
        console.debug(data);
        
        this._Worklist.getWorklistCount();

        let counter = mergeWorklist.length;
        while(counter--) {
          if (mergeWorklist[counter].key == worklistID) {
            mergeWorklist.splice(counter, 1);
          }
        }
        this._Toast.showToast('Student removed from merge worklist');

        subscribed.unsubscribe();
      },
      error: error => {
        console.error(error);

        this._Toast.showToast(error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }
}
