import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IssueService {

  /**
 * Generates an HTML string representing a list of criteria.
 *
 * @returns {string} An HTML string containing an unordered list of the criteria.
 */
  displayCriteria(): string {
    return `<ul>
    <li>Date occurs between age 3 and age 22</li>
    <li>QAD occurs on or within 36 months minus one day before this date</li>
    <li>EED occurs on or after this date</li>
    <li>If a Graduation/HSE Date is submitted, it must occur on or after this date</li>
    </ul>`
  }

  displayCriteriaAgeBirthThroughTwo() {
    let criteria: any = 'This date occurs on or after the child’s  Birth Date. ' +
    '·QAD occurs on or within 36 months minus one day before this date; '+  
    '·EED occurs on or after this date;'+ 
    '·If a Graduation/HSE Date is submitted, it must occur on or after this date.';
    criteria = criteria.split('·');
    for (let i = 0; i < criteria.length; i++) {
      criteria[i] = "· " + criteria[i] + `
      `;
    }
    criteria = criteria.join("");
    if (document.getElementById("residencyDateCriteria")) {
      document.getElementById("residencyDateCriteria").innerHTML = criteria;
    }
    return '';
  }

  setIssueData(identifier, targetProp, returnProp, option = 'Logic') {
    let completeData = [{
        key: 28,
        title: 'Missing Enrollment Date',
        text: 'Enrollment Date is missing during the performance period.'
      },
      {
        key: 49,
        title: 'Missing Withdrawal Date',
        text: 'Withdrawal Date is missing during the performance period.'
      },
      {
        key: 70,
        title: 'Missing Residency Date',
        text: 'Residency Date is missing during the performance period.'
      },
      {
        key: 73,
        title: 'Missing Residency Verification Date',
        text: 'Residency Verification Date is missing during the performance period.'
      }
    ]

    let validData = [{
        key: 28,
        title: 'Invalid Enrollment Date',
        text: 'Enrollment Date should be submitted in the format of YYYYMMDD.'
      },
      {
        key: 45,
        title: 'Invalid Continuation of Services Reason ',
        text: 'Continuation of Services Reason must be blank, 01, 02, or 03. Only children with blank values or values of 01 are counted in the MSIX child count.'
      },
      {
        key: 49,
        title: 'Invalid Withdrawal Date',
        text: 'Withdrawal Date should be submitted in the format of YYYYMMDD.'
      },
      {
        key: 70,
        title: 'Invalid Residency Date',
        text: 'Residency Date should be submitted in the format of YYYMMDD.'
      },
      {
        key: 73,
        title: 'Invalid Residency Verification Date',
        text: 'Residency Verification Date should be submitted in the format of YYYMMDD.'
      },
      {
        key: 74,
        title: 'Invalid Graduation Date/HSE Date',
        text: 'Graduation/HSE Date should be submitted in the format of YYYYMMDD.'
      }
    ]

    let logicData =[{
        key: 'MISSING_DATE_THAT_VERIFIES_RESIDENCY_CCR_CAT_1',
        title: 'No date that establishes residency',
        text: `<p>At least one of the following dates that establish residency 
        (Enrollment Date, Withdrawal Date, Residency Date, Residency Verification Date) 
        must occur during the performance period. If your State determines that a date 
        that establishes residency exists for this child verify that the date also meets 
        the following criteria: </p> ${this.displayCriteria()}`
      },
      {
        key: 'RESIDENCY_ESTABLISHING_DATE_OCCUR_ON_OR_AFTER_22_CCR_CAT_1',
        title: 'Date that establishes residency occurs before age 3 or on or after age 22',
        text: 'The date that establishes residency (Enrollment Date, Withdrawal Date, Residency Date, or Residency Verification Date) must occur on or after the child’s 3rd birthday and before the child’s 22nd birthday.'
      },
      {
        key: 'QAD_OCCURS_AFTER_RESIDENCY_ESTABLISHING_DATE_CCR_CAT_1',
        title: 'QAD occurs after or more than 36 months minus one day before date that establishes residency',
        text: 'The date that establishes residency (Enrollment Date, Withdrawal Date, Residency Date, or Residency Verification Date) must occur on or within 36 months minus one day after the Qualifying Arrival Date.'
      },
      {
        key: 'EED_OCCURS_BEFORE_RESIDENCY_ESTABLISHING_DATE_CCR_CAT_1',
        title: 'Eligibility Expiration Date occurs before date that establishes residency',
        text: 'The Eligibility Expiration Date must occur on or after the date that establishes residency (Enrollment Date, Withdrawal Date, Residency Date, or Residency Verification Date).'},
      {
        key: 'GRADUATION_DATE_OCCURS_BEFORE_RESIDENCY_ESTABLISHING_DATE_CCR_CAT_1',
        title: 'Graduation/HSE Date occurs before date that establishes residency',
        text: 'If a Graduation/HSE Date is submitted, it must occur on or after the date that establishes residency (Enrollment Date, Withdrawal Date, Residency Date, or Residency Verification Date).'},
      {
        key: 'ENROLLMENT_TYPE_IS_07_OR_MISSING_CCR_CAT_1',
        title: 'Enrollment Type is 07 or missing',
        text: 'Enrollment Type must be 01, 02, 03, 04, 05, or 06.'
      },
      {
        key: 'MISSING_DATE_THAT_VERIFIES_RESIDENCY_CCR_CAT_2',
        title: 'No date that establishes residency',
        text: 'The date that establishes residency (Enrollment Date) must occur during the performance period. If your State determines that a date that establishes residency exists for this child verify that the date also meets the following criteria:' 
        + this.displayCriteria()
      },
      {
        key: 'RESIDENCY_ESTABLISHING_DATE_OCCUR_ON_OR_AFTER_22_CCR_CAT_2',
        title: 'Date that establishes residency occurs before age 3 or on or after age 22',
        text: "The date that establishes residency (Enrollment Date) must occur on or after the child’s 3rd birthday and before the child’s 22nd birthday."
      },
      {
        key: 'QAD_OCCURS_AFTER_RESIDENCY_ESTABLISHING_DATE_CCR_CAT_2',
        title: 'QAD occurs after or more than 36 months minus one day before date that establishes residency',
        text: 'The Qualifying Arrival Date (QAD) must occur on or before the date that establishes residency (Enrollment Date).'
      },
      {
        key: 'EED_OCCURS_BEFORE_RESIDENCY_ESTABLISHING_DATE_CCR_CAT_2',
        title: 'Eligibility Expiration Date occurs before date that establishes residency',
        text: 'The Eligibility Expiration Date must occur on or after the date that establishes residency (Enrollment Date).'
      },
      {
        key: 'GRADUATION_DATE_OCCURS_BEFORE_RESIDENCY_ESTABLISHING_DATE_CCR_CAT_2',
        title: 'Graduation/HSE Date occurs before date that establishes residency',
        text: 'If a Graduation/HSE Date is submitted, it must occur on or after the date that establishes residency (Enrollment Date).'
      }
    ]

    let lookup;
    if (option === 'Completeness') {
      lookup = completeData;
    } else if (option === 'Validity') {
      lookup = validData;
    } else {
      lookup = logicData;
    }
    let foundObj = lookup.find(i => i[targetProp] === identifier);
    if (foundObj) {
      return foundObj[returnProp];
    }
  }

  setIssueDataForAgeBirthThroughTwo(identifier, targetProp, returnProp, option = 'Logic') {
    let completeData = [{
        key: 28,
        title: 'Missing Enrollment Date',
        text: 'Enrollment Date is missing during the performance period.'
      },
      {
        key: 49,
        title: 'Missing Withdrawal Date',
        text: 'Withdrawal Date is missing during the performance period.'
      },
      {
        key: 70,
        title: 'Missing Residency Date',
        text: 'Residency Date is missing during the performance period.'
      },
      {
        key: 73,
        title: 'Missing Residency Verification Date',
        text: 'Residency Verification Date is missing during the performance period.'
      }
    ]

    let validData = [{
        key: 28,
        title: 'Invalid Enrollment Date',
        text: 'Enrollment Date should be submitted in the format of YYYYMMDD.'
      },
      {
        key: 45,
        title: 'Invalid Continuation of Services Reason ',
        text: 'Continuation of Services Reason must be blank, 01, 02, or 03. Only children with blank values or values of 01 are counted in the MSIX child count.'
      },
      {
        key: 49,
        title: 'Invalid Withdrawal Date',
        text: 'Withdrawal Date should be submitted in the format of YYYYMMDD.'
      },
      {
        key: 70,
        title: 'Invalid Residency Date',
        text: 'Residency Date should be submitted in the format of YYYMMDD.'
      },
      {
        key: 73,
        title: 'Invalid Residency Verification Date',
        text: 'Residency Verification Date should be submitted in the format of YYYMMDD.'
      },
      {
        key: 74,
        title: 'Invalid Graduation Date/HSE Date',
        text: 'Graduation/HSE Date should be submitted in the format of YYYYMMDD.'
      }
    ]

    let logicData = [{
        key: 'MISSING_DATE_THAT_VERIFIES_RESIDENCY_BIRTH_THROUGH_TWO',
        title: 'No date that establishes residency',
        text: 'At least one of the following dates that establish residency (Enrollment Date, Withdrawal Date, Residency Date, Residency Verification Date) must occur during ' +
          'the performance period. If your State determines that a date that establishes residency exists for this child verify that the date also meets the following criteria: ' + 
          this.displayCriteriaAgeBirthThroughTwo()
      },
      {
        key: 'QAD_OCCURS_AFTER_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO',
        title: 'QAD occurs after date that establishes residency OR more than 36 months minus one day before the date that establishes residency OR before the Birth Date',
        text: 'The Qualifying Arrival Date (QAD) must occur on or within 36 months minus one day before this date AND after the birth date.'
      },
      {
        key: 'EED_OCCURS_BEFORE_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO',
        title: 'Eligibility Expiration Date occurs before date that establishes residency',
        text: 'The Eligibility Expiration Date must occur on or after the date that establishes residency (Enrollment Date).'
      },
      {
        key: 'GRADUATION_DATE_OCCURS_BEFORE_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO',
        title: 'Graduation/HSE Date occurs before date that establishes residency',
        text: 'If a Graduation/HSE Date is submitted, it must occur on or after the date that establishes residency (Enrollment Date).'
      },
      {
        key: 'ENROLLMENT_TYPE_IS_07_OR_MISSING_BIRTH_THROUGH_TWO',
        title: 'Enrollment Type is 07 or missing',
        text: 'Enrollment Type must be 01, 02, 03, 04, 05, or 06.'
      },
      {
        key: 'RESIDENCY_ESTABLISHING_DATE_OCCUR_BEFORE_BIRTH_DATE',
        title: 'Date that establishes residency occurs before Birth Date',
        text: "The date that establishes residency (Enrollment Date) must occur after the child's Birth Date."
      },
    ]

    let lookup;
    if (option === 'Completeness') {
      lookup = completeData;
    } else if (option === 'Validity') {
      lookup = validData;
    } else {
      lookup = logicData;
    }
    let foundObj = lookup.find(i => i[targetProp] === identifier);
    if (foundObj) {
      return foundObj[returnProp];
    }
  }
}
