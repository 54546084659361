<div layout="row" class="worklistHistoryRecord-page" id="worklistpagesection">
  <div layout="column" flex>

  <!-- Header Begin -->
  <div class="layout-margin top-margin-10">

    <div *ngIf="responsiveUI.isDesktop" class="layout-flex-row layout-align-end-start">
      <!--<div class="backToSearchResults" (click)="goBackToWorklistHistorySearchResults()">-->
      <!--<a>Back to Worklist Search Results</a>-->
      <!--</div>-->
      <div class="corHistoricalTab" (click)="goToWorklist()">
        <p>Worklist</p>
      </div>
    </div>

    <div class="mainHeader">
      <div class="block2 uppercase">Worklist History</div>
      <div class="flexSpaceBetween">
        <h2 class="camelcase">#{{worklistId}}</h2>
      </div>
    </div>
  </div>

  <hr class="thinHr">

  <div *ngIf="!responsiveUI.isDesktop" class="layout-margin">
    <p class="layout-padding">To view this page, access your worklist history on desktop.</p>
  </div>

  <!-- Header End-->

  <!-- Sub Header Begin -->
  <div *ngIf="responsiveUI.isDesktop" class="layout-margin layout-flex-row desktopStudentIds flexSpaceBetween">
    <div class="flex-baseline">
      <div class="block2 uppercase">Worklist Type:</div>
      <p>&nbsp;{{worklistType}}</p>
    </div>
    <div class="flex-baseline">
      <div class="block2 uppercase">Current Status:</div>
      <p>&nbsp;{{worklistStatus}}</p>
    </div>
  </div>
  <!-- Sub Header End -->

  <!--Body Start-->
  <div *ngIf="responsiveUI.isDesktop" class="layout-column flexSpaceBetweenTop removeBorders flex">
    <div class="layout-column flex">
      <!--MERGE & SPLIT WORKLIST HISTORY SEARCH-->
      <div *ngIf="emptyWorklistDevMessage == false">

        <!--Near Match-->
        <div *ngIf="worklistType == 'Near Match' || worklistType == 'NearMatch'">
          <!-- New Record Begin -->
          <div class="flex">
            <div class="table tableHeader" (click)="showNewRecord = !showNewRecord">
              <div class="layout-flex-row tableRow headerRow flexSpaceBetween">
                <p class="font-20">New Record Submitted to MSIX</p>
                <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="!showNewRecord"></i>
                <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="showNewRecord"></i>
              </div>
            </div>
            <div class="table tableBody layout-flex-row" *ngIf="showNewRecord">
              <div class="flex layout-flex-column">
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Student</div>
                  <p class="flex">{{workflowRecord.demographics.firstName}} {{workflowRecord.demographics.midName}}
                    {{workflowRecord.demographics.lastName1}} {{workflowRecord.demographics.lastName2}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Submitting State</div>
                  <p class="flex">{{workflowRecord.demographics.submittingState}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Sex</div>
                  <p class="flex">{{workflowRecord.demographics.gender}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">DOB</div>
                  <p class="flex">{{workflowRecord.demographics.birthDate}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Multi-Birth</div>
                  <p class="flex">{{workflowRecord.demographics.multipleBirthFlag}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">MSIX ID</div>
                  <p class="flex">{{workflowRecord.demographics.msixId}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">State ID</div>
                  <p class="flex">{{workflowRecord.demographics.studentStateID}}</p>
                </div>
              </div>
              <div class="flex layout-flex-column">
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 1</div>
                  <p class="flex">{{workflowRecord.demographics.femaleParentFirstName}}
                    {{workflowRecord.demographics.femaleParentLastName}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 1 Email Address</div>
                  <p class="flex">{{workflowRecord.demographics.parent1EmailAddress}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 1 Phone Number</div>
                  <p class="flex">{{workflowRecord.demographics.parent1PhoneNumber}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 2</div>
                  <p class="flex">{{workflowRecord.demographics.maleParentFirstName}}
                    {{workflowRecord.demographics.maleParentLastName}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 2 Email Address</div>
                  <p class="flex">{{workflowRecord.demographics.parent2EmailAddress}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 2 Phone Number</div>
                  <p class="flex">{{workflowRecord.demographics.parent2PhoneNumber}}</p>
                </div>
              </div>
            </div>
          </div>
          <!-- New Record Table End -->

          <!-- Existing Record Begin -->
          <div class="flex" *ngFor="let child of workflowRecord.children">
            <div class="table tableHeader" (click)="showExistingRecord = !showExistingRecord">
              <div class="layout-flex-row tableRow headerRow flexSpaceBetween">
                <p class="font-20">Existing MSIX Record</p>
                <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="!showExistingRecord"></i>
                <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="showExistingRecord"></i>
              </div>
            </div>
            <div class="layout-flex-row table tableBody" *ngIf="showExistingRecord">
              <div class="flex layout-flex-column">
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Student</div>
                  <p class="flex">{{child.demographics.firstName}} {{child.demographics.midName}}
                    {{child.demographics.lastName1}} {{child.demographics.lastName2}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Submitting State</div>
                  <p class="flex">{{child.demographics.submittingState}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Sex</div>
                  <p class="flex">{{child.demographics.gender}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">DOB</div>
                  <p class="flex">{{child.demographics.birthDate}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Multi-Birth</div>
                  <p class="flex">{{child.demographics.multipleBirthFlag}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">MSIX ID</div>
                  <p class="flex">{{child.demographics.msixId}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">State ID</div>
                  <p class="flex">{{child.demographics.studentStateID}}</p>
                </div>
              </div>
              <div class="flex layout-flex-column">
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 1</div>
                  <p class="flex">{{workflowRecord.demographics.femaleParentFirstName}}
                    {{workflowRecord.demographics.femaleParentLastName}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 1 Email Address</div>
                  <p class="flex">{{workflowRecord.demographics.parent1EmailAddress}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 1 Phone Number</div>
                  <p class="flex">{{workflowRecord.demographics.parent1PhoneNumber}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 2</div>
                  <p class="flex">{{workflowRecord.demographics.maleParentFirstName}}
                    {{workflowRecord.demographics.maleParentLastName}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 2 Email Address</div>
                  <p class="flex">{{workflowRecord.demographics.parent2EmailAddress}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 2 Phone Number</div>
                  <p class="flex">{{workflowRecord.demographics.parent2PhoneNumber}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!--Validate Merge OR Validate User Initated Merge-->
        <div *ngIf="((worklistType == 'Validate Merge') || (worklistType == 'ValidateMerge') || 
          (worklistType == 'Validate User Initiated Merge') || (worklistType == 'ValidateUserInitiatedMerge'))">
          <!-- Master Student Record Begin -->
          <div class="flex">
            <div class="table tableHeader" (click)="showMaster = !showMaster">
              <div class="layout-flex-row tableRow headerRow flexSpaceBetween">
                <p class="font-20">Master Student Record</p>
                <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="!showMaster"></i>
                <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="showMaster"></i>
              </div>
            </div>
            <div class="table tableBody layout-flex-row" *ngIf="showMaster">
              <div class="flex layout-flex-column">
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Student</div>
                  <p class="flex">{{workflowRecord.demographics.firstName}} {{workflowRecord.demographics.midName}}
                    {{workflowRecord.demographics.lastName1}} {{workflowRecord.demographics.lastName2}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Submitting State</div>
                  <p class="flex">{{workflowRecord.demographics.submittingState}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Sex</div>
                  <p class="flex">{{workflowRecord.demographics.gender}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">DOB</div>
                  <p class="flex">{{workflowRecord.demographics.birthDate}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Multi-Birth</div>
                  <p class="flex">{{workflowRecord.demographics.multipleBirthFlag}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">MSIX ID</div>
                  <p class="flex">{{workflowRecord.demographics.msixId}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">State ID</div>
                  <p class="flex">{{workflowRecord.demographics.studentStateID}}</p>
                </div>
              </div>
              <div class="flex layout-flex-column">
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 1</div>
                  <p class="flex">{{workflowRecord.demographics.femaleParentFirstName}}
                    {{workflowRecord.demographics.femaleParentLastName}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 1 Email Address</div>
                  <p class="flex">{{workflowRecord.demographics.parent1EmailAddress}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 1 Phone Number</div>
                  <p class="flex">{{workflowRecord.demographics.parent1PhoneNumber}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 2</div>
                  <p class="flex">{{workflowRecord.demographics.maleParentFirstName}}
                    {{workflowRecord.demographics.maleParentLastName}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 2 Email Address</div>
                  <p class="flex">{{workflowRecord.demographics.parent2EmailAddress}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 2 Phone Number</div>
                  <p class="flex">{{workflowRecord.demographics.parent2PhoneNumber}}</p>
                </div>
              </div>
            </div>
          </div>

          <!-- Merge Student Record Begin -->
          <div class="flex" *ngFor="let child of workflowRecord.children">
            <div class="table tableHeader" (click)="showMergeRecord = !showMergeRecord">
              <div class="layout-flex-row tableRow headerRow flexSpaceBetween">
                <p class="font-20">Merge Student Record</p>
                <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="!showMergeRecord"></i>
                <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="showMergeRecord"></i>
              </div>
            </div>
            <div class="table tableBody layout-flex-row" *ngIf="showMergeRecord">
              <div class="flex layout-flex-column">
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Student</div>
                  <p class="flex">{{child.demographics.firstName}} {{child.demographics.midName}}
                    {{child.demographics.lastName1}} {{child.demographics.lastName2}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Submitting State</div>
                  <p class="flex">{{child.demographics.submittingState}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Sex</div>
                  <p class="flex">{{child.demographics.gender}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">DOB</div>
                  <p class="flex">{{child.demographics.birthDate}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Multi-Birth</div>
                  <p class="flex">{{child.demographics.multipleBirthFlag}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">MSIX ID</div>
                  <p class="flex">{{child.demographics.msixId}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">State ID</div>
                  <p class="flex">{{child.demographics.studentStateID}}</p>
                </div>
              </div>
              <div class="flex layout-flex-column">
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 1</div>
                  <p class="flex">{{workflowRecord.demographics.femaleParentFirstName}}
                    {{workflowRecord.demographics.femaleParentLastName}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 1 Email Address</div>
                  <p class="flex">{{workflowRecord.demographics.parent1EmailAddress}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 1 Phone Number</div>
                  <p class="flex">{{workflowRecord.demographics.parent1PhoneNumber}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 2</div>
                  <p class="flex">{{workflowRecord.demographics.maleParentFirstName}}
                    {{workflowRecord.demographics.maleParentLastName}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 2 Email Address</div>
                  <p class="flex">{{workflowRecord.demographics.parent2EmailAddress}}</p>
                </div>
                <div class="flex layout-flex-row tableRow">
                  <div class="flex-45 block2 uppercase">Parent 2 Phone Number</div>
                  <p class="flex">{{workflowRecord.demographics.parent2PhoneNumber}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

          <!--Validate Split-->
          <div *ngIf="worklistType == 'Validate Split' || worklistType == 'ValidateSplit'">
            <!-- Student to Retain MSIX ID Begin -->
            <div class="flex">
              <div class="table tableHeader" (click)="showRetainMSIX = !showRetainMSIX">
                <div class="layout-flex-row tableRow headerRow flexSpaceBetween">
                  <p class="font-20">Student to Retain MSIX ID</p>
                  <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="!showRetainMSIX"></i>
                  <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="showRetainMSIX"></i>
                </div>
              </div>
              <div class="table tableBody layout-flex-row" *ngIf="showRetainMSIX">
                <div class="flex layout-flex-column">
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Student</div>
                    <p class="flex">{{workflowRecord.master.demographics.firstName}}
                      {{workflowRecord.master.demographics.midName}} {{workflowRecord.master.demographics.lastName1}}
                      {{workflowRecord.master.demographics.lastName2}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Submitting State</div>
                    <p class="flex">{{workflowRecord.master.demographics.submittingState}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Sex</div>
                    <p class="flex">{{workflowRecord.master.demographics.gender}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">DOB</div>
                    <p class="flex">{{workflowRecord.master.demographics.birthDate}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Multi-Birth</div>
                    <p class="flex">{{workflowRecord.master.demographics.multipleBirthFlag}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">MSIX ID</div>
                    <p class="flex">{{workflowRecord.master.demographics.msixId}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">State ID</div>
                    <p class="flex">{{workflowRecord.master.demographics.studentStateID}}</p>
                  </div>
                </div>
                <div class="flex layout-flex-column">
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Parent 1</div>
                    <p class="flex">{{workflowRecord.demographics.femaleParentFirstName}}
                      {{workflowRecord.demographics.femaleParentLastName}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Parent 1 Email Address</div>
                    <p class="flex">{{workflowRecord.demographics.parent1EmailAddress}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Parent 1 Phone Number</div>
                    <p class="flex">{{workflowRecord.demographics.parent1PhoneNumber}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Parent 2</div>
                    <p class="flex">{{workflowRecord.demographics.maleParentFirstName}}
                      {{workflowRecord.demographics.maleParentLastName}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Parent 2 Email Address</div>
                    <p class="flex">{{workflowRecord.demographics.parent2EmailAddress}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Parent 2 Phone Number</div>
                    <p class="flex">{{workflowRecord.demographics.parent2PhoneNumber}}</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- Student to Receive New MSIX ID Begin -->
            <div class="flex">
              <div class="table tableHeader" (click)="showReceiveMSIX = !showReceiveMSIX">
                <div class="layout-flex-row tableRow headerRow flexSpaceBetween">
                  <p class="font-20">Student to Receive New MSIX ID</p>
                  <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="!showReceiveMSIX"></i>
                  <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="showReceiveMSIX"></i>
                </div>
              </div>
              <div class="table tableBody layout-flex-row" *ngIf="showReceiveMSIX">
                <div class="flex layout-flex-column">
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Student</div>
                    <p class="flex">{{workflowRecord.orphan.demographics.firstName}}
                      {{workflowRecord.orphan.demographics.midName}} {{workflowRecord.orphan.demographics.lastName1}}
                      {{workflowRecord.orphan.demographics.lastName2}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Submitting State</div>
                    <p class="flex">{{workflowRecord.orphan.demographics.submittingState}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Sex</div>
                    <p class="flex">{{workflowRecord.orphan.demographics.gender}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">DOB</div>
                    <p class="flex">{{workflowRecord.orphan.demographics.birthDate}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Multi-Birth</div>
                    <p class="flex">{{workflowRecord.orphan.demographics.multipleBirthFlag}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">MSIX ID</div>
                    <p class="flex">{{workflowRecord.orphan.demographics.msixId}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">State ID</div>
                    <p class="flex">{{workflowRecord.orphan.demographics.studentStateID}}</p>
                  </div>
                </div>
                <div class="flex layout-flex-column">
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Parent 1</div>
                    <p class="flex">{{workflowRecord.demographics.femaleParentFirstName}}
                      {{workflowRecord.demographics.femaleParentLastName}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Parent 1 Email Address</div>
                    <p class="flex">{{workflowRecord.demographics.parent1EmailAddress}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Parent 1 Phone Number</div>
                    <p class="flex">{{workflowRecord.demographics.parent1PhoneNumber}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Parent 2</div>
                    <p class="flex">{{workflowRecord.demographics.maleParentFirstName}}
                      {{workflowRecord.demographics.maleParentLastName}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Parent 2 Email Address</div>
                    <p class="flex">{{workflowRecord.demographics.parent2EmailAddress}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Parent 2 Phone Number</div>
                    <p class="flex">{{workflowRecord.demographics.parent2PhoneNumber}}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

        <!--COR WORKLIST HISTORY SEARCH-->
        <div>
          <!--All COR-->
          <div
            *ngIf="worklistType == 'Data Request' || worklistType == 'DataRequest' || worklistType == 'Leaving Our Area' || 
            worklistType == 'LeavingOurArea' || worklistType == 'Moved To Our Area' || worklistType == 'MovedToOurArea'">
            <!-- Original Worklist Details -->
            <div class="flex">
              <div class="table tableHeader" (click)="showOverview = !showOverview">
                <div class="layout-flex-row tableRow headerRow flexSpaceBetween">
                  <p class="font-20">Original Worklist Details</p>
                  <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="!showOverview"></i>
                  <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="showOverview"></i>
                </div>
              </div>
              <div class="table tableBody layout-flex-row" *ngIf="showOverview">
                <div class="flex layout-flex-column">
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Created By</div>
                    <p class="flex">{{workflowRecord.creator.firstName}} {{workflowRecord.creator.lastName}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Phone</div>
                    <p class="flex">{{workflowRecord.creator.phone}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow" style="max-height: none;">
                    <div class="flex-45 block2 uppercase">user Comments</div>
                    <p class="flex-55">{{workflowRecord.creator.comments}}</p>
                  </div>
                </div>
                <div class="flex layout-flex-column">
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Date</div>
                    <p class="flex">{{workflowRecord.creator.dateCreated}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Email</div>
                    <p class="flex">{{workflowRecord.creator.email}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">

                  </div>
                </div>
              </div>

            </div>

            <!-- Student Record -->
            <div class="flex">
              <div class="table tableHeader" (click)="showStudentRecord = !showStudentRecord">
                <div class="layout-flex-row tableRow headerRow flexSpaceBetween">
                  <p class="font-20">Student Record</p>
                  <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="!showStudentRecord"></i>
                  <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="showStudentRecord"></i>
                </div>
              </div>
              <div class="table tableBody layout-flex-row" *ngIf="showStudentRecord">
                <div class="flex layout-flex-column">
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Student</div>
                    <p class="flex">{{workflowRecord.demographics.firstName}} {{workflowRecord.demographics.midName}}
                      {{workflowRecord.demographics.lastName1}} {{workflowRecord.demographics.lastName2}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Submitting State</div>
                    <p class="flex">{{workflowRecord.demographics.submittingState}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Sex</div>
                    <p class="flex">{{workflowRecord.demographics.gender}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">DOB</div>
                    <p class="flex">{{workflowRecord.demographics.birthDate}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Multi-Birth</div>
                    <p class="flex">{{workflowRecord.demographics.multipleBirthFlag}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">MSIX ID</div>
                    <p class="flex">{{workflowRecord.demographics.msixId}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">State ID</div>
                    <p class="flex">{{workflowRecord.demographics.studentStateID}}</p>
                  </div>
                </div>
                <div class="flex layout-flex-column">
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Parent 1</div>
                    <p class="flex">{{workflowRecord.demographics.femaleParentFirstName}}
                      {{workflowRecord.demographics.femaleParentLastName}}</p>
                  </div>
                  <div class="flex tableRow">
                    <div class="block2 flex-45 bluppercase">Parent 1 Email Address</div>
                    <p class="flex">{{workflowRecord.demographics.parent1EmailAddress}}</p>
                  </div>
                  <div class="flex tableRow">
                    <div class="block2 flex-45 uppercase">Parent 1 Phone Number</div>
                    <p class="flex">{{workflowRecord.demographics.parent1PhoneNumber}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Parent 2</div>
                    <p class="flex">{{workflowRecord.demographics.maleParentFirstName}}
                      {{workflowRecord.demographics.maleParentLastName}}</p>
                  </div>
                  <div class="flex tableRow">
                    <div class="block2 flex-45 uppercase">Parent 2 Email Address</div>
                    <p class="flex">{{workflowRecord.demographics.parent2EmailAddress}}</p>
                  </div>
                  <div class="flex tableRow">
                    <div class="block2 flex-45 uppercase">Parent 2 Phone Number</div>
                    <p class="flex">{{workflowRecord.demographics.parent2PhoneNumber}}</p>
                  </div>
                </div>
              </div>
            </div>

            <!-- School Information -->
            <div class="flex">
              <div class="table tableHeader" (click)="showSchoolInfo = !showSchoolInfo">
                <div class="layout-flex-row tableRow headerRow flexSpaceBetween">
                  <p class="font-20">School Information</p>
                  <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="!showSchoolInfo"></i>
                  <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="showSchoolInfo"></i>
                </div>
              </div>
              <div class="table tableBody layout-flex-row" *ngIf="showSchoolInfo">
                <div class="flex layout-flex-column">
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Sending School ID</div>
                    <p class="flex">{{workflowRecord.schoolInfo.sendingSchool.name}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Sending School District</div>
                    <p class="flex">{{workflowRecord.schoolInfo.sendingSchool.districtName}}</p>
                  </div>
                </div>
                <div class="flex layout-flex-column">
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Receiving School ID</div>
                    <p class="flex">{{workflowRecord.schoolInfo.receivingSchool.name}}</p>
                  </div>
                  <div class="flex layout-flex-row tableRow">
                    <div class="flex-45 block2 uppercase">Receiving School District</div>
                    <p class="flex">{{workflowRecord.schoolInfo.receivingSchool.districtName}}</p>
                  </div>
                </div>

              </div>
            </div>

            <!-- Change of Residency Correspondences -->
            <div class="flex">
              <div class="table tableHeader" (click)="showCORCorrespondence = !showCORCorrespondence">
                <div class="layout-flex-row tableRow headerRow flexSpaceBetween">
                  <p class="font-20">Correspondence</p>
                  <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="!showCORCorrespondence"></i>
                  <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="showCORCorrespondence"></i>
                </div>
              </div>

              <div class="layout-padding" *ngIf="showCORCorrespondence && corMessagesLength == 0">
                <p>No correspondences found</p>
              </div>

              <div *ngIf="showCORCorrespondence">
                <div *ngIf="corMessagesLength > 0">
                  <div class="worklist-header-titles flex-container layout-flex-row flex">
                    <div class="flex-container flex-95">
                      <div class="block2 flex-25">Submitting User</div>
                      <div class="block2 flex-25">Submitting State</div>
                      <div class="block2 flex-50">User Message</div>
                      <div class="block2 flex-25">Message Date</div>
                      <div class="block2 flex-25">Resolved</div>
                    </div>
                  </div>

                  <div class="flex-container layout-flex-row flex"
                    *ngFor="let corMessage of workflowRecord.corMessages">

                    <div class="flex-container flex-95">
                      <p class="flex-25">{{corMessage.msgSubmittingUserFN}} {{corMessage.msgSubmittingUserLN}}</p>
                      <p class="flex-50">{{corMessage.msgSubmittingUserState}}</p>
                      <p class="flex-25">{{corMessage.message}}</p>
                      <p class="flex-25">{{corMessage.msgDate}}</p>
                      <p class="flex-25">{{corMessage.isResolved}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
    <!--Body End-->

    <!-- Workflow Table Begin -->

    <div class="flex layout-column" [ngClass]="{'flexSpaceBetweenTop removeBorders' : responsiveUI.isDesktop}">
      <div class="flex">
        <div class="table tableHeader" (click)="showHideWorkflow()">
          <div class="layout-flex-row tableRow headerRow flexSpaceBetween">
            <p class="font-20">Workflow for #{{worklistId}}</p>
            <i class="fa fa-chevron-down" aria-hidden="true" *ngIf="!showWorkflow"></i>
            <i class="fa fa-chevron-up" aria-hidden="true" *ngIf="showWorkflow"></i>
          </div>
        </div>

        <div *ngIf="showWorkflow">
          <div class="worklist-header-titles flex-container layout-flex-row flex-95" *ngIf="showWorkflow && 
          (itemHistoryLength || workflowRecord.worklistHistory.length > 0)">
            <div class="flex-container flex">
              <div class="block2 flex-20">Workflow</div>
              <div class="block2 flex-20">Status</div>
              <div class="block2 flex-20">Acting User</div>
              <div class="block2 flex-20">User's State</div>
              <div class="block2 flex-20">Date</div>
            </div>
          </div>

          <!--workflow for merge & split-->
          <div *ngIf="workflowRecord.worklistHistory?.length > 0 && (worklistType == 'Near Match' || worklistType == 'NearMatch'
          || worklistType == 'ETL Near Match' || worklistType == 'ETLNearMatch'
          || worklistType == 'ETL Near Match Validation' || worklistType == 'ETLNearMatchValidation'
          || worklistType == 'Validate Merge' || worklistType == 'ValidateMerge'
          || worklistType == 'Validate User Initiated Merge' || worklistType == 'ValidateUserInitiatedMerge'
          || worklistType == 'User Initiated Merge' || worklistType == 'UserInitiatedMerge'
          || worklistType == 'User Initiated Merge Validation' || worklistType == 'UserInitiatedMergeValidation'
          || worklistType == 'Validate Split' || worklistType == 'ValidateSplit')">
            <div class="layout-flex-row table2Row flex"
              *ngFor="let workflowItem of workflowRecord.worklistHistory; let mergeIndex = index">
              <div class="layout-flex-column flex-95">
                <div class="flex-container workflow-item layout-flex-row">
                  <p class="flex-20">{{workflowItem.eventType}}</p>
                  <p class="flex-20">{{workflowItem.status}}</p>
                  <p class="flex-20">{{workflowItem.actingUser}}</p>
                  <p class="flex-20">{{workflowItem.userState}}</p>
                  <p class="flex-20">{{workflowItem.eventDate}}</p>
                </div>

                <div class="layout-flex-row section2 worklist-accordion-section2 background-grey1"
                  *ngIf="(workflowIndex === mergeIndex) && showWorkflow">
                  <div class="layout-flex-column flex">
                    <div class="worklist-header-titles flex-container layout-flex-row">
                      <div class="flex-container flex-95">
                        <div class="block2 flex">User's Name</div>
                        <div class="block2 flex">State</div>
                        <div class="block2 flex">Phone Number</div>
                        <div class="block2 flex">Email</div>
                      </div>
                    </div>
                    <div *ngFor="let user of workflowItem.assignedUsers" class="flex-container layout-flex-row">
                      <div class="flex-container flex-95">
                        <p class="flex">{{user.firstName}}</p>
                        <p class="flex">{{user.userState}}</p>
                        <p class="flex">{{user.userPhone}}</p>
                        <p class="flex">{{user.email}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-5" (click)="showWorkflowDetails(mergeIndex)" class="expandColumn-green flex-container"
                *ngIf="workflowItem.assignedUsers.length">
                <i class="fa fa-chevron-down accordion-icon" aria-hidden="true"
                  *ngIf="!(workflowIndex === mergeIndex)"></i>
                <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="workflowIndex === mergeIndex"></i>
              </div>
            </div>
          </div>

          <!--workflow for COR-->
          <div *ngIf="itemHistoryLength || worklistType == 'DataRequest' || 
            worklistType == 'Data Request' || worklistType == 'Leaving Our Area' || worklistType == 'LeavingOurArea' || 
            worklistType == 'Moved To Our Area' || worklistType == 'MovedToOurArea'">
            <div class="layout-flex-row table2Row"
              *ngFor="let workflowItem of workflowRecord.worklistItemHistory; let corIndex = index">
              <div class="layout-flex-column flex-95">
                <div class="flex-container workflow-item layout-flex-row">
                  <p class="flex">{{workflowItem.eventType}}</p>
                  <p class="flex">{{workflowItem.status}}</p>
                  <p class="flex">{{workflowItem.actingUser}}</p>
                  <p class="flex">{{workflowItem.userState}}</p>
                  <p class="flex">{{workflowItem.eventDate}}</p>
                </div>
                <div class="layout-flex-row">
                  <div class="layout-flex-column section2 worklist-accordion-section2 background-grey1 flex"
                    *ngIf="workflowIndex === corIndex && workflowItem.assignedUsers.length">
                    <div class="worklist-header-titles flex-container layout-flex-row">
                      <div class="flex-container flex-95">
                        <div class="block2 flex">User's Name</div>
                        <div class="block2 flex">State</div>
                        <div class="block2 flex">Phone Number</div>
                        <div class="block2 flex">Email</div>
                      </div>
                    </div>
                    <div *ngFor="let user of workflowItem.assignedUsers" class="flex-container layout-flex-row">
                      <div class="flex-container flex-95">
                        <p class="flex">{{user.firstName}}</p>
                        <p class="flex">{{user.userState}}</p>
                        <p class="flex">{{user.userPhone}}</p>
                        <p class="flex">{{user.email}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div (click)="showWorkflowDetails(corIndex)" class="flex-5 expandColumn-green flex-container"
                *ngIf="workflowItem.assignedUsers.length">
                <i class="fa fa-chevron-down accordion-icon" aria-hidden="true"
                  *ngIf="!(workflowIndex === corIndex)"></i>
                <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="(workflowIndex === corIndex)"></i>
              </div>
            </div>
          </div>
        </div>

      </div>


    </div>

  </div>
</div>
