import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ResponsiveUIService } from "../../services/responsiveUI.service";
import * as moment from 'moment';
@Component({
  selector: 'app-work-history-search',
  templateUrl: './work-history-search.component.html',
  styleUrls: ['./work-history-search.component.scss'],
})
export class WorklistHistorySearchComponent implements OnInit {

  showCreateErrMsg: boolean;
  showResolveErrorMessage: boolean;
  worklistQuery: any = {};
  queryType: any;
  $stateParams: any = {};
  @Input() worklistHistorySearchType: any;
  @Input() title: string;
  @Input() states: any[];
  @Output() searchforstudents = new EventEmitter<object>();

  constructor(private _router: Router,
    public responsiveUI: ResponsiveUIService,
    private activatedRoute: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.$stateParams.worklistHistorySearchType = params['worklistHistorySearchType'];
    });
  }

  searchValidationLogic() {
    var flag = true,
      fieldsEmpty = true,
      createFromMissing = false,
      createToMissing = false,
      createValidRange = false,
      resolvedValidRange = false,
      resolvedFromMissing = false,
      resolvedToMissing = false,
      createdRangeStart,
      createdRangeEnd,
      resolvedRangeStart,
      resolvedRangeEnd

    // TEXT / DROPDOWN LOGIC:
    if (this.worklistQuery) {
      // loop through fields and check if data has been entered - if so enable submit button
      var array = [] as any;
      for (var i in this.worklistQuery) {
        var value = this.worklistQuery[i] as unknown as any;
        if (value.length > 0) {
          array.push(value)
        }
      }
      if (array.length > 0) {
        fieldsEmpty = false;
        flag = false;
      } else {
        fieldsEmpty = true;
        flag = true;
      }

      // DATE FIELD LOGIC:

      //ensure create from field is complete
      if (this.worklistQuery.createdFromDateMM || this.worklistQuery.createdFromDateDD || this.worklistQuery.createdFromDateYYYY) {
        createFromMissing = true;
        if ((this.worklistQuery.createdFromDateMM && this.worklistQuery.createdFromDateDD && this.worklistQuery.createdFromDateYYYY) && this.worklistQuery.createdFromDateYYYY.length == 4) {
          createdRangeStart = this.worklistQuery.createdFromDateMM + '/' + this.worklistQuery.createdFromDateDD + '/' + this.worklistQuery.createdFromDateYYYY;
          createdRangeStart = moment(createdRangeStart)
          if (createdRangeStart.isValid()) {
            createFromMissing = false;
          }
        }
      }

      //ensure create to field is complete
      if (this.worklistQuery.createdToDateMM || this.worklistQuery.createdToDateDD || this.worklistQuery.createdToDateYYYY) {
        createToMissing = true;
        if ((this.worklistQuery.createdToDateMM && this.worklistQuery.createdToDateDD && this.worklistQuery.createdToDateYYYY) && this.worklistQuery.createdToDateYYYY.length == 4) {
          createdRangeEnd = this.worklistQuery.createdToDateMM + '/' + this.worklistQuery.createdToDateDD + '/' + this.worklistQuery.createdToDateYYYY;
          createdRangeEnd = moment(createdRangeEnd)
          if (createdRangeEnd.isValid()) {
            createToMissing = false;
          }
        }
      }

      //ensure resolved from field is complete
      if (this.worklistQuery.resolvedFromDateMM || this.worklistQuery.resolvedFromDateDD || this.worklistQuery.resolvedFromDateYYYY) {
        resolvedFromMissing = true;
        if ((this.worklistQuery.resolvedFromDateMM && this.worklistQuery.resolvedFromDateDD && this.worklistQuery.resolvedFromDateYYYY) && this.worklistQuery.resolvedFromDateYYYY.length == 4) {
          resolvedRangeStart = this.worklistQuery.resolvedFromDateMM + '/' + this.worklistQuery.resolvedFromDateDD + '/' + this.worklistQuery.resolvedFromDateYYYY;
          resolvedRangeStart = moment(resolvedRangeStart)
          if (resolvedRangeStart.isValid()) {
            resolvedFromMissing = false;
          }
        }
      }

      //ensure resolved to field is complete
      if (this.worklistQuery.resolvedToDateMM || this.worklistQuery.resolvedToDateDD || this.worklistQuery.resolvedToDateYYYY) {
        resolvedToMissing = true;
        if ((this.worklistQuery.resolvedToDateMM && this.worklistQuery.resolvedToDateDD && this.worklistQuery.resolvedToDateYYYY) && this.worklistQuery.resolvedToDateYYYY.length == 4) {
          resolvedRangeEnd = this.worklistQuery.resolvedToDateMM + '/' + this.worklistQuery.resolvedToDateDD + '/' + this.worklistQuery.resolvedToDateYYYY;
          resolvedRangeEnd = moment(resolvedRangeEnd)
          if (resolvedRangeEnd.isValid()) {
            resolvedToMissing = false;
          }
        }
      }
    }

    // ensure created fields have a valid range
    if (createdRangeStart > createdRangeEnd) {
      createValidRange = true;
    } else {
      createValidRange = false;
    }

    // ensure resolved fields have a valid range
    if (resolvedRangeStart > resolvedRangeEnd) {
      resolvedValidRange = true;
    } else {
      resolvedValidRange = false;
    }

    // ensure created fields are complete
    if (createFromMissing || createToMissing) {
      flag = true;
    }
    // ensure created fields have valid range
    if (createValidRange) {
      this.showCreateErrMsg = true;
      flag = true;
    } else {
      this.showCreateErrMsg = false;
    }

    // ensure resolved fields are complete
    if (resolvedFromMissing || resolvedToMissing) {
      flag = true;
    }
    // ensure resolved fields have valid range
    if (resolvedValidRange) {
      this.showResolveErrorMessage = true;
      flag = true;
    } else {
      this.showResolveErrorMessage = false;
    }

    return flag;
  }

  searchWorklistHistory() {
    this.worklistQuery.creationFromDate = this.worklistQuery.createdFromDateMM + '/' + this.worklistQuery.createdFromDateDD + '/' + this.worklistQuery.createdFromDateYYYY;
    this.worklistQuery.creationToDate = this.worklistQuery.createdToDateMM + '/' + this.worklistQuery.createdToDateDD + '/' + this.worklistQuery.createdToDateYYYY;
    this.worklistQuery.resolutionFromDate = this.worklistQuery.resolvedFromDateMM + '/' + this.worklistQuery.resolvedFromDateDD + '/' + this.worklistQuery.resolvedFromDateYYYY;
    this.worklistQuery.resolutionToDate = this.worklistQuery.resolvedToDateMM + '/' + this.worklistQuery.resolvedToDateDD + '/' + this.worklistQuery.resolvedToDateYYYY;
    if (this.worklistQuery.creationFromDate.indexOf('undefined') !== -1 || this.worklistQuery.creationFromDate === '//') {
      this.worklistQuery.creationFromDate = '';
    }
    if (this.worklistQuery.creationToDate.indexOf('undefined') !== -1 || this.worklistQuery.creationToDate === '//') {
      this.worklistQuery.creationToDate = '';
    }
    if (this.worklistQuery.resolutionFromDate.indexOf('undefined') !== -1 || this.worklistQuery.resolutionFromDate === '//') {
      this.worklistQuery.resolutionFromDate = '';
    }
    if (this.worklistQuery.resolutionToDate.indexOf('undefined') !== -1 || this.worklistQuery.resolutionToDate === '//') {
      this.worklistQuery.resolutionToDate = '';
    }


    //define search object
    var searchObject = {
      creationFromDate: this.worklistQuery.creationFromDate?.trim(),
      creationToDate: this.worklistQuery.creationToDate?.trim(),
      initiatingState: this.worklistQuery.initiatingState,
      page: this.worklistQuery.page?.trim(),
      queryType: this.queryType?.trim(),
      resolutionFromDate: this.worklistQuery.resolutionFromDate?.trim(),
      resolutionToDate: this.worklistQuery.resolutionToDate?.trim(),
      sortBy: '2',
      sortOrder: 'descending',
      stateStudentId: this.worklistQuery.stateStudentId?.trim(),
      studentFirstName: this.worklistQuery.studentFirstName?.trim(),
      studentLastName: this.worklistQuery.studentLastName?.trim(),
      userFirstName: this.worklistQuery.userFirstName?.trim(),
      userLastName: this.worklistQuery.userLastName?.trim(),
      worklistId: this.worklistQuery.worklistId?.trim(),
      worklistStatus: this.worklistQuery.worklistStatus?.trim(),
      worklistType: this.worklistQuery.worklistType
    };

    this.searchforstudents.emit({
      searchobject: searchObject
    });
    this._router.navigate(['/worklistHistory'], {
      queryParams: {
        creationFromDate: this.worklistQuery.creationFromDate,
        creationToDate: this.worklistQuery.creationToDate,
        initiatingState: this.worklistQuery.initiatingState,
        page: this.worklistQuery.page,
        queryType: this.queryType,
        resolutionFromDate: this.worklistQuery.resolutionFromDate,
        resolutionToDate: this.worklistQuery.resolutionToDate,
        sortBy: '2',
        sortOrder: 'descending',
        stateStudentId: this.worklistQuery.stateStudentId,
        studentFirstName: this.worklistQuery.studentFirstName,
        studentLastName: this.worklistQuery.studentLastName,
        userFirstName: this.worklistQuery.userFirstName,
        userLastName: this.worklistQuery.userLastName,
        worklistId: this.worklistQuery.worklistId,
        worklistStatus: this.worklistQuery.worklistStatus,
        worklistType: this.worklistQuery.worklistType,
        worklistHistorySearchType: this.$stateParams.worklistHistorySearchType
      }
    });

  }

  clearSearchFields() {
    this.worklistQuery = {};
    this.showCreateErrMsg = false;
    this.showResolveErrorMessage = false;
  }

}