<div styles="display: flex; flex-direction: row" class="reportsFilter learnMoreModal">
    <div aria-label="additional information" class="mat-dialog filter-dialog">
      <mat-toolbar>
        <div class="md-toolbar-tools header">
          <h2 class="filter-title">{{dialogData.title ? dialogData.title : 'Learn More'}}</h2>
          <button tabindex="0" class="md-button md-icon-button" matDialogClose aria-label="close dialog">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </mat-toolbar>
  
      <mat-dialog-content>
        <div class="dialog-info-content" [innerHTML]="dialogData.message"></div>
      </mat-dialog-content>
    </div>
  </div>