import { NgModule } from "@angular/core";
import { SharedModule } from '../../shared.module';
import { ChildDetailsComponent } from '../child-details/child-details.component';
import { StudentDetailsHistoricalRawViewComponent } from "../student-details-historical-raw-view/student-details-historical-raw-view.component";

@NgModule({
    declarations: [ChildDetailsComponent, StudentDetailsHistoricalRawViewComponent],
    imports: [SharedModule],
    providers: [],
    exports: [ChildDetailsComponent, StudentDetailsHistoricalRawViewComponent]
})
export class StudentDetailsModule { }