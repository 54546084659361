<div class="answerCQs-page background-awesome" layout="row" layout-align="center start">
  <!-- Mobile (portrait) - flex 95; Mobile (landscape) - flex 45; Tablet (portrait) - flex 35; Tablet (landscape) - flex 35; Desktop - flex 55-->
  <div class="md-content" [ngClass]="{'layoutMobP': (responsiveUI.isMobile && responsiveUI.isPortrait), 'layoutMobL': (responsiveUI.isMobile && !responsiveUI.isPortrait),
  'layoutTabP': (responsiveUI.isTablet && responsiveUI.isPortrait), 'layoutTabL': (responsiveUI.isTablet && !responsiveUI.isPortrait), 'layoutDesktop': responsiveUI.isDesktop}">

    <div class="title">
      <h1>Challenge Question Response Page</h1>
    </div>

    <div class="answer-section">
      <form name="answerhallengeQuestionForm" (submit)="submitForm()">
        <p>Challenge Question 1: {{question1}}</p>
        <div class="custom-input-container">
            <mat-form-field class="md-block input-container">
              <mat-label class="custom-label">Response 1</mat-label>
              <input matInput class="custom-input" 
                [(ngModel)]="response1" 
                name="response1"
                #description1
                [ngClass]="{'mat-input-has-value': description1.value}"/>
            </mat-form-field>
        </div>

        <p style="padding-top: 15px;">Challenge Question 2: {{question2}}</p>
        <div class="custom-input-container">
            <mat-form-field class="md-block input-container">
              <mat-label class="custom-label">Response 2</mat-label>
              <input matInput class="custom-input" [(ngModel)]="response2" 
              name="response2"
              #description2
              [ngClass]="{'mat-input-has-value': description2.value}"/>
            </mat-form-field>
        </div>

        <p style="padding-top: 15px;">Challenge Question 3: {{question3}}</p>
        <div class="custom-input-container ">
            <mat-form-field class="md-block input-container">
              <mat-label class="custom-label">Response 3</mat-label>
              <input matInput class="custom-input" [(ngModel)]="response3" 
              name="response3"
              #description3
              [ngClass]="{'mat-input-has-value': description3.value}"/>
            </mat-form-field>
        </div>

        <div class="center">
          <button class="md-button button" type="submit" [disabled]="(!response1 || !response2 || !response3)">Submit</button>
        </div>

      </form>
    </div>

  </div>
  <p class="version-number">{{appVersion}}</p>
</div>
