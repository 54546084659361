<div class="login-page background-awesome layout-flex-row"layout-align="center center">
  <!--  -->
  <div class="msix-login">
    <div class="mfaBanner mfaTextPadding flex-main"
      *ngIf="!formData.isMFA && formData.showRegistrationDecision && mfaSetupStep ==''">
      Multi-Factor Authentication is now mandatory in MSIX.
      Please configure an MFA application to access MSIX.
      Contact the MSIX Help Desk at 1-866-878-9525 if you need assistance.
    </div>
    <div class="login-section layout-flex-column flex-main"
      *ngIf="!formData.isMFA && formData.showRegistrationDecision && mfaSetupStep ==''">

      <div layout-margin>

        <div class="title">
          <h1>Migrant Student Information Exchange</h1>
        </div>

        <div *ngIf="errors.length > 0">
          <div class="errors flex-inline" *ngFor="let error of errors">
            <i class="fa fa-times" aria-hidden="true"></i>&nbsp;
            <p class="validation">{{ error }}</p>
          </div>
        </div>

        <div class="success flex-inline" *ngIf="successFlag">
          <i class="fa fa-check-circle" aria-hidden="true"></i>&nbsp;
          <p class="successP">Your password has been successfully reset.</p>
        </div>

        <div class="login-form">
          <form [formGroup]="loginForm" (ngSubmit)="submitForm('login')" style="display: grid;">
            <div style="width: 94%; justify-self: center;">
              <custom-mat-input 
                customlabel="Username"
                errorlength="{{errors.length}}"
                [(ngModel)]="formData.username"
                formControlName="username"
                [userform]="false"
                (keypress)="enter($event)">
              </custom-mat-input>
            </div>
            <div style="width: 94%; justify-self: center; margin-top: -11px;">
              <custom-mat-input 
                customlabel="Password"
                errorlength="{{errors.length}}"
                textType="password"
                [(ngModel)]="formData.password"
                formControlName="password"
                [userform]="false"
                (keypress)="enter($event)">
              </custom-mat-input>
            </div>

            <div layout="row" class="center">
              <button type="submit" class="md-button" [disabled]="!(loginForm.controls['username'].value && formData.password)">
                Sign In</button>
              <a tabindex="0" ng-keypress="goToForgotYourPassword()" (click)="goToForgotYourPassword()"
                class="loginHelpButton"><span>Forgot Your Password?</span></a>
              <a tabindex="0" ng-keypress="goToStateContactSearch()" (click)="goToStateContactSearch()"
                class="loginHelpButton"><span>State Contact Search</span></a>
            </div>
          </form>
        </div>

        <div class="center hillsCenter">
          <img src="../../../msix/assets/images/backgrounds/login_hills.png" alt="Login Hills" class="login-hills" />
        </div>

      </div>
    </div>

    <div class="layout-flex-column" *ngIf="formData.isMFA || mfaSetupStep != ''">
      <!--MFA code entry form-->
      <div class="mfa-login" *ngIf="formData.isMFA && !formData.isRegistrationWorkflow && !verifyUserUsingCQA">

        <form name="mfaForm" (ngSubmit)="submitMFA($event)">
          <div class="title">
            Verify your login to MSIX<br /><br />
          </div>

          <div class="subTitle">
            Enter the six-digit code displayed in your authenticator app
            <br /><br />
          </div>

          <div class="custom-input-container center">
            <mat-form-field class="md-input-container-margins md-block" style="width: 100%">
              <mat-label class="custom-label" [ngClass]="{'label-error' : invalidMFACode}">MFA Code</mat-label>
              <input matInput class="custom-input" type="text" inputmode="numeric" [(ngModel)]="formData.mfaCode"
                name="mfaCode" [ngClass]="{'input-error' : invalidMFACode, 'mat-input-has-value': formData.mfaCode}">
            </mat-form-field>
            <br />
          </div>

          <div>
            <div class="center layout-flex-row">
              <a tabindex="0" class="loginHelpButton" (click)="configureNewDeviceAfterUnamePwdLogin()">
                <span>Reset Multi-Factor Authentication</span>
              </a>
            </div>
          </div>

          <div class="center layout-flex-row">
            <button type="submit" class="md-button" [disabled]="(!formData.mfaCode) || attempts >= 3">Submit</button>
          </div>

          <div *ngIf="invalidMFACode">
            <div class="errors flex-inline" *ngFor="let error of errors">
              <i class="fa fa-times" aria-hidden="true"></i>&nbsp;
              <p class="mfavalidation">The code could not be verified.<br />
                <!--<script type="text/javascript">
                  document.write(error)
                </script>-->
                {{ error }} <br />
              </p>
            </div>
          </div>
        </form>
        <div class="center hillsCenter">
          <img src="../../../msix/assets/images/backgrounds/login_hills.png" alt="Login Hills" class="login-hills" />
        </div>
      </div>

      <!-- Registration decision form -->
      <div class="mfa-login" *ngIf="mfaSetupStep === 'default'">
        <form name="mfaRegistrationDecision" (ngSubmit)="mfaSetupNext('download')">
          <div class="title">
            MSIX Multi Factor Authentication Setup
          </div>
          <div class="subTitle">
            Configure your MSIX account <br />
            to use a Time-Based One-Time <br />
            Password (OTP) from an Authenticator Application.
          </div>

          <div>
            <div layout="row" class="nextButton center">
              <button style="border: none;" type="submit" class="md-button">Next</button>
              <a *ngIf="mfaSkipAllowed" tabindex="0" ng-keypress="mfaSetupSkipOrBack('skip')"
                (click)="mfaSetupSkipOrBack('skip')" class="loginHelpButton"><span>Skip for now</span></a>
            </div>
          </div>
        </form>
        <div class="center hillsCenter">
          <img src="../../../msix/assets/images/backgrounds/login_hills.png" alt="Login Hills" class="login-hills">
        </div>
      </div>

      <!-- MFA Registration - Download app form -->
      <div class="mfa-register" *ngIf="formData.isRegistrationWorkflow && mfaSetupStep === 'download'" [ngClass]="{'mobile-portrait-width': (responsiveUI.isMobile && responsiveUI.isPortrait)}">
        <form name="mfaRegistrationDownload" (ngSubmit)="mfaSetupNext('register')">
          <div layout="column">
            <div layout="row" layout-align="center center" class="mfa-breadcrumbs icon-row">
              <i class="fa fa-circle statusCircleCurrent breadcrumb-icons"></i>
              <hr class="thinHr breadcrumb-dashes">
              <i class="fa fa-circle-thin breadcrumb-icons"></i>
              <hr class="thinHr breadcrumb-dashes">
              <i class="fa fa-circle-thin breadcrumb-icons"></i>
            </div>
            <div layout="row" layout-align="center center" class="mfa-breadcrumbs">
              <p class="skipDisclaimer breadcrumb-stages">Download App</p>
              <p class="skipDisclaimer breadcrumb-stages">Configure</p>
              <p class="skipDisclaimer breadcrumb-stages">Verify</p>
            </div>
          </div>
          <div class="title" style="padding-bottom: 15px;">
            Download Authenticator Application
          </div>
          <div class="skipDisclaimer" style="padding: 0px 13px;">
            To utilize Multi-Factor Authentication (MFA)
            to login to MSIX, an authenticator app must be
            downloaded to your device. Supported devices
            include PCs (Windows and MacOS), Mobile (Android
            and iOS), and web browsers such as Firefox and
            Google Chrome. </div>

          <div class="skipDisclaimer">
            Download one of the four authenticators listed below: <br /><br />
            Google Authenticator
            (<a class="authLink" target="_blank" rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&hl=en_US&gl=US">Android</a>
            and <a class="authLink" target="_blank" rel="noopener noreferrer"
              href="https://apps.apple.com/us/app/google-authenticator/id388497605">iOS</a>) <br />
            <br />
            Microsoft Authenticator
            (<a class="authLink" target="_blank" rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.azure.authenticator&hl=en_US&gl=US">Android</a>
            and <a class="authLink" target="_blank" rel="noopener noreferrer"
              href="https://apps.apple.com/us/app/microsoft-authenticator/id983156458">iOS</a>) <br />
            <br />
            Authy
            (<a class="authLink" target="_blank" rel="noopener noreferrer"
              href="https://authy.com/download/">Windows</a>,
            <a class="authLink" target="_blank" rel="noopener noreferrer" href="https://authy.com/download/">MacOS</a>,
            <a class="authLink" target="_blank" rel="noopener noreferrer"
              href="https://play.google.com/store/apps/details?id=com.authy.authy&hl=en_US&gl=US">Android</a>
            and <a class="authLink" target="_blank" rel="noopener noreferrer"
              href="https://apps.apple.com/us/app/authy/id494168017">iOS</a>) <br />
            <br />
            Authenticator
            (<a class="authLink" target="_blank" rel="noopener noreferrer"
              href="https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai?hl=en">Chrome</a>
            and
            <a class="authLink" target="_blank" rel="noopener noreferrer"
              href="https://addons.mozilla.org/en-US/firefox/addon/auth-helper/">Firefox</a> Browser Extensions)<br />
          </div>

          <div class="skipDisclaimer" style="padding: 0px 13px;">
            After the authenticator application is installed on your device, select the next button to proceed with the
            configuration.<br />
          </div>
          <div class="skipDisclaimer_underline" style="padding: 0px 13px;">
            If you need assistance, please reach out to the MSIX Help Desk at 1-866-878-9525.</div>

          <div layout="row" class="center">
            <button type="submit" class="md-button">Next
            </button>
            <a tabindex="0" ng-keypress="mfaSetupSkipOrBack('default')" (click)="mfaSetupSkipOrBack('default')"
              class="loginHelpButton"><span>Back</span></a>
          </div>
          <div style="padding-top: 20px;" class="center hillsCenter">
            <img src="../../../msix/assets/images/backgrounds/login_hills.png" alt="Login Hills" class="login-hills">
          </div>
        </form>
      </div>

      <!-- MFA Registration - MFA Rest Warning Page-->
      <div class="mfa-confirmReset" *ngIf="formData.isRegistrationWorkflow && mfaSetupStep === 'confirmReset'">
        <form name="mfaResetWarning" (ngSubmit)="mfaSetupNext('reset')">
          <div class="title">
            Are you sure?
          </div>

          <div class="subTitle">
            Selecting next will remove your current MFA configuration and allow you to configure a
            new authenticator application.
          </div>
          <br />
          <div layout="row" class="center">
            <button type="submit" class="md-button">Next
            </button>
            <a tabindex="0" ng-keypress="mfaSetupSkipOrBack('mfaEntry')" (click)="mfaSetupSkipOrBack('mfaEntry')"
              class="loginHelpButton"><span>Back</span></a>
          </div>
          <div class="center hillsCenter">
            <img src="../../../msix/assets/images/backgrounds/login_hills.png" alt="Login Hills" class="login-hills">
          </div>
        </form>
      </div>

      <!-- MFA Registration - QR Code form-->
      <div class="mfa-register" *ngIf="formData.isRegistrationWorkflow && mfaSetupStep === 'register'" [ngClass]="{'mobile-portrait-width': (responsiveUI.isMobile && responsiveUI.isPortrait)}">
        <form name="mfaRegistrationQRCode" (ngSubmit)="mfaSetupNext('verify')">
          <div layout="column">
            <div layout="row" layout-align="center center" class="mfa-breadcrumbs icon-row">
              <i class="fa fa-check-circle statusCircleComplete breadcrumb-icons"></i>
              <hr class="thinHr breadcrumb-dashes">
              <i class="fa fa-circle statusCircleCurrent breadcrumb-icons"></i>
              <hr class="thinHr breadcrumb-dashes">
              <i class="fa fa-circle-thin breadcrumb-icons"></i>
            </div>
            <div layout="row" layout-align="center center" class="mfa-breadcrumbs">
              <p class="skipDisclaimer breadcrumb-stages">Download App</p>
              <p class="skipDisclaimer breadcrumb-stages">Configure</p>
              <p class="skipDisclaimer breadcrumb-stages">Verify</p>
            </div>
          </div>
          <div class="title" style="padding-bottom: 15px;">
            Configure Authenticator Application
          </div>

          <div class="subTitle">
            Scan the image below.
          </div>
          <div>
            <!--img data-ng-src="{{qrCode}}" alt="QR Code" class="qrCode"-->
            <img [src]="qrCode" alt="QR Code" class="qrCode">
          </div>
          <div class="subTitle">
            If you are unable to scan the image, enter
            the following information in your app. </div>
          <div class="skipDisclaimer">
            {{ secretKey }}
          </div>
          <div class="subTitle">If the app displays a six-digit code, it has been configured!</div>

          <br />

          <div class="subTitle">
            For more detailed steps to configure your
            authenticator app, please select the link
            for the authenticator you downloaded:
          </div>

          <br />

          <div layout="row" class="center">
            <a tabindex="0" target="_blank" rel="noopener noreferrer"
              href="assets/trainingCorner/resources/msixguides/Google_Authenticator_Configuration_Aid.pdf">
              <span class="removeUnderline">Google Authenticator </span></a>
            <a tabindex="0" target="_blank" rel="noopener noreferrer"
              href="assets/trainingCorner/resources/msixguides/Microsoft_Authenticator_Configuration_Aid.pdf">
              <span class="removeUnderline">Microsoft Authenticator </span></a>
            <a tabindex="0" target="_blank" rel="noopener noreferrer"
              href="assets/trainingCorner/resources/msixguides/Authy_Configuration_Aid.pdf">
              <span class="removeUnderline"> Authy </span></a>
            <a tabindex="0" target="_blank" rel="noopener noreferrer"
              href="assets/trainingCorner/resources/msixguides/Authenticator_Browser_Extension_Configuration_Steps_Aid.pdf">
              <span class="removeUnderline"> Authenticator </span></a>
          </div>

          <div class="skipDisclaimer_underline">If you need assistance, please reach out to the MSIX Help Desk at
            1-866-878-9525.</div>
          <div layout="row" class="center">
            <button type="submit" class="md-button">Next</button>
            <a tabindex="0" ng-keypress="mfaSetupSkipOrBack('download')" (click)="mfaSetupSkipOrBack('download')"
              class="loginHelpButton"><span>Back</span></a>
          </div>
          <div class="center hillsCenter">
            <img src="../../../msix/assets/images/backgrounds/login_hills.png" alt="Login Hills" class="login-hills">
          </div>
        </form>
      </div>

      <!-- MFA Registration - Verify QR Code form -->
      <div class="mfa-login" *ngIf="formData.isRegistrationWorkflow && mfaSetupStep === 'verify'">
        <form name="mfaRegistrationVerifyQRCode" (ngSubmit)="mfaSetupNext('confirmRegistration')">
          <div layout="column">
            <div layout="row" layout-align="center center" class="mfa-breadcrumbs icon-row">
              <i class="fa fa-check-circle statusCircleComplete breadcrumb-icons"></i>
              <hr class="thinHr breadcrumb-dashes">
              <i class="fa fa-check-circle statusCircleComplete breadcrumb-icons"></i>
              <hr class="thinHr breadcrumb-dashes">
              <i class="fa fa-circle statusCircleCurrent breadcrumb-icons"></i>
            </div>
            <div layout="row" layout-align="center center" class="mfa-breadcrumbs">
              <p class="skipDisclaimer breadcrumb-stages">Download App</p>
              <p class="skipDisclaimer breadcrumb-stages">Configure</p>
              <p class="skipDisclaimer breadcrumb-stages">Verify</p>
            </div>
          </div>
          <div class="title">
            Verify Authenticator <br> Application
          </div>
          <div class="skipDisclaimer">
            Enter the six-digit code displayed on your app
          </div>
          <div style="padding: 5px;" class="custom-input-container center">
            <mat-form-field class="md-input-container-margins md-block" 
              style="display: flex; width: 97%; margin: -12px;">
              <mat-label style="margin-top: 13px;" class="custom-label" for="mfaCode" [ngClass]="{'label-error' : invalidMFACode}">MFA Code</mat-label>
              <input matInput style="width: 98.5%;" class="custom-input" type="text" inputmode="numeric"
                [(ngModel)]="formData.mfaCode" name="mfaCode" id="mfaCode"
                [ngClass]="{'input-error' : invalidMFACode,'mat-input-has-value': (!invalidMFACode && formData.mfaCode)}">
            </mat-form-field>
          </div>

          <div class="errors flex-inline" *ngIf="invalidMFACode">
            <i class="fa fa-times" aria-hidden="true"></i>&nbsp;
            <p class="mfavalidation">The code could not be verified.<br />
              Please enter a new code from the authenticator application.
            </p>
          </div>

          <div class="center">
            <button type="submit" [disabled]="(!formData.mfaCode)" class="md-button">Next</button>
            <a tabindex="0" ng-keypress="mfaSetupSkipOrBack('register')" (click)="mfaSetupSkipOrBack('register')"
              class="loginHelpButton"><span>Back</span></a>
          </div>
          <div class="center hillsCenter">
            <img src="../../../msix/assets/images/backgrounds/login_hills.png" alt="Login Hills" class="login-hills">
          </div>
        </form>
      </div>

      <!-- MFA Registration - Success form -->
      <div class="mfa-login" *ngIf="formData.isRegistrationWorkflow && mfaSetupStep === 'success'">
        <form name="mfaRegistrationSuccess" (ngSubmit)="mfaSetupNext('done')">
          <div class="layout-flex-column">
            <div layout-align="center center" class="mfa-breadcrumbs icon-row layout-flex-row">
              <i class="fa fa-check-circle statusCircleComplete breadcrumb-icons"></i>
              <hr class="thinHr breadcrumb-dashes">
              <i class="fa fa-check-circle statusCircleComplete breadcrumb-icons"></i>
              <hr class="thinHr breadcrumb-dashes">
              <i class="fa fa-check-circle statusCircleComplete breadcrumb-icons"></i>
            </div>
            <div layout-align="center center" class="mfa-breadcrumbs layout-flex-row">
              <p class="skipDisclaimer breadcrumb-stages">Download App</p>
              <p class="skipDisclaimer breadcrumb-stages">Configure</p>
              <p class="skipDisclaimer breadcrumb-stages">Verify</p>
            </div>
          </div>
          <div class="title">
            Success!
          </div>
          <div class="skipDisclaimer">
            Your account is configured to use Multi-Factor Authentication.
          </div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>

          <div>
            <div class="center layout-flex-row">
              <button type="submit" class="md-button">
                Done
              </button>
            </div>
          </div>
        </form>
      </div>

      <!-- Verify user using challenge auestions and answers -->
      <div>
        <form name="answerChallengeQuestionForm" (ngSubmit)="submitToVerifyChallengeQAndAToVerifyUser()"
          *ngIf="verifyUserUsingCQA">
          <div layout="column" class="mfaCqa-section">
            <div class="center" style="margin: 5px;">
              <p class="center">Challenge Question 1: {{ challengeQuestions[0].question }}</p>
              <div class="custom-input-container">
                <mat-form-field class="md-block input-container">
                  <mat-label class="custom-label">Response 1</mat-label>
                  <input matInput class="custom-input" [(ngModel)]="response[0]" name="response1">
                </mat-form-field>
              </div>
              <p class="mfaCQA-section-questions-padding">Challenge Question 2: {{ challengeQuestions[1].question }}</p>
              <div class="custom-input-container">
                <mat-form-field class="md-block input-container">
                  <mat-label class="custom-label">Response 2</mat-label>
                  <input matInput class="custom-input" [(ngModel)]="response[1]" name="response2">
                </mat-form-field>
              </div>
              <p class="mfaCQA-section-questions-padding">Challenge Question 3: {{ challengeQuestions[2].question }}</p>
              <div class="custom-input-container">
                <mat-form-field class="md-block input-container">
                  <mat-label class="custom-label">Response 3</mat-label>
                  <input matInput class="custom-input" [(ngModel)]="response[2]" name="response3">
                </mat-form-field>
              </div>
            </div>

            <div class="center" style="margin-top: 20px;">
              <button type="submit" class="md-button" style="margin-top:0px" [disabled]="(!response[0] || !response[1] || !response[2])">
                Submit
              </button>
            </div>

            <div style="padding-top: 30px;" class="center hillsCenter">
              <img src="../../../msix/assets/images/backgrounds/login_hills.png" alt="Login Hills" class="login-hills">
            </div>
          </div>
        </form>
      </div>

    </div>
  </div>

</div>