<div *ngIf="residencyChangeItems.length == 0" class="layout-margin-worklist-module layout-padding-worklist-module" style="margin: 15px 65px">
  <h2>No Move Notice items found.</h2>
  <p>Move notices that have been sent to you from other areas as well as move notices that you have initiated will be
    shown
    here.
  </p>
  <p>To initiate a move notice, start by
    <a (click)="goToSearch()">searching for records</a>
  </p>
</div>

<!--START Move Notice Desktop-->

<div *ngIf="responsiveUI.isDesktop && residencyChangeItems.length > 0" class="worklist-page layout-margin-worklist-module layout-padding-worklist-module">

  <div class="layout-padding-worklist-submodule background-white">
    <div class="desktopEnrollments removeBorders">
      <div class="layout-flex-row tableRow headerRow">
        <h3>Move Notice</h3>
      </div>
    </div>
    <div class="desktopEnrollments removeBorders layout-flex-row">
      <p class="flex-65 worklist-description-text">
        Expand the row to view demographic information on children with recent move notifications. Use the
        correspondence section to communicate with the initiator with questions regarding the move notice. The move
        notices can be reassigned to other State, Regional, or District Data Administrators for their action.
      </p>
      <div class="flex-35 filter-dropdown layout-column">
        <p>Showing:</p>
        <mat-form-field class="dropdown move-notice-showing-box" style="border: 1px solid gray">
          <mat-select class="md-select-input-height" [ngModelOptions]="{standalone: true}" [(ngModel)]="selectedMNFilter">
            <mat-option *ngFor="let option of MNFilterOptions" [selected]="'All Open Notices'" (click)="filterMNOptions(selectedMNFilter)"
              [value]="option">
              {{option}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <!-- Beginning of move notice -->
    <div class="worklist-header-titles" layout="row">
      <div class="flex-95">
        <div class="flex-container flex-90">
          <div class="block2 flex-20" >
            <a (click)="sortColumns('column1')">
              Student
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column1'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column1'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-15">
            <a (click)="sortColumns('column2')">
              MSIX ID
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column2'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column2'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-15">
            <a (click)="sortColumns('column3')">
              State ID
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column3'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column3'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-15">
            <a (click)="sortColumns('column4')">
              Worklist
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column4'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column4'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-15">
            <a (click)="sortColumns('column5')">
              Move Type
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column5'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column5'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-15">
            <a (click)="sortColumns('column6')">
              Initiated By
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column6'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column6'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
          <div class="block2 flex-15">
            <a (click)="sortColumns('column7')">
              Created
              <i class="fa fa-caret-down fa-lg" aria-hidden="true" *ngIf="'column7'===currentSortColumn && !sortAscending"></i>
              <i class="fa fa-caret-up fa-lg" aria-hidden="true" *ngIf="'column7'===currentSortColumn && sortAscending"></i>
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="layout-flex-row table2Row" [ngClass]="{'background-white' : !(studentMNIndex === student.key), 'background-grey1' : (studentMNIndex === student.key)}"
      *ngFor="let student of MNFilteredItems">
      <div class="flex-95 background-white">
        <!-- move notice -->
        <div class="layout-flex-row">
          <div class="layout-flex-row section1 flex-90" (click)="showMNDetailsFn(student.key); updateReadFlag(student)">
            <span class="flex-20 column" [ngClass]="{highlight: currentSortColumn === 'column1'}">
              <p [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                {{student.demographics.lastName1}}<span class="removeUnderline" *ngIf="student.demographics.lastName2 !=''">
                  {{student.demographics.lastName2}}</span>,
                {{student.demographics.firstName}}
                <span class="removeUnderline" *ngIf="student.demographics.midName != ''">
                  {{student.demographics.midName}}</span>
              </p>
            </span>
            <span class="flex-15 column" [ngClass]="{highlight: currentSortColumn === 'column2'}">
              <p [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                {{student.demographics.msixId}}
              </p>
            </span>
            <span class="flex-15 column" [ngClass]="{highlight: currentSortColumn === 'column3'}">
              <p [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                {{student.demographics.studentStateID}}
              </p>
            </span>
            <span flex class="flex-15 column" [ngClass]="{highlight: currentSortColumn === 'column4'}">
              <p [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                {{student.key}}
              </p>
            </span>
            <span flex class="flex-15 column" [ngClass]="{highlight: currentSortColumn === 'column5'}">
              <p [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}" *ngIf="student.itemType ===1">
                From
              </p>
              <p [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}" *ngIf="student.itemType ===3">
                To
              </p>
              <i flex class="fa fa-minus flex-15" aria-hidden="true" *ngIf="student.itemType != 1 && student.itemType != 3"></i>
            </span>
            <span flex class="flex column" [ngClass]="{highlight: currentSortColumn === 'column6'}">
              <p [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                {{student.creator.stateCode}}
              </p>
            </span>
            <span flex class="flex-15 column" [ngClass]="{highlight: currentSortColumn === 'column7'}">
              <p [ngClass]="{unread: isWorklistRead(student.isWorklistRead)}">
                <span class="layout-flex-row days-in-list">{{convertDateCreated(student.dateCreated)}}</span>
                <span class="layout-flex-row days-in-list">{{daysOnList(student.dateCreated)}} days in list</span>
              </p>
            </span>
          </div>
          <div class="flex-10 flex-container">
            <p>
              <a flex (click)="viewDetails(student)" class="top-bottom-margin-8 flexCenter">
                <span>Full Record</span>
              </a>
            </p>
          </div>
        </div>

        <div class="flex-95 section2 worklist-accordion-section2 background-white" *ngIf="studentMNIndex === student.key">

          <div class="col1 flex-15 layout-column">
            <div class="top-bottom-margin-8">
              <p><b>Sex:</b></p>
              <p>{{student.demographics.gender}}</p>
            </div>
          </div>
          <div class="col1 flex-15 layout-column">
            <div class="top-bottom-margin-8">
              <p><b>DOB:</b></p>
              <p>{{student.demographics.birthDate}}
              </p>
            </div>
            <div class="top-bottom-margin-8">
              <p><b>Multi-birth:</b></p>
              <p> {{student.demographics.multipleBirthFlag}}</p>
            </div>
          </div>
          <div class="col1 flex-20 layout-column">
            <div class="top-bottom-margin-8">
              <p><b>Parent 1:</b></p>
              <p>{{student.demographics.maleParentFirstName}} {{student.demographics.maleParentLastName}}
              </p>
            </div>
            <div class="top-bottom-margin-8">
              <p><b>Parent 1 Email Address:</b></p>
              <p>{{student.demographics.parent1EmailAddress}}
              </p>
            </div>
            <div class="top-bottom-margin-8">
              <p><b>Parent 1 Phone Number:</b></p>
              <p>{{student.demographics.parent1PhoneNumber}}
              </p>
            </div>
          </div>
          <div class="col1 flex-20 layout-column">
            <div class="top-bottom-margin-8">
              <p><b>Parent 2:</b></p>
              <p> {{student.demographics.femaleParentFirstName}} {{student.demographics.femaleParentLastName}}
              </p>
            </div>
            <div class="top-bottom-margin-8">
              <p><b>Parent 2 Email Address:</b></p>
              <p>{{student.demographics.parent2EmailAddress}}
              </p>
            </div>
            <div class="top-bottom-margin-8">
              <p><b>Parent 2 Phone Number:</b></p>
              <p>{{student.demographics.parent2PhoneNumber}}
              </p>
            </div>
          </div>
          <div class="col1 flex-15">
            <a flex *ngIf="!showResolve" (click)="goToReassign(student)" class="top-bottom-margin-8 flexCenter">
              <span>Reassign</span>
            </a>
          </div>
          <div class="col1 flex-15">
            <button flex *ngIf="!showResolve" (click)="resolveModal(student)" class="md-button top-bottom-margin-8">
              Dismiss
            </button>
          </div>
        </div>

        <!--correspondence-->

        <div class="background-white layout-flex-row flex" *ngIf="studentMNIndex === student.key" layout="row">
          <div class="flex-95">
            <div class="correspondenceSectionHeader worklist-box-border">
              <p><b>Correspondence</b></p>
            </div>
            <div class="correspondenceBody">
              <div class="correspondenceSection flex flex-container">
                <div class="flex-container correspondenceRow flex" layout="row">
                  <p class="flex"><b>{{student.creator.firstName}} {{student.creator.lastName}}</b></p>
                  <p class="flex-25 right-align"><b>{{student.creator.dateCreated}}</b></p>
                </div>
                <div class="flex-container correspondenceRow flex" layout="row">
                  <p class="flex">{{student.creator.comments}}</p>
                </div>
              </div>

              <div *ngIf="student.corMessages.length > 0">
                <div class="correspondenceSection flex flex-container" *ngFor="let message of student.corMessages">
                  <div class="flex-container correspondenceRow layout-flex-row flex">
                    <p class="flex"><b>{{message.msgSubmittingUserFN}} {{message.msgSubmittingUserLN}}</b></p>
                    <p class="flex-25 right-align"><b>{{message.msgDate}}</b></p>
                  </div>
                  <div class="flex-container correspondenceRow layout-flex-row flex">
                    <p class="flex">{{message.message}}</p>
                  </div>
                </div>
              </div>
              <div flex class="custom-input-container">
                <mat-form-field class="md-block md-input-container-margins background-white comment-box" style="padding-right:1px; padding-bottom:1px">
                  <mat-label class="custom-label">Message</mat-label>
                  <textarea matInput class="custom-input comment-text" [(ngModel)]="corMessageMN.message" maxlength="250"
                    rows="5" md-select-on-focus>
                  </textarea>
                  <div class="md-errors-spacer">
                    <div class="md-char-counter">{{corMessageMN.message.length}} / 250</div>
                  </div>
                </mat-form-field>
              </div>
              <div class="layout-flex-row">
                <div class="flex-85 commentbox-instructions">
                  <p class=" small worklist-commentbox-instructions">
                    Use this form to submit questions regarding the student and their records. This will ensure the
                    correspondence is logged
                    in the MSIX system and will show above.
                  </p>
                </div>
                <button (click)="sendMoveNoticeCorrespondence(student)" [disabled]="corMessageMN.message === ''"
                  class="top-bottom-margin-8 flex-15 md-button">
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="flex-5" (click)="showMNDetailsFn(student.key); updateReadFlag(student)" class="expandColumn-green flex-container">
        <i class="fa fa-chevron-down accordion-icon" aria-hidden="true" *ngIf="!(studentMNIndex === student.key)"></i>
        <i class="fa fa-chevron-up accordion-icon" aria-hidden="true" *ngIf="studentMNIndex === student.key"></i>
      </div>

    </div>
    <!-- End of move notice -->
  </div>
</div>

<!--END Move Notice Desktop-->



<!--MOBILE AND TABLET START-->

<div flex class="worklist-module top-bottom-margin-5" *ngIf="!responsiveUI.isDesktop && residencyChangeItems.length > 0">
  <div layout-padding class="dashboard-module-body">
    <!--mobile & tablet data requests-->
    <div class="top-bottom-margin-8">
      <div class="layout-padding-l layout-padding-r">
        <div class="desktopEnrollments removeBorders">
          <div class="tableHeader">
            <div class="layout-flex-row tableRow headerRow no-padding">
              <p class="worklist-section-header">Move Notices</p>
            </div>
          </div>
        </div>
        <div class="layout-padding-bottom-15">
          <p>To dismiss a move notice, access your worklist on desktop.</p>
        </div>
        <div hide-gt-md>
          <div class="results-border top-bottom-padding-8" *ngFor="let student of residencyChangeItems">
            <div class="layout-flex-row" (click)="viewDetails(student)">
              <div flex class="layout-flex-column">
                <a class="uppercase">
                  {{student.demographics.lastName1}}<span class="removeUnderline" *ngIf="student.demographics.lastName2 !=''">
                    {{student.demographics.lastName2}}</span>,
                  {{student.demographics.firstName}}
                  <span *ngIf="student.demographics.midName != ''"> {{student.demographics.midName}}</span>
                </a>
                <div flex>
                  <p class="small small-grey2andahalf uppercase">ID: {{student.key}} | DOB:
                    {{student.demographics.birthDate}}
                    |&nbsp;{{student.demographics.gender}} | {{student.demographics.submittingStateAbbv}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--MOBILE AND TABLET END-->
