<div class="worklistHistorySearchPartial">
  <div flex class="background-grey1 dashboard-module no-padding no-margins">
    <div layout-padding layout-align="center center" class="layout-flex-row dashboard-module-title-background">
      <h3 hide-xs class="flex dashboard-module-title-text-2" style="padding: 9px">{{title}}</h3>
    </div>

    <form name="worklist-history-search-form" class="dashboard-module-body layout-padding">
      <div class="custom-input-container">
        <!-- Instructions Row -->
        <div layout-align="center center" flex class="layout-flex-row instructionsRow">
          <p class="flex-80 search-form-instructions">
            At least one of the search parameters is required in order to return valid results. You can only search for
            worklists assigned to, or originating from, users in your state.
          </p>
          <div class="flex-20 align-right">
            <button class="clear-button" (click)="clearSearchFields()" [disabled]="!worklistQuery" type="button"
              tabindex="0" aria-label="Clear">
              <i class="fa fa-times" aria-hidden="true"></i>
              <span>Clear</span>
            </button>
          </div>
        </div>

        <!-- Form Fields -->
        <div class="layout-flex-row" flex>
          <!-- Left Column -->
          <div class="flex-50 layout-column">
            <custom-mat-input class="flex" customlabel="Student First Name" [(ngModel)]="worklistQuery.studentFirstName"
              name="searchFName" [userform]="false" (ngsubmit)="searchWorklistHistory()">
            </custom-mat-input>
            <custom-mat-input class="flex" customlabel="Student Last Name" [(ngModel)]="worklistQuery.studentLastName"
              name="searchFName" [userform]="false" (ngsubmit)="searchWorklistHistory()">
            </custom-mat-input>
            <custom-mat-input class="flex" customlabel="User's First Name" [(ngModel)]="worklistQuery.userFirstName"
              name="searchFName" [userform]="false" (ngsubmit)="searchWorklistHistory()">
            </custom-mat-input>
            <custom-mat-input class="flex" customlabel="User's Last Name" [(ngModel)]="worklistQuery.userLastName"
              name="searchFName" [userform]="false" (ngsubmit)="searchWorklistHistory()">
            </custom-mat-input>

            <!-- Created Between Dates -->
            <div layout-align="start center" class="datesRow datesHeader mat-input-container">
              <p>Worklist created between</p>
            </div>
            <div class="flex-inline datesRow">

              <custom-mat-input style="padding-right: 0px" class="flex-10" customlabel="MM" textType="number"
                [(ngModel)]="worklistQuery.createdFromDateMM" name="searchBirthMonthFrom" maxcharacter=2
                [isNumeric]="true" [userform]="false" (ngsubmit)="searchWorklistHistory()" [mask]="true">
              </custom-mat-input>
              <custom-mat-input style="margin-left: -2px;" class="flex-10" customlabel="DD" textType="number"
                [(ngModel)]="worklistQuery.createdFromDateDD" name="searchBirthDayFrom" maxcharacter=2
                [isNumeric]="true" [userform]="false" (ngsubmit)="searchWorklistHistory()" [mask]="true">
              </custom-mat-input>
              <custom-mat-input style="margin-left: -3px;" class="flex-27" customlabel="YYYY" textType="number"
                [(ngModel)]="worklistQuery.createdFromDateYYYY" name="searchBirthYearFrom" maxcharacter=4
                [isNumeric]="true" [userform]="false" (ngsubmit)="searchWorklistHistory()" [mask]="true">
              </custom-mat-input>
              <div class="flex dateDash">
                <p style="margin-left: 7px !important;">-</p>
              </div>
              <custom-mat-input style="padding-right: 0px; width: 10%;" class="flex-10" customlabel="MM"
                [(ngModel)]="worklistQuery.createdToDateMM" name="searchBirthMonth" maxcharacter=2
                [userform]="false" (ngsubmit)="searchWorklistHistory()" textType="number" [mask]="true">
              </custom-mat-input>
              <custom-mat-input style="margin-left: -2px;" customlabel="DD" class="flex-10"
                [(ngModel)]="worklistQuery.createdToDateDD" name="searchBirthDay" maxcharacter=2
                [userform]="false" (ngsubmit)="searchWorklistHistory()" textType="number" [mask]="true">
              </custom-mat-input>
              <custom-mat-input style="margin-left: -3px;" class="flex-27" customlabel="YYYY"
                [(ngModel)]="worklistQuery.createdToDateYYYY" name="searchBirthYear" maxcharacter=4
                [userform]="false" (ngsubmit)="searchWorklistHistory()" textType="number" [mask]="true">
              </custom-mat-input>
            </div>
          </div>
          <!-- Right Column -->
          <div class="layout-flex-column flex-50">
            <custom-mat-input customlabel="Student Student ID" [(ngModel)]="worklistQuery.stateStudentId"
              name="searchFName" [userform]="false" (ngsubmit)="searchWorklistHistory()" class="">
            </custom-mat-input>
            <mat-form-field class="placeholder-label md-block status" flex-gt-xs>
              <mat-label class="custom-label select-label">Current Worklist Status</mat-label>
              <mat-select class="md-select-input-height" [ngModelOptions]="{standalone: true}"
                [(ngModel)]="worklistQuery.worklistStatus" aria-label="State">
                <mat-option value="initiated" *ngIf="worklistHistorySearchType == 'corWorklistHistory'">Initiated
                </mat-option>
                <mat-option value="active" *ngIf="worklistHistorySearchType == 'corWorklistHistory'">Active</mat-option>
                <mat-option value="resolved">Resolved</mat-option>
                <mat-option value="unresolved" *ngIf="worklistHistorySearchType == 'mergeAndSplitWorklistHistory'">
                  Unresolved</mat-option>
                <mat-option value="canceled">Canceled</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="worklistHistorySearchType == 'mergeAndSplitWorklistHistory'"
              class="placeholder-label md-block status" flex-gt-xs style="border: 1px solid gray; margin-top: 9px">
              <mat-label class="custom-label select-label">Worklist Type
              </mat-label>
              <mat-select class="md-select-input-height" [ngModelOptions]="{standalone: true}"
                [(ngModel)]="worklistQuery.worklistType" aria-label="State">
                <mat-option value="NEAR MATCH">Near Match</mat-option>
                <mat-option value="VALIDATE MERGE">Validate Merge</mat-option>
                <mat-option value="VALIDATE USER INITIATED MERGE">Validate User Initiated Merge</mat-option>
                <mat-option value="VALIDATE SPLIT">Validate Split</mat-option>
              </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="worklistHistorySearchType == 'corWorklistHistory'"
              class="placeholder-label md-block status" style="margin-top: 9px;">
              <mat-label class="custom-label select-label">Initiating State</mat-label>
              <mat-select [ngModelOptions]="{standalone: true}" [(ngModel)]="worklistQuery.initiatingState">
                <mat-option *ngFor="let state of states; let i = index" [value]="i + 1">
                  {{state.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <custom-mat-input customlabel="Worklist ID" [(ngModel)]="worklistQuery.worklistId" name="searchFName"
              [userform]="false" (ngsubmit)="searchWorklistHistory()">
            </custom-mat-input>

            <!-- Resolved Between Dates -->
            <div layout-align="start center" class="datesRow datesHeader">
              <p>Worklist resolved between</p>
            </div>
            <div class="flex-inline datesRow">
              <custom-mat-input style="padding-right: 0px; width: 10%;" customlabel="MM" class="flex-10"
                [(ngModel)]="worklistQuery.resolvedFromDateMM" name="searchBirthMonthFrom" maxcharacter=2
                [isNumeric]="true" [userform]="false" (ngsubmit)="searchWorklistHistory()" textType="number" [mask]="true">
              </custom-mat-input>
              <custom-mat-input style="margin-left: -2px;" customlabel="DD" class="flex-10"
                [(ngModel)]="worklistQuery.resolvedFromDateDD" name="searchBirthDayFrom" maxcharacter=2
                [isNumeric]="true" [userform]="false" (ngsubmit)="searchWorklistHistory()" textType="number" [mask]="true">
              </custom-mat-input>
              <custom-mat-input style="margin-left: -3px;" customlabel="YYYY" class="flex-27"
                [(ngModel)]="worklistQuery.resolvedFromDateYYYY" name="searchBirthYearFrom" maxcharacter=4
                [isNumeric]="true" [userform]="false" (ngsubmit)="searchWorklistHistory()" textType="number" [mask]="true">
              </custom-mat-input>
              <div flex class="dateDash">
                <p>-</p>
              </div>
              <custom-mat-input style="padding-right: 0px;" class="flex-10" customlabel="MM"
                [(ngModel)]="worklistQuery.resolvedToDateMM" name="searchBirthMonth" maxcharacter=2 [mask]="true"
                [userform]="false" (ngsubmit)="searchWorklistHistory()" textType="number">
              </custom-mat-input>
              <custom-mat-input style="margin-left: -2px;" customlabel="DD" class="flex-10" [(ngModel)]="worklistQuery.resolvedToDateDD"
                name="searchBirthDay" maxcharacter=2 [mask]="true" [userform]="false" textType="number"
                (ngsubmit)="searchWorklistHistory()">
              </custom-mat-input>
              <custom-mat-input style="margin-left: -3px;" customlabel="YYYY" class="flex-27" textType="number"
                [(ngModel)]="worklistQuery.resolvedToDateYYYY" name="searchBirthYear" maxcharacter=4 [mask]="true"
                [userform]="false" (ngsubmit)="searchWorklistHistory()">
              </custom-mat-input>
            </div>
          </div>
        </div>
        <div class="layout-flex-row error-message" style="margin-left: 10px;">
          <div class="layout-flex-column" flex>
            <p *ngIf="showCreateErrMsg">The Worklist created end date must be after the start date</p>
          </div>
          <div class="layout-flex-column" flex>
            <p style="margin-left: 10px !important;" *ngIf="showResolveErrorMessage">The Worklist resolved end date must
              be after the start date</p>
          </div>
        </div>
        <!-- Search button row -->
        <div layout-align="end start" flex class="layout-flex-row buttonRow layout-align-end-start">
          <button
            [ngClass]="{'no-top-bottom-right-margin-padding': responsiveUI.isMobile, 'no-top-bottom-margin-padding': !responsiveUI.isMobile}"
            class="md-button" type="Submit" (click)="searchWorklistHistory()" [disabled]="searchValidationLogic()">
            Search
          </button>
        </div>
      </div>
    </form>
  </div>
</div>