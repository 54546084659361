<div class="saved-list-modals">
  <div class="confirmDeactivateDialog _md md-transition-in" 
    tabindex="-1">
    <div class="md-toolbar-tools toolbar">
      <h2>Accept/Delete List</h2>
        <span class="flex"></span>
        <button class="md-icon-button md-button" type="button" (click)="onClose()" (keypress)="onClose()" style="margin-left: auto; margin-right: 0;">
          <i class="fa fa-times ng-scope"></i>
        </button>
    </div>

    <!-- Window copy and input box for new Savelist name-->
    <div class="md-dialog-content">
      <div layout="row" class="layout-row newListCopy">
        <p>Edit the name of this list by entering a new list name in the “List Name” field. Delete this list by
          selecting “Delete List”. Any action on this list will only affect this list.</p>
      </div>
      <div layout="row" class="center layout-row">
        <div class="custom-input-container" [ngClass]="{'error': acceptDeleteSharedListObj.shareListName.$invalid}">
          <custom-mat-input 
            [isdisabled]="deleteShareListCheckbox" 
            ngMaxlength="60"
            customlabel="List Name" 
            [(ngModel)]="acceptDeleteSharedListObj.shareListName" 
            name="shareListName"
            (ngsubmit)="acceptDeleteSharedList()">
          </custom-mat-input>
        </div>
      </div>
      <div class="edit-delete-error-message">
        <p *ngIf="acceptDeleteSharedListObj.shareListName.length > 60">List names must be 60 characters or less.</p>
        <p *ngIf="!deleteShareListCheckbox && checkShareListName()">List name already exists. Enter a
          different name.</p>
      </div>
      <div layout="row" class="layout-row deleteList">
        <mat-checkbox (click)="toggleShareDelete()" (keypress)="toggleShareDelete()" tabindex="0" type="checkbox">
          <p class="filtertext">Delete List</p>
        </mat-checkbox>
      </div>
      <p class="deleteListText" *ngIf="deleteShareListCheckbox">
        Selecting "Delete List" will permanently delete this list.
        This action cannot be undone.
      </p>
    </div>

    <div class="layout-row filter-row">
      <hr class="thinHr">
      <a tabindex="0" (click)="onClose()" (keypress)="onClose()">
        <span>Cancel</span>
      </a>
      <span class="flex"></span>
      <button class="md-button right-align" type="submit" (click)="acceptDeleteSharedList()"
        [disabled]="!deleteShareListCheckbox && (!acceptDeleteSharedListObj.shareListName || acceptDeleteSharedListObj.shareListNameExists || acceptDeleteSharedListObj.shareListName.length > 60)">
        Submit
      </button>
    </div>
  </div>
</div>