import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {MatDividerModule} from '@angular/material/divider';
import { NgDynamicBreadcrumbModule } from 'ng-dynamic-breadcrumb';
import { ExportReportComponent } from './components/exportReport/export-report.component';
import { ReportsTableComponent } from './components/reportsTable/reportsTable.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import {MatTabsModule} from '@angular/material/tabs';
import { LayoutModule } from '@angular/cdk/layout';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomMatInputComponent } from './components/customMatInput/customMatInput.component';
import { CustomMatInputForFilterComponent } from './components/customMatInputForFilter/customMatInputForFilter.component';
import { InformationDialogComponent } from './components/information-dialog/information-dialog.component';
import { CustomStatesControlComponent } from './components/custom-states-control/custom-states-control.component';
import { IssueDropdownComponent } from './components/issueDropdown/issueDropdown.component';
import { NoResultsComponent } from './components/noResults/noResults.component';
import { ReportsRowComponent } from './components/reportsRow/reportsRow.component';
import { StudentSearchComponent } from './components/student-search/student-search.component';
import { FilterButtonComponent } from './components/filter-button/filter-button.component';
import { AppHeaderComponent } from './layout/header/header.component';
import { AppFooterComponent } from './layout/footer/footer.component'
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatChipsModule} from '@angular/material/chips';
import {MatIconModule} from '@angular/material/icon';
import {MatCardModule} from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { CustomGradeLevelControlComponent } from './components/custom-grade-level-control/custom-grade-level-control.component';
import { CustomAssessmentsTypeControlComponent } from './components/custom-assessments-type-control/custom-assessments-type-control.component';
import { CustomAssessmentsInterpretationControlComponent } from './components/custom-assessments-interpretation-control/custom-assessments-interpretation-control.component';
import { CustomCourseTypeControlComponent } from './components/custom-course-type-control/custom-course-type-control.component';
import { CustomDrillDownLevelControlComponent } from './components/custom-drill-down-level-control/custom-drill-down-level-control.component';
import { ThousandsSeparatorPipe } from './pipes/thousands-separator';
import { FocusNextElementDirectiveModule } from './directive/focus-next-element.module';
import { CustomTooltipModule } from './directive/custom-tooltip/custom-tooltip.module';
import { LoaderModule } from './layout/loader/loader.module';
import {MatExpansionModule} from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';


@NgModule({
  declarations: [
    ExportReportComponent,
    ReportsTableComponent, 
    CustomMatInputComponent,
    CustomMatInputForFilterComponent,
    InformationDialogComponent,
    CustomStatesControlComponent,
    CustomGradeLevelControlComponent,
    CustomAssessmentsTypeControlComponent,
    CustomAssessmentsInterpretationControlComponent,
    CustomCourseTypeControlComponent,
    CustomDrillDownLevelControlComponent,
    FilterButtonComponent,
    StudentSearchComponent,
    ReportsRowComponent,
    NoResultsComponent,
    IssueDropdownComponent,
    AppHeaderComponent,
    AppFooterComponent,
    ThousandsSeparatorPipe,
  ],
  imports: [
    CommonModule,
    MatDividerModule,
    NgDynamicBreadcrumbModule,
    MatButtonModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatMenuModule,
    MatSelectModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatDialogModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
    RouterModule,
    MatTabsModule,
    FocusNextElementDirectiveModule,
    CustomTooltipModule,
    LoaderModule,
    MatCardModule,
    MatExpansionModule,
    MatTooltipModule
  ],
  exports: [
    ExportReportComponent,
    ReportsTableComponent,
    CommonModule,
    MatDividerModule,
    NgDynamicBreadcrumbModule,
    MatButtonModule,
    MatInputModule,
    MatRadioModule,
    MatCheckboxModule,
    MatMenuModule,
    MatSelectModule,
    MatToolbarModule,
    MatFormFieldModule,
    MatProgressSpinnerModule,
    MatSlideToggleModule,
    MatDialogModule,
    LayoutModule,
    FormsModule,
    ReactiveFormsModule,
    CustomMatInputComponent,
    CustomMatInputForFilterComponent,
    InformationDialogComponent,
    CustomStatesControlComponent,
    CustomGradeLevelControlComponent,
    CustomAssessmentsTypeControlComponent,
    CustomAssessmentsInterpretationControlComponent,
    CustomCourseTypeControlComponent,
    CustomDrillDownLevelControlComponent,
    FilterButtonComponent,
    StudentSearchComponent,
    ReportsRowComponent,
    NoResultsComponent,
    IssueDropdownComponent,
    AppHeaderComponent,
    AppFooterComponent,
    ThousandsSeparatorPipe,
    MatAutocompleteModule,
    MatChipsModule,
    MatIconModule,
    RouterModule,
    MatTabsModule,
    FocusNextElementDirectiveModule,
    CustomTooltipModule,
    LoaderModule,
    MatCardModule,
    MatExpansionModule,
    MatTooltipModule
  ]
})
export class SharedModule { }
