import { Component, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { AuthService } from 'src/app/services/auth.service';
import { LoginService } from 'src/app/services/login.service';
import { User, UserService } from 'src/app/services/user.service';
import * as angular from "angular";
import { AppConstants } from '../../config/index.constants';
import { Router } from '@angular/router';
import { FormBuilder } from '@angular/forms';
import { Observable } from 'rxjs';
import { ToastService } from 'src/app/services/toast.service';
import * as moment from 'moment';
import { EncoderService } from 'src/app/services/encoder.service';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  
  public username;
  public password;
  mfaSkipAllowed: boolean;
  resetupMFA: string;
  successFlag: boolean;
  formData: {
    isMFA: boolean,
    username: string,
    showRegistrationDecision: boolean,
    isRegistrationWorkflow: boolean,
    mfaCode: string,
    password: string,
    needQRCode: boolean,
    verifyQRCode: boolean
  };
  submitUsername: string;
  secretKey: string;
  qrCode: string;
  isSubmitting: boolean;
  mfaSetupStep: string;
  errors: string[];
  invalidMFACode: boolean;
  attempts: number = 0;
  verifyUserUsingCQA: boolean;
  challengeQuestions: {key: number, question: string, answer: string}[];
  response: string[] = ['', '', ''];
  appVersion: string;
  router: string;
  userChallengeQuestion: {};
  loginForm = this.formBuilder.group({
    isMFA: false,
    username: '',
    showRegistrationDecision: true,
    isRegistrationWorkflow: false,
    mfaCode: '',
    password: '',
    needQRCode: false,
    verifyQRCode: false
  });
  userObj: {
    roles: {},
    name: string,
    username: string,
    privacyFlag: boolean,
    robFlag: boolean,
    isOTP: boolean,
    betaUserFlag: boolean,
    passwordExpirationWarn: boolean
  };
  constructor(private authService: AuthService,
    private cookieService: CookieService,
    private loginService: LoginService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private _router: Router,
    private _Toast: ToastService,
    private encoderService: EncoderService,
    public responsiveUI: ResponsiveUIService) {

    this.router = _router.url;
    this.mfaSetupStep = '';
    this.errors = [];
    this.response[0] = '';
    this.response[1] = '';
    this.response[2] = '';
    this.invalidMFACode= false;
    this.formData = {
      isMFA: false,
      username: '',
      showRegistrationDecision: true,
      isRegistrationWorkflow: false,
      mfaCode: '',
      password: '',
      needQRCode: false,
      verifyQRCode: false
    };
    this.userObj = {
      roles: {},
      name: '',
      username: '',
      privacyFlag: false,
      robFlag: false,
      isOTP: false,
      betaUserFlag: false,
      passwordExpirationWarn: false
    };
    if (this.cookieService.getObject("resetupmfa")) {
      this.formData.isRegistrationWorkflow = true;
      this.mfaSetupStep = "download";
    }
  }

  // This function is called when:
  //   1) user submitting MFA
  //   2) Skipping MFA (Only for Non-Prod as MFA is mandatory in Prod)
  finalAuthenticationLogin() {
    console.log('finalAuthentication() - isAuthenticated: ' + this.authService.isAuthenticated());

    let apiCall = this.loginService.login(this.formData);
    let subscribed = apiCall.subscribe({
      next: data => {
        let navigateTo = '';
        console.log("login.comp.ts - finalAuthenticationLogin data: ", data);

        // Store the user's info for easy lookup
        var userObj = this.getUser(data);
        
        if (this.cookieService.getObject('privacyAccept')) {
          userObj.privacyFlag = true;
        }
        this.cookieService.putObject('user', userObj);
        var encodedUsername = encodeURIComponent(this.userObj.username);
        this.cookieService.put('username', encodedUsername);
        
        window.localStorage.setItem('logged_in', 'true');

        if (data.username != "") {
          navigateTo = this.checkROB(data);
        } else {
          this.errors = ["Incorrect login"];
        }
        let rolesCall = this.userService.getUserRoles();
        let rolesSubscribed = rolesCall.subscribe(
          (data) => {
            this.cookieService.putObject('userRoles', data);
            console.log("Putting cookie object userRoles: " + angular.toJson(data));
            rolesSubscribed.unsubscribe();
          }
        );
        let statusesCall = this.userService.getUserStatuses()
        let statusesSubscribed = statusesCall.subscribe(
          (data) => {
            this.cookieService.putObject('userStatuses', data);
            console.log("Putting cookie object userStatuses: " + angular.toJson(data));
            statusesSubscribed.unsubscribe();
          }
        );

        if (navigateTo && navigateTo.length > 0) {
          this._router.navigate([navigateTo]);
        } else {
          this.goToHomePage();
        }
        
        subscribed.unsubscribe();
      },
      error: error => {
        console.log(error);
        if (error.error.message == "invalid_mfa_code") {
          this.invalidMFACode = true;
          this.attempts += 1;
          if (this.attempts < 3) {
            this.errors = ["Please enter a new code from the authenticator application."];
            } 
          else if (error.error.message == "invalid_mfa_code" && this.attempts >= 3) {
            this.errors = ["Please utilize the link to configure a new MFA Application to login to MSIX."];

          }
        }
        else {
          this.errors = ["Error occurred while verifying MFA code. Please try again."];
        }
        this.isSubmitting = false;
        subscribed.unsubscribe();
      }
    });
  };

  submitMFA(ev) {
    this.formData.isMFA = true;
    this.finalAuthenticationLogin();
  }

  // Event handler for pressing enter key that activates submission
  enter(e) {
    if(e.keyCode === 13) {
      this.submitForm('login');
    }
  }

  // This function is called when user submit their credentials for login.
  res: Observable<ArrayBuffer[]>;
  submitForm(ev): any {
    this.successFlag = false;

    this.authService.creds = this.loginForm.value;
    
    console.log('submitForm() - isAuthenticated: ' + this.authService.isAuthenticated());
    
    sessionStorage.setItem(this.loginService.USER_CREDS_SESSION_ATTRIBUTE_NAME, 
      this.encoderService.encode(this.formData.username + ':' + this.formData.password)); 

    let apiCall = this.loginService.login(this.loginForm.value);
    let subscribed = apiCall.subscribe({
      next: data => {
        this.isSubmitting = false;
        console.log('data returned: ', data);

        if (!data.otp) {
          this.formData.isMFA = true;
        } else {
          console.log('User logged in with OTP.');
          let navigateTo = this.checkROB(data);

          if (navigateTo && navigateTo.length > 0) {
            this._router.navigate([navigateTo]);
          }
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log('Login component error: ', error.error.message);
        if (error.error.message == "need_to_register_for_mfa" || error.error.message == "need_to_register_for_mfa_allow_skip") {
          // Show the temporary mfa registration decision page
          this.mfaSetupStep = "default";
          this.errors = [''];

          if (error.error.message == 'need_to_register_for_mfa_allow_skip') {
                 this.mfaSkipAllowed = true;
            }

        } else if (error.error.message === "need_mfa_authentication") {
          // If this error received, then it means that first authentication step is successful, but user is still not authenticated as 
          // the user needs to pass the 2nd factor (MultiFactor) authentication.
          this.formData.isMFA = true;
          this.errors = [''];
          
        } else {
          console.log('Login component error: ', error.error.message);
          this.isSubmitting = false;
          this.errors = ["Incorrect login."];
          sessionStorage.removeItem(this.loginService.USER_CREDS_SESSION_ATTRIBUTE_NAME);
        }
        subscribed.unsubscribe();
      }
    });
  }

  getUser(res) {
    if (res.roles) {
      var rolesArray = [];
      for (var role in res.roles) {
        rolesArray.push(res.roles[role]);
      }
      this.userObj.roles = angular.toJson(rolesArray);
    }
    this.userObj.name = res.firstName + ' ' + res.lastName;
    this.userObj.username = res.username;
    this.userObj.privacyFlag = false;
    this.userObj.robFlag = res.robFlag;
    this.userObj.isOTP = res.otp;
    this.userObj.betaUserFlag = res.betaUser;
    this.userObj.passwordExpirationWarn = res.passwordExpirationWarn;
    return this.userObj;
  }

  // This function is called when a user successfully logins using Username/Password, but does not have access to TOTP code using previously registered device(s).
  configureNewDeviceAfterUnamePwdLogin() {
    let apiCall = this.userService.getChallengeQAndAToVerifyUser(this.formData.username);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log('Users CQ&A to reconfigure MFA: ', data);
        console.log('CHALLENGE QUESTIONS: ', data.challengeQuestions);
        this.challengeQuestions = data.challengeQuestions;
        this.response[0] = '';
        this.response[1] = '';
        this.response[2] = '';
        this.verifyUserUsingCQA = true;
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error retrieving the user's CQ&A's from the login component: ", error);
        //TODO: add toast message
        // this._Toast.showToast(err.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  // This function is called when we need to verify the user account using the Challenge Questions and Answers functionality. It is triggered as 
  // part of 'Configure a new device' functionality workflow.
  submitToVerifyChallengeQAndAToVerifyUser() {
    this.challengeQuestions[0].answer = this.response[0];
    this.challengeQuestions[1].answer = this.response[1];
    this.challengeQuestions[2].answer = this.response[2];

    this.userChallengeQuestion = {
      challengeQuestions: this.challengeQuestions
    };

    let apiCall = this.userService.verifyChallengequestionsForLoggedInUser(this.formData.username, this.userChallengeQuestion);
    let subscribed = apiCall.subscribe({
      next: data => {
        if (data == true || data == "true"){
          this.verifyUserUsingCQA = false;
          this.formData.isRegistrationWorkflow = true;
          this.mfaSetupStep = "confirmReset";
          this.formData.isMFA = false;
          this.errors = [''];
        } else {
          this._router.navigate(['checkEmailMsg']);
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error verifying the user's CQ&A's from the login component: ", error);
        this._Toast.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  resetPasswordApiCall() {
    let apiCall = this.userService.resetSelfPassword();
    let subscribed = apiCall.subscribe({
      // Callback for success
      next: data => {
        console.log("login.comp.ts - submitUsernameForm - data: ", data);
        this.logout('An email has been sent with your temporary password.');
      },
      // Callback for failure
      error: error => {
        console.log("Error actually resetting user's password from the login component: ", error);
        //TODO: Implement Toast
        // this._Toast.showToast(err.data.developerMessage, 0);
      }
    });
    subscribed.unsubscribe();
  }

  checkROB(data) {
    let navigateTo = '';
    let userObj: any='';
    userObj = this.cookieService.getObject('user');

    if (!userObj) {
      userObj = this.getUser(data);
    }

    userObj.privacyFlag = true;
    this.cookieService.putObject('user', userObj);
    //let apiCall = this.userService.getCurrentUser();
    let currentDate = moment();

    let robAcceptDate = data.robDate;
    let timeDiff = currentDate.diff(robAcceptDate, 'days'); 
    if (data.robFlag !== 'Yes') {
      // If user has not accepted rob, take them to rob page
      /* event.preventDefault();
      this._router.navigate(['rulesOfBehavior']); */
      navigateTo = 'rulesOfBehavior';
    } else {
      // User needs to accept ROB every year. 
      // If they have, set cookie & send to home page. 
      // If they haven't, send to ROB page
      if (timeDiff > 365) {
        /* event.preventDefault();
        this._router.navigate(['rulesOfBehavior']); */
        navigateTo = 'rulesOfBehavior';
      } else {
        this.acceptRobFlag();
        // userObj.isOtp is set to indicate if user is logging in with OTP or his/her password.
        // It will be checked in main.ts to control the user navigation to other pages
        let userObj: any = this.cookieService.getObject('user');
        userObj.isOTP = data.otp;
        userObj.needsJobTitle = !data.hasJobTitle;
        this.cookieService.putObject('user', userObj);

        if (data.otp) {
          //this._router.navigate(['setChallengeQuestions']);
          navigateTo = 'setChallengeQuestions';
        } 
        else if (!data.hasJobTitle){
          //this._router.navigate(['selectJobTitles']);
          navigateTo = 'selectJobTitles';
        } else {
          //this._router.navigate(['']);
          navigateTo = 'home';
        }
      }
    }

    return navigateTo;
  }

  acceptRobFlag() {
    let userObj: any='';
    userObj = this.cookieService.getObject('user');
    userObj.robFlag = true;
    this.cookieService.putObject('user', userObj);
  }
  
  // Called when user clicks on:
  //   - 'Next' on the MFA registration decision page (step = 'download')
  mfaSetupNext(step){
    this.formData.showRegistrationDecision = false;
    this.formData.isRegistrationWorkflow = true;

    if (step === 'done') {//step option 1
      if (this.cookieService.getObject('resetupMFA')) {
        this._router.navigate(['myaccount']);
      } else { //API call to backend
        this.authService.logout('');
      }
    } else if(step === 'reset') {//step option 2
      let apiCall = this.userService.resetAuthenticatedUserMFA(this.formData.username);
      let subscribed = apiCall.subscribe({
        // Callback for success
        next: data => {
          console.log("Resetting user's MFA - mfaSetupNext('reset'): ", data);
          this.invalidMFACode = false;
          //call this method again to hit the "register" clause and return the QR code for the registration page
          this.mfaSetupNext('register');
          subscribed.unsubscribe();
        },
        // Callback for failure
        error: error => {
          console.log("Error - mfaSetupNext('reset'): ", error);
          subscribed.unsubscribe();
        }
      });
    } else if (step === 'register') {//step option 3
      this.formData.mfaCode = '';
      this.formData.isRegistrationWorkflow = true;
      this.formData.needQRCode = true;

      if (this.cookieService.getObject('resetupMFA')) { //enter registration model
        let apiCall = this.userService.authenticatedRegisterMfa();
        let subscribed = apiCall.subscribe({
          // Callback for success
          next: data => {
            console.log('Retrieving the QR Code and secret key: ', data);
            // App should navigate to QR code page.
            this.mfaSetupStep = "register";
            this.secretKey = (data.substring(0, data.lastIndexOf('--'))).match(/.{1,4}/g).join(' ');
            this.qrCode = (data.substring(data.lastIndexOf("--") + 2));
            subscribed.unsubscribe();
          },
          // Callback for failure
          error: error => {
            console.log("Error re-registering user's MFA from the login component: ", error);
            subscribed.unsubscribe();
          }
        });
      } else {
        console.log('mfaSetupNext(' + step + ') - isAuthenticated: ' + this.authService.isAuthenticated());
    
        this.loginService.login(this.formData).subscribe({
          next: data => {
            console.log('data: ', data);
          },
          error: error => {
            console.log('Expecting error mfa_registration_using_qr_code: ', error);
            if (error.error.message.includes('mfa_registration_using_qr_code')) {
              // If this error received, then it means that user is trying to register for MFA authentication and is at a step to scan the QR code.
              // Along with the error, we should have received the QR code string to show the QR code in the app.

              // App should navigate to QR code page.
              this.mfaSetupStep = "register";
              let secret_qr_string = error.error.message.substring(error.error.message.lastIndexOf("_") + 1); // Exmple of substring: 'secret123--qrcodexyz'
              this.secretKey = (secret_qr_string.substring(0, secret_qr_string.lastIndexOf('--'))).match(/.{1,4}/g).join(' ');
              this.qrCode = (secret_qr_string.substring(secret_qr_string.lastIndexOf("--") + 2));

            } else {
              console.log(error.error.message);
              this.isSubmitting = false;
              this.errors = ["Incorrect login."];
            }
          }
        });
      }
    } else if (step === 'confirmRegistration') {
      this.formData.isRegistrationWorkflow = true;
      this.formData.needQRCode = false;
      this.formData.verifyQRCode = true;

      if (this.cookieService.getObject('resetupMFA')){
        let apiCall = this.userService.authenticatedVerifyMfa(this.formData.mfaCode);
        let subscribed = apiCall.subscribe({
          // Callback for success
          next: data => {
            console.log('data: ', data);
            if (data == "true"){
              this.mfaSetupStep = "success";
            } else {
              this.invalidMFACode = true;
            }
          },
          // Callback for failure
          error: error => {
            console.log("Error verifying new MFA setup code from the login component: ", error);
          }
        });
        subscribed.unsubscribe();
      } else {
        console.log('mfaSetupNext(' + step + ') - isAuthenticated: ' + this.authService.isAuthenticated());
    
        // Backend should throw one of the followin error at this point because user is not completely authenticated:
        //    'mfa_registration_valid_code'
        //    'mfa_registration_invalid_code'
        this.loginService.login(this.formData).subscribe({
          next: data => {
            // Callback for success
            console.log('data: ', data);
          },
          error: error => {
            console.log(error);
            if (error.error.message.includes('mfa_registration_valid_code')) {
              // If this error received, then it means that user is trying to register for MFA authentication and is at a step to verify the registration.
              // User entered valid code.
              // App should navigate to Verify code page.
              this.mfaSetupStep = "success";
              
            } else if (error.error.message.includes('mfa_registration_invalid_code')) {
              // If this error received, then it means that user is trying to register for MFA authentication and is at a step to verify the registration.
              // User entered code is not valid
              this.invalidMFACode = true;
              this.attempts -= 1;
              if(this.attempts === 0) {
                this.errors = ["Please utilize the link to configure a new MFA Application to login to MSIX."];
              }
              else {
                this.errors = ["Please enter a new code from the authenticator application."];
              }

            } else {
              console.log(error.error.message);
              this.isSubmitting = false;
              this.errors = ["Incorrect login."];
            }
          }
        });
      }
    } else {
      this.mfaSetupStep = step;
    }
  }

  // Called when user decides :
  //   - to skip the MFA registration (step = 'skip')
  //   - to go back from the MFA registration download page (step = 'default')
  mfaSetupSkipOrBack(step){
    if (step === 'skip'){
      //        Only allowed in Non-Prod environments.
      this.formData.showRegistrationDecision = false;
      this.formData.isRegistrationWorkflow = false;
      //Go to home page
      //this.goToHomePage();
      console.log('skipping MFA setup. Calling finalAuthenticationLogin()');
      this.finalAuthenticationLogin();
    } else if (step === 'default') {
      this.formData.showRegistrationDecision = true;
      this.mfaSetupStep = step;
    } else if (step === 'register') { 
      //Reset MFA Code data
      this.errors = [''];
      this.invalidMFACode = false;
      this.formData.mfaCode = '';
      this.mfaSetupStep = step;
    } else if (step === 'mfaEntry') {
       this.formData.isMFA = true;
       this.formData.isRegistrationWorkflow = false;
       this.verifyUserUsingCQA = false;
    } else {
      this.mfaSetupStep = step;
    }
  }

  goBackToUsernameLogin() {
    this.formData.isMFA = false;
    this.formData.username = "";
    this.formData.password = "";
    this.errors = [''];
  }

  goToStateContactSearch() {
    this._router.navigate(['pwReset'], {
      queryParams: {
        header: 'State Contact Search'
      }
    });
  }

  goToLogin() {
    this._router.navigate(['login']);
  }

  // TODO - Is this being called?
  acceptWarn() {
    this.cookieService.putObject('privacyAccept', {
      accept: true
    });
    this._router.navigate(['login']);
  }

  goToHomePage() {
    this._router.navigate(['']);
  }

  goToPrivacy() {
    this._router.navigate(['privacy']);
  }

  logout(msg) {
    this.cookieService.removeAll();
    this.authService.logout(msg);
  }

  goToForgotYourPassword() {
    this._router.navigate(['forgotYourPassword']);
  }

  goToCheckEmailMsg() {
    this._router.navigate(['checkEmailMsg']);
  }

  init() {
    if (this.cookieService.getObject('resetupMFA')) {
      this.formData.isRegistrationWorkflow = true;
      this.mfaSetupStep = "download";
    }
    if(this.router === '/logout'){
      this.authService.logout('');
    }
    if (this.authService.getTimeoutFlag() === true) {
      this.errors = ['You were logged out due to inactivity.'];
      this.authService.setTimeoutFlag(false);
    }
    if (this.userService.getPasswordResetFlag() === true) { 
      this.successFlag = true;
      this.userService.setPasswordResetFlag(false);
      this.formData.username = decodeURIComponent(this.cookieService.get('username'));
    }
    if (this.authService.isAuthenticated() && (this.router === '/login')
        && !this.cookieService.getObject('resetupMFA')) {
      this.authService.logout('');
    } 
    if (!this.authService.isAuthenticated() && !this.cookieService.getObject('privacyAccept')) {
      this._router.navigate(['privacy']);
    }
    this.appVersion = AppConstants.appVersion;
  }
 }

@Component({
  selector: 'forgotYourPassword',
  templateUrl: './forgotYourPassword.component.html',
  styleUrls: ['./login.component.scss']
})
export class ForgotYourPasswordComponent implements OnInit {

  appVersion: string;
  submitUsername: string;
  errors: string[];

  constructor(private userService: UserService,
    private cookieService: CookieService,
    private _router: Router,
    public responsiveUI: ResponsiveUIService,
    private _Toast: ToastService) {
    
    this.appVersion = AppConstants.appVersion;
    this.errors = [];
  }

  ngOnInit() {
    
  }

  // This function is called when User submits 'Forgot Your Password' request with their username.
  submitUsernameForm() {
    
    let apiCall = this.userService.selfServicePasswordReset(this.submitUsername);
    let subscribed = apiCall.subscribe({
      // Callback for success
      next: data => {
        console.log("login.comp.ts - submitUsernameForm - data: ", data);
        var cQList = data.challengeQuestions;
        var userName = this.submitUsername;
        var encodedUsername = encodeURIComponent(userName);
        this.cookieService.putObject('challengeQuestionsList', cQList);
        this.cookieService.put('isChallengeQuestionSet', data.isChallengeQuestionSet);
        this.cookieService.put('username', encodedUsername);
        this._router.navigate(['answerChallengeQuestions']);
        subscribed.unsubscribe();
      },
      // Callback for failure
      error: error => {
        console.log("Error submitting username for password reset from the login component: ", error);
        this._Toast.showToast(error.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
    
  }
}

@Component({
  selector: 'checkEmailMsg',
  templateUrl: './checkEmailMsg.component.html',
  styleUrls: ['./login.component.scss']
})
export class CheckEmailMsgComponent implements OnInit {

  appVersion: string;
  
  constructor(public responsiveUI: ResponsiveUIService) {
    this.appVersion = AppConstants.appVersion;
  }

  ngOnInit(): void {
    
  }
}

@Component({
  selector: 'rulesOfBehavior',
  templateUrl: './rulesOfBehavior.component.html',
  styleUrls: ['./login.component.scss']
})
export class RulesOfBehaviorComponent implements OnInit {

  constructor(private cookieService: CookieService,
    private _router: Router,
    private userService: UserService,
    private authService: AuthService) {
  }

  ngOnInit(): void {
  }

  acceptRob() {
    this.acceptRobFlag();
    let apiCall = this.userService.acceptROB();
    let subscribed = apiCall.subscribe(
      data => {
        // Set current user information in the User service.
        this.userService.setCurrentUser(data);

        if (data.otp){
          this._router.navigate(['setChallengeQuestions']);
        } else {
          this._router.navigate(['/home']);
        }

        subscribed.unsubscribe();
      },
      error => {
        console.error('Error occrred in acceptRob() when calling userService.acceptROB API call: ' + error);

        subscribed.unsubscribe();
      }
    );
  }

  acceptRobFlag() {
    let userObj: any='';
    userObj = this.cookieService.getObject('user');
    userObj.robFlag = true;
    this.cookieService.putObject('user', userObj);
  }

  logout() {
    this.cookieService.removeAll();
    this.authService.logout('');
  }
}