import { Component, Inject } from '@angular/core';
import { CookieService } from 'ngx-cookie';
import { ReportsService } from 'src/app/services/reports.service';
import { ReportInfoService } from 'src/app/services/reportinfo.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import * as angular from "angular";
import { AppConstants } from 'src/app/config/index.constants';
import {MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule, MatDialogConfig } from '@angular/material/dialog';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'accountManagement',
  templateUrl: './accountManagement.component.html',
  styleUrls: ['./accountManagement.component.scss']
})
export class AccountManagementComponent {
  id: string = "accountManagement";
  chart: string = "accountManagementChart";
  exportCall: string = 'accountManagementExport';
  private appConst = AppConstants;
  private userStateName: string;
  private accountManagementFilterParams: any = {};
  private activeAccountManagementFilter: any = {};
  private accountManagementData: any;
  private drillDown: any;
  showDrill: boolean;
  private ua: string;
  private activeTab: string;
  private reportGroupVar: string;
  private reportDrillDownVar: string;
  private blankRes: boolean;
  private tabChangeDone: boolean;
  private drillDownData: any = {};
  drillDownInfo: any = {};
  private roleClasses: any[];
  private authRoleClasses: any;
  private dateObj: any;
  private accountManagementDataHasLoaded: boolean;
  private hideAccountManagement: boolean;
  private dataHasLoaded: boolean = false;
  private lastUpdated: any;
  private states: any[] = [];
  showFilter: boolean;
  sortAscending: boolean = true;
  currentSortColumn: string = 'column1';
  constructor(
    public dialog: MatDialog,
    private cookieService: CookieService,
    private reportsService: ReportsService,
    private reportInfoService: ReportInfoService,
    private authService: AuthService,
    private toastService: ToastService,
    public responsiveUI: ResponsiveUIService) {
      this.showDrill = false;
      this.ua = navigator.userAgent;
      this.reportGroupVar = 'studentInfo';
      this.reportDrillDownVar = 'accountManagementDrilldown';
      this.activeTab = 'totalLogins';
      this.blankRes = true;
      this.tabChangeDone = false;
      this.accountManagementDataHasLoaded = false;
      this.hideAccountManagement = false;
      this.lastUpdated = new Date();
      this.showFilter = true;
      this.authRoleClasses = {};
      this.drillDown = {
        beginRow: 1,
        endRow: 20,
        sortField: "MONTH_DESC",
        stateName: this.accountManagementFilterParams.selectedState,
        performancePeriod: 'CURRENT',
        chartType: 'accountManagement',
        tab: this.activeTab
      };
      this.roleClasses = [
        'dataDashboard_studentAchievement_with_sua',
        'dataDashboards',
        'acctMngt_sua'
      ];
  }

  ngOnInit() {
    this.getReportInfo();
  }

  // Toggle boolean to stop showing the drilldown table and 'return' to the main chart
  goToReport() {
    this.showDrill = false;
  }

  //Gets info needed to display the filter: list of states, user's state, both performance periods, and the authorized role classes
  async getReportInfo() {
    let reportInfo = await this.reportInfoService.getReportInfo(this.roleClasses);
    console.log("Reportinfo: ", reportInfo);
 
    this.userStateName = reportInfo.userStateName;
    if (reportInfo.authRoleClasses.acctMngt_sua) {
      this.accountManagementFilterParams = {
        rptGrping: 'State',
        selectedState: this.userStateName,
        states: []
      };
    } else {
      this.accountManagementFilterParams = {
        rptGrping: 'National',
        selectedState: "",
        states: []
      };
    } 
    await this.setStatesArray(reportInfo.stateData);

    this.authRoleClasses = reportInfo.authRoleClasses;
    
    console.log("Conditions for export/view details: ", this.accountManagementFilterParams);
    this.activeAccountManagementFilter = cloneDeep(this.accountManagementFilterParams);
    //Generate the report automatically if in mobile mode. Otherwise open the filter dialog
    if (this.responsiveUI.isMobile || this.authRoleClasses.acctMngt_sua) {
      this.submitFilter();
    } else {
      this.filterActivate();
    }
  }

  // Trigger Filter Modal
  filterActivate() {
    this.showFilter = true;
    const dialogRef = this.dialog.open(FilterAccountManagement, {
      height: 'auto',
      width: '50%',
      minHeight: '250px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: {
        params: this.accountManagementFilterParams
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null && result != '') {
        this.activeAccountManagementFilter = result;
        this.accountManagementFilterParams = result;
        this.submitFilter();
        this.showFilter = false;
      }
    });
  }

  // Retrieve the report with the selected parameters
  submitFilter() {
    this.accountManagementDataHasLoaded = false;
    this.hideAccountManagement = false;
    let apiCall = this.reportsService.getAccountManagementFilter(this.accountManagementFilterParams.selectedState)
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("this.activeAccountManagementFilter: ", this.activeAccountManagementFilter);
        this.accountManagementFilterParams = cloneDeep(this.activeAccountManagementFilter);

        this.accountManagementData = data.accountManage;
        console.log("AccountManagement.submitFilter(): ", this.accountManagementData);
        this.dataHasLoaded = true;
        this.accountManagementDataHasLoaded = true;
        this.setLastUpdated();

        if (this.accountManagementData.data.length == 0) {
          this.hideAccountManagement = true;
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - AccountManagement.submitFilter(): ", error);
        this.dataHasLoaded = true;
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  setLastUpdated() {
    this.reportsService.getFirstDayOfMonth().subscribe({
      next: data => {
        this.lastUpdated = data.date;
      },
      error: error => {
        console.log("Error - AccountManagement.setLastUpdated(): ", error);
      }
    });
  }

  // removes DC, PR, and WY from states array and sets the fullStateArray with list of states
  setStatesArray(responseData) {
    for (var i in responseData) {
      // add every state to the state array except for DC (stateKey: "9"), PR (stateKey: "40"), and WY (stateKey: "52")
      if (!(responseData[i].stateKey == "9" || responseData[i].stateKey == "40" || responseData[i].stateKey == "52")) {
        let obj = {
          key: responseData[i
          ].key
        };
        //this.states.push(responseData[i]);
        this.accountManagementFilterParams.states.push(responseData[i]);
      }
    }
  }

  //Open pop up window with more information on the dashboard
  goToLearnMore() {
    this.showFilter = true;
    const dialogRef = this.dialog.open(LearnMoreAccountManagement, {
      height: 'auto',
      width: '50%',
      minHeight: '325px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: {params: this.authRoleClasses},
    });
    dialogRef.afterClosed().subscribe(result => {
      this.showFilter = false;
    });
  }

  goToDrillDownLearnMore() {
    this.showFilter = true;
    const dialogRef = this.dialog.open(LearnMoreDDAccountManagement, {
      height: 'auto',
      width: '50%',
      minHeight: '250px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false, 
      data: {},
    });
    dialogRef.afterClosed().subscribe(result => {
      this.showFilter = false;
    });
  }

  showDrilldown() {
    this.showDrill = true;
    this.drillDownInit();
  }

  //Pull in Drilldown data
  drillDownInit() {
    this.drillDown = {
      beginRow: 1,
      endRow: 20,
      sortField: "MONTH_DESC",
      stateName: this.accountManagementFilterParams.selectedState,
      performancePeriod: 'CURRENT',
      chartType: 'accountManagement',
      tab: this.activeTab
    };
    this.updateDrilldown(this.drillDown);
  }

  updateDrilldown(drillDown) {
    //this.blankRes = true; // This will remove the reports-table from the DOM and prevent the persistence of sorting across tabs (MSIX-4936)
    this.tabChangeDone = false;
    this.drillDownData = {};
    this.reportGroupVar = 'dashboard';
    this.reportDrillDownVar = 'accountManagementDrilldown';
    let apiCall = this.reportsService.drillDownView(drillDown, this.reportGroupVar, this.reportDrillDownVar);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log('Account Management.updateDrilldown(): ', data);
        if (data.rows.length === 0) {
          this.blankRes = true;
          this.tabChangeDone = true;
        } else {
          this.blankRes = false;
          this.tabChangeDone = true;
          this.drillDownData = data;
          this.drillDownData.query = drillDown;
          this.drillDownInfo = {
            drillDown: drillDown, 
            reportGroupVar: this.reportGroupVar,
            reportDrillDownVar: this.reportDrillDownVar
          }
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log('Error - Account Management.updateDrilldown(): ', error);
        subscribed.unsubscribe();
      }
    });
  }

  //Sort Drill Down Data
  handleDrillDownSortClick(newSortKey) {
    this.drillDown.sortField = newSortKey.sortKey;
    this.updateDrilldown(this.drillDown);
  }

  //Updates which column and direction the table will be sorted by
  sortByColumn(data: {column: string, ascending: boolean}) {
    this.currentSortColumn = data.column;
    this.sortAscending = data.ascending;
  }

  //Change which tab the user is viewing
  changeTab(tab) {
    this.activeTab = tab;
    this.drillDownInit();
  }

  //stringifies an object
  jsonStringify(obj) {
    return angular.toJson(obj);
  }
}

// Separate component for the AccountManagement report's filter
export interface FilterParameters {
  params: {
    rptGrping: string; 
    selectedState: string;
    states: any[];
  }
}
@Component({
  selector: 'filterAccountManagement',
  templateUrl: './filterAccountManagement.html',
  styleUrls: ['./accountManagement.component.scss']
})
export class FilterAccountManagement {
  accountManagementFilterParams: { 
    rptGrping: string, 
    selectedState: string,
    states: any[]
  };
  constructor(
    public dialogRef: MatDialogRef<FilterAccountManagement>,
    public responsiveUI: ResponsiveUIService,
    @Inject(MAT_DIALOG_DATA) public data: FilterParameters) {
      this.accountManagementFilterParams = cloneDeep(data.params);
      if (this.accountManagementFilterParams.rptGrping == '' || !this.accountManagementFilterParams.rptGrping){
        this.resetFilter();
      }
  }

  resetFilter() {
    this.accountManagementFilterParams = {
      rptGrping: 'National',
      selectedState: "",
      states: this.data.params.states
    }
  }

  onSubmit(){
    this.dialogRef.close(this.accountManagementFilterParams);
  }

  selectState() {
    this.accountManagementFilterParams.rptGrping = 'State';
  }

  selectNational() {
    this.accountManagementFilterParams.rptGrping = 'National';
    this.accountManagementFilterParams.selectedState = '';
  }
  selectDrilldownState(state) {
    this.accountManagementFilterParams.selectedState = state;
  }

  onClose() {
    //this.accountManagementFilterParams = JSON.parse(JSON.stringify(this.data.params));
    this.accountManagementFilterParams = cloneDeep(this.data.params);
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.accountManagementFilterParams = cloneDeep(this.data.params);
    this.dialogRef.close(this.accountManagementFilterParams);
  }
}

// Separate component for the AccountManagement report's Learn More dialog box
export interface LearnMoreParameters {
  params: {
    dataDashboards: boolean, 
    acctMngt_sua: boolean
  }
}
@Component({
  selector: 'learnMoreAccountManagement',
  templateUrl: 'learnMoreAccountManagement.html',
  styleUrls: ['./accountManagement.component.scss']
})
export class LearnMoreAccountManagement {
  authRoleClasses: any = {};
  constructor(
    public dialogRef: MatDialogRef<LearnMoreAccountManagement>,
    @Inject(MAT_DIALOG_DATA) public data: LearnMoreParameters
  ) {
    this.authRoleClasses = data.params;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}

// Separate component for the AccountManagement report's drilldown Learn More dialog box
@Component({
  selector: 'learnMoreDDAccountManagement',
  templateUrl: 'learnMoreDDAccountManagement.html',
  styleUrls: ['./accountManagement.component.scss']
})
export class LearnMoreDDAccountManagement {
  constructor(
    public dialogRef: MatDialogRef<LearnMoreDDAccountManagement>
  ) {}

  closeDialog(): void {
    this.dialogRef.close();
  }
}
