import { Component, OnDestroy , OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StudentDetailsSummerService } from './child-details.service';
import { Observable, tap } from 'rxjs';
import { IChildDetails, IStudent } from './types';

@Component({
    selector: 'app-child-details',
    templateUrl: './child-details.component.html',
    styleUrls: ['./child-details.component.scss']

})
export class ChildDetailsComponent implements OnInit, OnDestroy {
    isExcluded:boolean;
    displayDate: string;
    msixId: string;
    idx: number;
    lastIdx: number;
    childDetails$: Observable<IChildDetails>;
    ids: string[];
    lastUpdatedDefault: Date = new Date();
    hasMissingDateLogicIssue: boolean;
    residencyEstablishingDateOccursBeforeBirthDateDataLogic: boolean;
    qadOccursAfterResidencyEstablishingDateDataLog: boolean;
    EEDOccursBeforeResidencyDataLogic: boolean;
    enrollmentTypeIs07OrMissingDataLogic: boolean;
    graduationDateOccursBeforeResidencyEstablishingDateDataLogic: boolean;
    isSummer: boolean;

// MISSING_DATE_THAT_VERIFIES_RESIDENCY_BIRTH_THROUGH_TWO_CAT_2
// RESIDENCY_ESTABLISHING_DATE_OCCUR_BEFORE_BIRTH_DATE_CAT_2
// QAD_OCCURS_AFTER_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO_CAT_2
// EED_OCCURS_BEFORE_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO_CAT_2
// GRADUATION_DATE_OCCURS_BEFORE_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO_CAT_2
// ENROLLMENT_TYPE_IS_07_OR_MISSING_BIRTH_THROUGH_TWO_CAT_2

    constructor(private route: ActivatedRoute, 
        private router: Router,
        private studentDetailsSummerService: StudentDetailsSummerService) {
        this.isExcluded = this.route.snapshot.queryParamMap.get('isExcluded') === 'true';
        this.displayDate = this.route.snapshot.queryParamMap.get('displayDate');
        this.isSummer = this.route.snapshot.queryParamMap.get('isSummer') === 'true';
        this.msixId = this.route.snapshot.queryParamMap.get('msixId');
        this.ids = this.isExcluded ? localStorage.getItem('drillDownDataExcluded') ? JSON.parse(localStorage.getItem('drillDownDataExcluded')) : null 
            : localStorage.getItem('drillDownDataIncluded') ? JSON.parse(localStorage.getItem('drillDownDataIncluded')) : null;
        this.idx = this.ids.findIndex((id) => id === this.msixId);
        
     }

    ngOnInit() { 
        this.childDetails$ = this.studentDetailsSummerService.getChildDetails(this.msixId).pipe(
            tap(res => {
                if (res.failedLogicRules.includes('MISSING_DATE_THAT_VERIFIES_RESIDENCY_BIRTH_THROUGH_TWO_CAT_1') 
                || res.failedLogicRules.includes('MISSING_DATE_THAT_VERIFIES_RESIDENCY_BIRTH_THROUGH_TWO_CAT_2')) {
                    this.hasMissingDateLogicIssue = true;
                } else if (res.failedLogicRules.includes('RESIDENCY_ESTABLISHING_DATE_OCCUR_BEFORE_BIRTH_DATE_CAT_1')
                || res.failedLogicRules.includes('RESIDENCY_ESTABLISHING_DATE_OCCUR_BEFORE_BIRTH_DATE_CAT_2')) {
                    this.residencyEstablishingDateOccursBeforeBirthDateDataLogic = true;
                } else {
                    if (res.failedLogicRules.includes('QAD_OCCURS_AFTER_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO_CAT_2')
                    || res.failedLogicRules.includes('QAD_OCCURS_AFTER_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO_CAT_1')) {
                        this.qadOccursAfterResidencyEstablishingDateDataLog = true;
                    }
                    if (res.failedLogicRules.includes('EED_OCCURS_BEFORE_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO_CAT_1')
                         || res.failedLogicRules.includes('EED_OCCURS_BEFORE_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO_CAT_2')) {
                            this.EEDOccursBeforeResidencyDataLogic = true;
                    }
                    if (res.failedLogicRules.includes('ENROLLMENT_TYPE_IS_07_OR_MISSING_BIRTH_THROUGH_TWO_CAT_2') 
                    || res.failedLogicRules.includes('ENROLLMENT_TYPE_IS_07_OR_MISSING_BIRTH_THROUGH_TWO_CAT_1')) {
                        this.enrollmentTypeIs07OrMissingDataLogic = true;
                    }  
                    if (res.failedLogicRules.includes('GRADUATION_DATE_OCCURS_BEFORE_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO_CAT_2') 
                    || res.failedLogicRules.includes('GRADUATION_DATE_OCCURS_BEFORE_RESIDENCY_ESTABLISHING_DATE_BIRTH_THROUGH_TWO_CAT_1')) {
                        this.graduationDateOccursBeforeResidencyEstablishingDateDataLogic = true;
                    }
                }
            })
        )
    }

   
    getStudentInfo(step: number) {
        if (this.idx === 0 && step === -1) {
            return;
        }
        if (this.idx === this.ids.length - 1 && step === 1) {
            return;
        }
        this.idx += step;
        this.msixId = this.ids[this.idx];
        this.childDetails$ = this.studentDetailsSummerService.getChildDetails(this.msixId)
    }

    goToStudentDetails(msixId: string) {
        const url =`#/studentDetails?msixId=${msixId}&historical=true`
       window.open(url.toString(), '_blank');
    }


    ngOnDestroy() { }
}