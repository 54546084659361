import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ReportsService } from '../../../../../services/reports.service';
import { ReportInfoService } from '../../../../../services/reportinfo.service'
import { WorklistService } from '../../../../../services/worklist.service';
import * as moment from "moment";
import { AuthService } from '../../../../../services/auth.service';
import { StateInfo } from '../reconciliationDash/reconciliationDash.component';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'reports-reconciliation-potential-duplicates',
  templateUrl: './potentialDuplicates.component.html',
  styleUrls: ['./potentialDuplicates.component.scss']
})
export class ReconPotentialDuplicatesComponent implements OnInit {

  private states: StateInfo[];
  private fullStateArray: StateInfo[];
  private selectedStatesArray: StateInfo[];
  private listOfStudentsToFlagForMerge: string[];
  isMergeListEmpty: boolean = true;
  todayDate = moment().format('MM/DD/YYYY');
  yesterdayDate = moment().subtract(1, 'days').startOf('day').format('MM/DD/YYYY');
  private roleClasses: string[];
  private filterSelectedState: number;
  response: any = {};
  private reportInfo: any;
  authRoleClasses: any = {};
  indicator: string;
  blankRes: boolean;
  exportCall = 'reconciliationExport';

  private stateParams: {
    period: string,
    rptGrping: string,
    selectedState: number
  }

  filterParameters: {
    rowStart: number,
    rowEnd: number,
    page: number,
    sort: string,
    selectedState: number,
    selectedStates: any,
    periodType: string
  };
  exportParams: { report: string; params: { rowStart: number; rowEnd: number; page: number; sort: string; selectedState: number; selectedStates: any; periodType: string; }; };

  constructor(private dialog: MatDialog,
    public _ReportsService: ReportsService,
    private _ReportInfoService: ReportInfoService,
    private _Worklist: WorklistService,
    private _AuthService: AuthService,
    private _router: Router,
    private _Toast: ToastService,
    private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    this.stateParams = <any>this.route.snapshot.queryParams;
    /* period: this.filterParameters.periodType,
    selectedState: this.getSelectedStateKey(),
    rptGrping: this.filterParameters.rptGrping */

    //arrays holding state data
    this.states = new Array();
    this.fullStateArray = new Array();
    this.selectedStatesArray = new Array();

    // Array to hold list of students that are flagged for merge
    this.listOfStudentsToFlagForMerge = new Array();

    // Enable/disable 'Flag for Merge' button
    this.isMergeListEmpty = true;

    // 'Flag for Merge' functionality is available to only data admins.
    // When user visits this page, following roles are verified if they have it or not.
    this.roleClasses = [
      'dataAdmins'
    ];

    this.resetFilter();
    this.checkRoleClasses();
    this.getReportInfo();
  }

  goToReportsNew() {
    this._router.navigate(['reports']);
  }

  goToReport(reportName: string) {
    var filterSelectedState: any = null;
    if (this.filterParameters.selectedStates != null && this.filterParameters.selectedStates.length > 0) {
      filterSelectedState = this.filterParameters.selectedStates[0].key;
    }

    let reportPath = '';
    if (reportName === 'reconciliationDash') {
      reportPath = 'dashboard';
    } else if (reportName === 'reconNearMatches') {
      reportPath = 'nearMatches';
    }

    this._router.navigate(['reports/reconciliation/' + reportPath], {
      queryParams: {
        period: this.stateParams.period,
        selectedState: filterSelectedState,
        rptGrping: this.stateParams.rptGrping,
        fromBreadcrumbs: true
      }
    });
  }

  retrieveReport() {

    let apiCall = this._ReportsService.reconciliationPotentialDupes(this.filterParameters);
    let subscribed = apiCall.subscribe({
      next: data => {
        this.response = data;

        subscribed.unsubscribe();
      },
      error: error => {
        console.log('Error occurred in retrieveReport() when calling _ReportService.reconciliationPotentialDupes API: ' + error);

        subscribed.unsubscribe();
      }
    });
  }

  // sets filter initially and resets filter when called
  resetFilter() {
    var filterSelectedState = this.stateParams.selectedState;
    var filterSelectedStates: StateInfo[] = new Array();

    if (filterSelectedState != null && filterSelectedState > 0) {
      var obj = {
        key: filterSelectedState
      }
      filterSelectedStates.push(obj);
    } else {
      filterSelectedState = -1;
    }

    this.filterParameters = {
      rowStart: 1,
      rowEnd: 100,
      page: 1,
      sort: 'MSIX_ID_ASC',
      selectedState: filterSelectedState,
      selectedStates: filterSelectedStates,
      periodType: this.stateParams.period
    };

    this.exportParams = {
      report: 'potentialDuplicates',
      params: this.filterParameters
    };
  }

  // gets info needed to generate report
  getReportInfo() {
    this.reportInfo = this._ReportInfoService.getReportInfo(['dataQuality_all', 'dataQuality']);
    // generates report
    this.retrieveReport();
  }

  // sets the selectedStatesArray with list of states
  mineAndSetStatesArray(responseData) {
    for (var i in responseData) {
      // add every state to the state array except for DC (stateKey: "9"), PR (stateKey: "40"), and WY (stateKey: "52")
      if (!(responseData[i].stateKey == "9" || responseData[i].stateKey == "40" || responseData[i].stateKey == "52")) {
        let obj = {
          key: responseData[i].stateKey
        }
        this.states.push(responseData[i]);
        this.fullStateArray.push(obj);
        this.selectedStatesArray.push(obj);
      }
    }
  }

  // sets the selected states in the filter object, based on user role
  setSelectedStates() {
    var roles = this.reportInfo.authRoleClasses;
    if (roles.dataQuality == true) {
      this.filterParameters.selectedStates = this.selectedStatesArray;
    } else {
      let arr = [{
        key: this.reportInfo.userState
      }]
      this.filterParameters.selectedStates = arr;
    }
  }

  handleReportSpecialSortClick(newSortKey) {
    this.filterParameters.sort = newSortKey.sortKey;
    this.retrieveReport();
  }

  // Update the list of students selected to flag for merge 
  checkedRecordForFlagForMerge(rowInfo) {
    let row = rowInfo.row;
    let isChecked = rowInfo.isChecked;

    if (isChecked) {
      this.listOfStudentsToFlagForMerge.push(row.column1.value);
    } else {
      let i = this.listOfStudentsToFlagForMerge.indexOf(row.column1.value);
      this.listOfStudentsToFlagForMerge.splice(i, 1);
    }

    this.isMergeListEmpty = (this.listOfStudentsToFlagForMerge.length > 0) ? false : true;
  }

  // Call API to flag all the selected students for merge
  flagForMergeAllStudents() {
    let apiCall = this._Worklist.flagAllSelectedStudentsForMerge(this.listOfStudentsToFlagForMerge);
    let subscribed = apiCall.subscribe({
      next: data => {
        this.listOfStudentsToFlagForMerge = [];
        this.isMergeListEmpty = true;

        this.retrieveReport();
        this._Toast.showToast('Students have been added to merge worklist');
        console.log(data);

        subscribed.unsubscribe();
      },
      error: error => {
        this._Toast.showToast(error.error.developerMessage, 0);
        console.log('Error occurred in flagForMergeAllStudents() when calling _Worklist.flagAllSelectedStudentsForMerge API: ' + error);

        subscribed.unsubscribe();
      }
    });
  }

  // Learn More Activate
  learnMoreActivate() {

    const dialogRef = this.dialog.open(ReconPotentialDuplicatesLearnMore, {
      height: 'auto',
      width: '60%',
      minHeight: '325px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false
    });
  }

  navPages(direction) {
    if (direction === 'next') {
      this.filterParameters.rowStart += 100
      this.filterParameters.rowEnd += 100
      this.filterParameters.page++
    } else {
      this.filterParameters.rowStart -= 100
      this.filterParameters.rowEnd -= 100
      this.filterParameters.page--
    }
    this.retrieveReport();
  }

  disableNavigation() {
    if (this.response.totalAvailableRows / 100 <= this.filterParameters.page) {
      return true;
    }
    return false
  }

  // Check if the logged in user has any of the listed roles
  checkRoleClasses() {
    this._AuthService.isRoleClassAuthorized(this.roleClasses).then(data => {
      this.authRoleClasses = data;
    });
  }
}

@Component({
  selector: 'recon-potential-duplicates-learn-more',
  templateUrl: 'dupesLearnMore.html',
  styleUrls: ['./potentialDuplicates.component.scss']
})
export class ReconPotentialDuplicatesLearnMore {

  stateParams: {
    report: string,
    option: string
  }

  constructor(public dialogRef: MatDialogRef<ReconPotentialDuplicatesLearnMore>) {

  }

  // Close Learn More
  closeDialog() {
    this.dialogRef.close();
  }
}
