<div class="dataAdmin-page">
  <div aria-label="Confirm Merge" class="editAddDialog filter-dialog">
    <!-- TODO - need to add *ngIf to replace ng-cloak-->
    <!-- <form ng-cloak> -->
      <mat-toolbar style="padding: 0px">
        <div class="md-toolbar-tools toolbar md-toolbar">
          <h2 style="font-size: inherit !important; font-weight: inherit !important">Edit Region</h2>
          <span style="display: flex; flex: auto"></span>
          <button class="md-button md-icon-button" (click)="closeDialog()" aria-label="close dialog">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </mat-toolbar>

      <mat-dialog-content>
        <div class="md-dialog-content">

          <div class="layout-padding-l-r-17">
            <div class="custom-input-container worklist-commentbox-padding layout-column">
              <!-- <mat-form-field class="md-block md-input-container-margins background-white">
                <mat-label class="custom-label">Name</mat-label>
                <textarea matInput class="custom-input" [(ngModel)]="formData.name" rows="1" md-select-on-focus></textarea>
              </mat-form-field> -->
              <div class="custom-input-container">
                <mat-form-field style="height: 70px; width: 100%;" class="example-full-width textarea">
                  <mat-label class="custom-label" [ngClass]="{'box-selected': inputField.focused || formData.name}"
                    for="inputFieldName">Name</mat-label>
                  <textarea matInput #inputField="matInput" style="height: 55px; border: 1px solid #888;"
                    class="comment-text" [ngClass]="{'mat-input-has-value': formData.name}" [(ngModel)]="formData.name"
                    name="inputFieldName" maxlength="250" [ngModelOptions]="{standalone: true}"></textarea>
                </mat-form-field>
              </div>
              <div class="custom-input-container">
                <mat-form-field style="height: 150px; width: 100%;" class="example-full-width textarea">
                  <mat-label class="custom-label" [ngClass]="{'box-selected': inputField.focused || formData.description}"
                    for="inputFieldDesc">Description</mat-label>
                  <textarea matInput #inputField="matInput" style="height: 140px; border: 1px solid #888;"
                    class="comment-text" [ngClass]="{'mat-input-has-value': formData.description}" [(ngModel)]="formData.description"
                    name="inputFieldDesc" maxlength="250" [ngModelOptions]="{standalone: true}" ></textarea>
                  <div class="md-errors-spacer" style="color: rgba(0,0,0,0.87);">
                    <div class="md-char-counter">{{formData.description.length}} / 250</div>
                  </div>
                </mat-form-field>
              </div>
              <div class="layout-row layout-align-start-center flex-100" style="margin-top: 20px">
                <p class="flex-20">Enabled</p>
                <mat-slide-toggle class="flex-20" [(ngModel)]="formData.active" aria-label="Save Record Switch"
                [ngModelOptions]="{standalone: true}"></mat-slide-toggle>
              </div>
            </div>
          </div>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions class="layout-row" style="padding: 0px 15px">
        <hr class="thinHr">
        <a (click)="closeDialog()">
          <span>Cancel</span>
        </a>
        <span style="display: flex; flex: auto"></span>
        <button (click)="submitEditedRegion()" [disabled]="(jsonStringify(formData) === jsonStringify(originalForm))" class="md-button confirmButton">
          CONFIRM
        </button>
      </mat-dialog-actions>

  </div>
</div>
