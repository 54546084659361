import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { AppConstants }  from '../config/index.constants';
import { EncoderService } from '../services/encoder.service';
import { LoginService } from '../services/login.service';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router'; 
// import { districtsForState, regionsFromState, regularUser, schoolsForState, superUser } from './tmpuserdata';
const moment = require('moment');

export interface User {
  name: string,
  fetched: boolean
}

export type DrillDownItem = {
  id: string,
  key: number,
  code: string | null,
  description: string
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private apiUrl = AppConstants.api;
  router: string;
  passwordResetFlag: boolean;
  challengeQuestionResetFlag: boolean;
  current: User | null;
  constructor(
    private http: HttpClient,
    private encoderService: EncoderService,
    private cookieService: CookieService,
    private loginService: LoginService,
    private _router: Router) {
      
    this.router = _router.url;
    this.current = null;
    this.passwordResetFlag = false;
    this.challengeQuestionResetFlag = false;
  }

  /* async getStates(): Promise<void> {
    await this.stateService.getStates().then(
      data => {
        this.mineAndSetStatesArray(data);
        console.log("Getting all states " + angular.toJson(this.fullStateArray));
        //statesSubscribed.unsubscribe();
      }, 
      error => {
        console.error('some error');
      }
    );
  } */

  async getCurrentUser(): Promise<User> {
    // TODO - Need to figure out how exactly moment() is being used here and re-apply.
    /* if(this.current && moment(this.current.fetched).isAfter(moment().subtract(1, 'm'))){
      console.log('returning cached user: ');
      console.log(this.current);
      var temp = this.current;
      return new Promise(function(resolve, reject){
        var toReturn ={
          data: temp
        };
        resolve(toReturn);
      });
    } */
    
    if (!this.current) {
      try {
        console.log('Getting and storing user info.');
        // return new Promise((res, _) => res(
        // // superUser
        // regularUser
        
        // ))
        const userDataAPI = this.http.get<User>(this.apiUrl + 'user');
        await firstValueFrom(userDataAPI).then( userData => {
          this.current = userData;
          console.log('Setting user data after asyn/await firstValueFrom call in user.service.getCurrentUser()');
        });
        this.current.fetched = moment();
        console.log('user.service.getCurrentUser() - After calling getUser API.');
      } catch {
        console.error('Error getting user information in user.service.getCurrentUser() API call.');
      }
    }

    return this.current;
  }

  logout(){
    this.current = null;

    // Remove user related cookies
    // Get all cookies and if exists, then remove it. getAll() returns an object with key: value pair for each cookie.
    let allCookies = this.cookieService.getAll();

    if (allCookies.privacyAccept) {
      this.cookieService.remove('privacyAccept');
    }

    if (allCookies.user) {
      this.cookieService.remove('user');
    }

    if (allCookies.userRoles) {
      this.cookieService.remove('userRoles');
    }

    if (allCookies.userStatuses) {
      this.cookieService.remove('userStatuses');
    }

    if (allCookies.username) {
      this.cookieService.remove('username');
    }

    if (allCookies.challengeQuestionsList) {
      this.cookieService.remove('challengeQuestionsList');
    }

    if (allCookies.isChallengeQuestionSet) {
      this.cookieService.remove('isChallengeQuestionSet');
    }

    if (allCookies.worklistCount) {
      this.cookieService.remove('worklistCount');
    }
  }

  async getUser(username): Promise<any> {
    //return this.http.get<ArrayBuffer[]>(this.apiUrl + 'user/' + username);

    const userDataAPI = this.http.get<ArrayBuffer[]>(this.apiUrl + 'user/' + username);

    return await lastValueFrom(userDataAPI);

  //   return new Promise((res, _) => res(
  // //  superUser
  //  regularUser
  //   ))

    /* try {
      await lastValueFrom(userDataAPI).then( userData => {
        console.log('Getting data for user: ' + username + ' after asyn/await firstValueFrom call in user.service.getUser(username)');
        return userData;
      });
    } catch {

    } */
  }

  /* Updates user's ROB */
  acceptROB(): any {
    let apiCall = this.http.put<User>(this.apiUrl + 'user/rob', null);
    /* let subscribed = apiCall.subscribe(data => this.current = data);
    subscribed.unsubscribe(); */
    return apiCall;
  }

  setCurrentUser(User) {
    this.current = User;
  }

  /* Sets new password */
  updatePassword(username, newPassword) {
    var encodedNew = encodeURIComponent(newPassword);
    
    return this.http.put<ArrayBuffer[]>(this.apiUrl + 'user/' + username + 
    '/password?newPassword=' + encodedNew, '');
  }

  getAllChallengeQuestions() {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'user/challengequestions/all');
  }

  /* Update new challenge questions */
  saveSelectedChallengeQuestions(user) {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'user/challengequestions/save', user);
  }

  /* Get challenge questions */
  getAllCQsforUser(userName){
    return this.http.get<any>(this.apiUrl + 'user/allchallengequestionsandresponses/' + userName);
  }

  // This API is called when user submits username when they forget their password.
  selfServicePasswordReset(userName): any {
    return this.http.put<ArrayBuffer[]>(this.apiUrl + 'user/resetPasswordRequest/' + userName, '');
  }

  // This function calls the API to get random 3 challenge questions so we 
  // confirm user's account and allow updates.
  getChallengeQAndAToVerifyUser(userName): any {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'user/challengeQAndAVerification/' + userName);
  }

  // This funciton is called when user has logged in using username/password and does not have access to previously registerd MFA device to get TOTP code.
  // This function will all the backend API to verify the challenge questions and associated responses that user entered to verify the user account.
  verifyChallengequestionsForLoggedInUser(userName, userChallengeQuestion): any {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 
      'user/verifyChallengequestionsResponsesForLoggedInUser/' + userName, userChallengeQuestion);
  }

  verifyChallengeQuestions(userName, userChallengeQuestion) {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'user/verifyChallengequestions/' + 
    userName, userChallengeQuestion);
  }

  adminResetPassword(username) {
    return this.http.put<ArrayBuffer[]>(this.apiUrl + 'user/admin/password?userName=' + username, '');
  }

  //Resets a user's MFA setup from the User Record page
  adminResetMFA(username) {
    return this.http.put<ArrayBuffer[]>(this.apiUrl + 'user/admin/resetmfa?userName=' + username, '');
  }

  //Resets a user's MFA secretKey and registration after being authenticated but not authorized yet
  resetAuthenticatedUserMFA(username): any {
    return this.http.put<ArrayBuffer[]>(this.apiUrl + 'user/resetAuthenticatedUserMFA?userName=' + username, '');
  }
  
  //API call to get the QR Code when trying to configure a new MFA device from the My Account page
  authenticatedRegisterMfa(): any {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'user/authenticatedRegisterMfa');
  }

  //API call to verify the MFA code when trying to configure a new MFA device from the My Account page
  authenticatedVerifyMfa(mfaCode): any {
    return this.http.put<ArrayBuffer[]>(this.apiUrl + 'user/authenticatedVerifyMfa?mfaCode=' + mfaCode, '');
  }

  deactivateUser(username, userObj) {
    return this.http.put<ArrayBuffer[]>(this.apiUrl + 'user/' + username + '/deactivate', userObj);
  }

  resetSelfPassword(): any {
    return this.http.put<ArrayBuffer[]>(this.apiUrl + 'user/password', '');
  }

  getUsers(username, firstName, lastName) {
    if (!username) {
      username = '';
    }
    if (!firstName) {
      firstName = '';
    }
    if (!lastName) {
      lastName = '';
    }
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'user/search?userID=' + username + '&firstName=' + firstName + '&lastName=' + lastName);
  }

  updateUserSelf(user) {
    return this.http.put<User>(this.apiUrl + 'user', user);
  }

  updateUser(username, user) {
    return this.http.put<ArrayBuffer[]>(this.apiUrl + 'user/' + username, user);
  }

  updateJobTitlesForUser(username, jobTitles) {
    return this.http.put<ArrayBuffer[]>(this.apiUrl + 'user/updateJobTitles/' + username, jobTitles);
  }

  addUser(user): Observable<any> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'user', user);
  }

  setPasswordResetFlag(boolean) {
    this.passwordResetFlag = boolean;
  }

  getPasswordResetFlag() {
    return this.passwordResetFlag;
  }

  setChallengeQuestionResetFlag(boolean) {
    this.challengeQuestionResetFlag = boolean;
  }

  getChallengeQuestionResetFlag() {
    return this.challengeQuestionResetFlag;
  }

  //Get a list of user statuses
  getUserStatuses(): any {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'user/getAllUserAccountStatuses');
  }

  //Get list of user roles
  getUserRoles(): any {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'user/getAllAllowedUserRoles');
  }

  //Get a list of all regions from a user's state
  getRegionsFromState() {
    return this.http.get<DrillDownItem[]>(this.apiUrl + 'user/getAllRegionsFromaState');
    // return of(regionsFromState)
  }

  //Get a list of all districts from a user's state
  getDistrictsFromState() {
    return this.http.get<DrillDownItem[]>(this.apiUrl + 'user/getAllDistrictsFromaState');
    // return of(districtsForState)
  }

  //Get a list of all schools from a user's state
  getSchoolsFromState() {
    return this.http.get<DrillDownItem[]>(this.apiUrl + 'user/getAllSchoolsFromaState');
    // return of(schoolsForState)
  }

  //Get a list of users to share a saved list with Active/Locked/Expired status and has at least one of the following roles:
  // Superuser, MSIX Primary User, Secondary User, State/Regional/District Data Administrator
  getActiveLockedExpiredUsers() {
    return this.http.get<any>(this.apiUrl + 'user/getActiveLockedExpiredUsers');
  }

  //Get a list of users to share a saved list with
  getJobTitles(username): any {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'user/getJobTitles?userName=' + username);
  }
}