import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth.service';
import { PreviousRouteService } from 'src/app/services/previous-route.service';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { SearchService } from 'src/app/services/search.service';
import { StateService } from 'src/app/services/state.service';
import { ToastService } from 'src/app/services/toast.service';
import { UserService } from 'src/app/services/user.service';
import { WorklistService } from 'src/app/services/worklist.service';
import { WorkHistoryService } from '../work-history-results/work-history-results.service';
@Component({
    selector: 'app-work-history',
    templateUrl: './work-history.component.html',
    styleUrls: ['./work-history.component.scss']
  })
  export class WorklistHistoryComponent implements OnInit {
    
    private fullList: any[] = [];
    private worklistQuery = {
      dateSearchQuery: '',
      fromDateMM: '',
      fromDateDD: '',
      fromDateYYYY: '',
      toDateMM: '',
      toDateDD: '',
      toDateYYYY: '',
      createdFromDateMM: '',
      createdFromDateDD: '',
      createdFromDateYYYY: '',
      createdToDateMM: '',
      createdToDateDD: '',
      createdToDateYYYY: '',
      resolvedFromDateMM: '',
      resolvedFromDateDD: '',
      resolvedFromDateYYYY: '',
      resolvedToDateMM: '',
      resolvedToDateDD: '',
      resolvedToDateYYYY: ''
    };
    private itemsPerLoad: number = 50;
    private currentPage: number = 1;
    private totalItemsToLoad = this.currentPage * this.itemsPerLoad;
    private $stateParams: any;
    private worklistHistorySearchType: any;
    searchObject: any;
    authRoleClasses: any;
    queryType: string;
    formTitle: string;
    title: string;
    states: any;
    totalRecordsLength: any;
    partialWlList: any = [];
    subscription = new Subscription();

    constructor(private activatedRoute: ActivatedRoute,
      private _router: Router,
      private _AuthService: AuthService,
      private _User: UserService,
      private _State: StateService,
      private _Worklist: WorklistService,
      private _Toast: ToastService,
      private _Search: SearchService,
      public responsiveUI: ResponsiveUIService,
      private stateService: StateService,
      private service: WorkHistoryService,
      private previousRouteService: PreviousRouteService) {
      
      this.$stateParams = <any>this.activatedRoute.snapshot.queryParams;
      console.log("title: ", this.$stateParams);
    }
  
    async ngOnInit() {;
      // get the worklist search type from the stateParams & set the queryType
      this.worklistHistorySearchType = this.$stateParams.worklistHistorySearchType
      await this.checkRoleClasses();
      this.setTitle();
      this.getStates();
      if(this.$stateParams) {      
        this.searchObject = JSON.parse(JSON.stringify(this.$stateParams));
      }
      //Breadcrumb navigation checking
      this.subscription.add(this.previousRouteService.previousRoute$.subscribe(prevRoute => {
        if (prevRoute.includes('/worklistHistoryRecord')) {        
          this.searchObject = this.service.getWorkHistoryResults();
        }
      }));
      this.extractStateParams();
      if (this.searchObject && this.searchObject.queryType) {
        this.searchForStudents(this.searchObject);
      }
    }

    goToWorklist() {
      this._router.navigate(['worklist']);
    }
  
    async checkRoleClasses() {
  
      await this._AuthService.isRoleClassAuthorized(['initiateMerge', 'validateMerge', 'worklistSearch']).then(data => {
        this.authRoleClasses = data;
  
        if (this.worklistHistorySearchType === 'mergeAndSplitWorklistHistory' && this.authRoleClasses.worklistSearch) {
          this.queryType = 'worklist';
          this.formTitle = 'Merge and Split';
        } else {
          if(this.worklistHistorySearchType !== 'corWorklistHistory'){
  
            this._router.navigate(['worklistHistory'], {
              queryParams: {
                worklistHistorySearchType: 'corWorklistHistory',
                query: this.$stateParams.query
              }
            });
          }
          this.queryType = 'cor';
          this.formTitle = 'Change of Residency';
        }
      });
    }
  
    setTitle(){
      console.log("worklistHistorySearchType: ", this.$stateParams.worklistHistorySearchType);
      if (this.$stateParams.worklistHistorySearchType === 'corWorklistHistory'){
        this.title = 'Data Request & Move Notice History';
      }
      if (this.$stateParams.worklistHistorySearchType === 'mergeAndSplitWorklistHistory'){
        this.title = 'Merge & Split Worklist History';
      }
    }
  
    async getStates(): Promise<void> {
      this.states = await this.stateService.getStates();
    }
  
    extractStateParams() {
      this.worklistQuery = this.searchObject;
      if(!this.searchObject){
        return;
      }
      if (this.searchObject.creationFromDate) {
        var createFromDateArray = this.searchObject.creationFromDate.split('/');
        this.worklistQuery.createdFromDateMM = createFromDateArray[0];
        this.worklistQuery.createdFromDateDD = createFromDateArray[1];
        this.worklistQuery.createdFromDateYYYY = createFromDateArray[2];
      }
      if (this.searchObject.creationToDate) {
        var createToDateArray = this.searchObject.creationToDate.split('/');
        this.worklistQuery.createdToDateMM = createToDateArray[0];
        this.worklistQuery.createdToDateDD = createToDateArray[1];
        this.worklistQuery.createdToDateYYYY = createToDateArray[2];
      }
      if (this.searchObject.resolutionFromDate) {
        var resolutionFromDateArray = this.searchObject.resolutionFromDate.split('/');
        this.worklistQuery.resolvedFromDateMM = resolutionFromDateArray[0];
        this.worklistQuery.resolvedFromDateDD = resolutionFromDateArray[1];
        this.worklistQuery.resolvedFromDateYYYY = resolutionFromDateArray[2];
      }
      if (this.searchObject.resolutionToDate) {
        var resolutionToDateArray = this.searchObject.resolutionToDate.split('/');
        this.worklistQuery.resolvedToDateMM = resolutionToDateArray[0];
        this.worklistQuery.resolvedToDateDD = resolutionToDateArray[1];
        this.worklistQuery.resolvedToDateYYYY = resolutionToDateArray[2];
      }
    }
  
    searchInit(data) {
      this.searchObject = data.searchobject;
      this.searchForStudents(data.searchobject);
    }

    searchForStudents(query) {
      var loopCount;
  
      if (query) {
        query.queryType = this.queryType;
        let apiCall = this._Worklist.searchCOR(query);
        let subscribed = apiCall.subscribe({
          next: data => {
            console.debug(data);
            this.totalRecordsLength = data.results.totalRecords;
            this.partialWlList = data.results.partialWlList;
            this.currentPage = 1;
            this.totalItemsToLoad = this.currentPage * this.itemsPerLoad; // reset totalItemsToLoad
            if (this.partialWlList.length < this.totalItemsToLoad){
              loopCount = this.partialWlList.length;
            }else{
              loopCount = this.totalItemsToLoad;
            }
            this.fullList = []; //empty on every search
            for (var i = 0; i < loopCount; i++) {
              if (this.searchObject.queryType === 'cor'){
                this.partialWlList[i].worklistType = this.partialWlList[i].worklistType.replace( /([A-Z])/g, " $1" ).trim();
              }
              this.fullList.push(this.partialWlList[i]);
            }
  
            subscribed.unsubscribe();
          },
          error: error => {
            console.error(error);
            this._Toast.showToast(error.error.developerMessage, 0);
  
            subscribed.unsubscribe();
          }
        });
      }
    }

    
    ngOnDestroy(): void {
      this.subscription.unsubscribe()
  }
  }