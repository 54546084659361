import * as d3 from 'd3';
import { Component, Input, SimpleChanges} from '@angular/core';
import * as angular from 'angular';

const ChartTypes = Object.freeze({
    WITHDRAWALSCOURSEHISTORY: "withdrawalCourseHistoryChart"
});

// Make sure that the keys match the ChartTypes constant!
const TooltipHeights = {
    "withdrawalCourseHistoryChart": "60px"
};

const TooltipWidths = {
    "withdrawalCourseHistoryChart": "325px"
};

// Reference the examples housed under d3js.org with questions
@Component({
    selector: 'msix-stacked-bar',
    templateUrl: './msixStackedBar.component.html',
    styleUrls: ['./msixStackedBar.component.scss']
  })
  
// Reference the examples housed under d3js.org with questions
export class MsixStackedBarComponent {
  // Gets info from Angular bindings, data is coming from the API as a DatabaseWrapper class containing the majority of chart info
  @Input() chart: any;
  @Input() data: any;
  @Input() id: any;

  constructor() {}

  ngOnInit() {
      this.paint();
  }
  // Call the paint function, add listeners for resize or if the chart attribute changes value (filter change)
  
  ngOnChanges(changes: SimpleChanges) {
      window.addEventListener("resize", this.paint);
      this.paint();
  }

  // Function for building the full chart
  paint() {
    d3.select("#" + this.id).selectAll("*").remove();

    var margin = resolveMargin(this.chart);
    var height = window.innerHeight / 1.8 > 500 ? window.innerHeight / 1.8 : 500;
    var width = document.getElementById("chart-section").offsetWidth > 700 ? document.getElementById("chart-section").offsetWidth : 700;

    var groupCategories = resolveGroupCategories(this.data);
    var colors = resolveColors(this.chart);

    var xValues = resolveXValues(this.data);
    var legendMap = resolveLegendMap(this.data);
    var data = resolveData(this.data);
    var hoverData = resolveHoverMap(this.chart, data);

    var series = d3.stack()
        .keys(groupCategories)(data)
        .map(d => (d.forEach(v => v.category = d.category), d));

    var x = d3.scaleBand()
      .domain(xValues)
      .rangeRound([margin.left, width - margin.right])
      .paddingInner(0.2);

    var y = d3.scaleLinear()
      .domain([0, Math.max(d3.max(series, d => d3.max(d, d => d[1]) * 1.1), 10)]).nice()
      .rangeRound([height - margin.bottom, margin.top]);

    var color = d3.scaleOrdinal()
      .domain(Object.keys(legendMap).reverse())
      .range(colors);

    var xAxis = g => g
      .attr("transform", `translate(${0},${height - margin.bottom})`)
      .call(d3.axisBottom(x).tickSizeOuter(0))
      .attr("class", "axis")
      .style("font-family", "Work Sans, sans-serif")
      .style("font-size", "15px")
      .style("color", "#4A4A4A")
      .selectAll(".axis text")
      .call(formatMonths);

    var yAxis = g => g
      .attr("transform", `translate(${margin.left},${0})`)
      .call(d3.axisLeft(y).ticks(null, "s").tickSizeOuter(0).tickFormat(d3.format(",")))
      .attr("class", "axis")
      .style("font-family", "Work Sans, sans-serif")
      .style("font-size", "15px")
      .style("color", "#4A4A4A");

    var legend = svg => resolveLegend(svg, this.chart);

    var grid = g => g
      // Vertical
      .attr("stroke", "currentColor")
      .attr("class", "grid")
      .attr("stroke-opacity", 0.3)

      // Horizontal gridlines (conntected to above)
      .call(g => g.append("g")
        .selectAll("line")
        .data(y.ticks())
        .join("line")
        .attr("y1", d => 0.5 + y(d))
        .attr("y2", d => 0.5 + y(d))
        .attr("x1", margin.left)
        .attr("x2", width - margin.right));

    // Define the div for the tooltip
    var div = d3.select("#" + this.id).append("div")
      .attr("class", "linechart-tooltip")
      .style("opacity", 0);

    // The actual svg element of the chart
    var svg = d3.select("#" + this.id).append('svg')
      .attr("class", "dashboard-svg")
      .attr("viewBox", [0, 0, width, height])
      .style("overflow", "visible");

    // Calls the functions from earlier
    svg.append("g")
      .call(xAxis);

    svg.append("g")
      .call(yAxis);

    svg.append("g")
      .call(legend);

    svg.append("g")
      .call(grid);

    // Small screen adjustment (carryover from original lineChart directive)
    var horizShift = 0
    if (window.innerWidth < 1100) {
      horizShift = 50
    }

    var dataG = svg.append("g")
      .attr("transform", `translate(${0},${0})`);

    if (this.chart == ChartTypes.WITHDRAWALSCOURSEHISTORY) {
      // The red asterisks
      dataG.selectAll("text")
        .data(data)
        .join("text")
        // .data(d => d)
        .attr("class", "label")
        .attr("class", "legend")
        .attr("text-anchor", "middle")
        .attr("pointer-events", "none")
        .attr("x", d => x(d.category) + x.bandwidth() / 2)
        .attr("y", d => y(d.history + d.none) - 2)
        .style("fill", "#73260D")
        .style("font-family", "Work Sans, sans-serif")
        //.style("font-size", "16px")
        .style("color", "#4A4A4A")
        .attr("font-size", 30)
        .text(d => {
          if (d.history == 0 && d.none != 0) {
              return "*";
          }
          return;
        });
    }

    // Stacked bars
    dataG.append("g")
      .selectAll("g")
      .data(series)
      .join("g")
      .attr("fill", d => color(d.key))
      .attr("class", "stacks")
      .selectAll("rect")
      .data(d => d)
      .join("rect")
      .attr("x", (d, i) => x(d.data.category))
      .attr("y", d => y(d[1]))
      .attr("height", d => y(d[0]) - y(d[1]))
      .attr("width", x.bandwidth())
      .on("pointerover", (event, d) => { // Tooltip
        if (event.pointerType == "touch") {
          event.preventDefault();
          event.stopPropagation();
          return;
        }

        div.transition()
          .duration(200)
          .style("opacity", .9);

        div.html(resolveHover(d, hoverData))
          .style("left", (event.pageX) + "px")
          .style("top", (event.pageY) + "px")
          .style("height", TooltipHeights[this.chart])
          .style("width", TooltipWidths[this.chart])
          .style("position", "absolute") // had to add styles from "linechart-tooltip" class to here specifically, class wasnt being applied
          .style("text-align", "center")
          .style("font", "12px sans-serif")
          .style("border", "0px")
          .style("padding", "2px")
          .style("background", "lightsteelblue")
          .style("border-radius", "8px")
          .style("pointer-events", "none");
      })
      .on("pointerout", (event, d) => {
        if (event.pointerType == "touch") {
          event.preventDefault();
          event.stopPropagation();
          return;
        }

        div.transition()
          .duration(500)
          .style("opacity", 0);
      })
      .on('pointermove', (event, d) => {
        if (event.pointerType == "touch") {
          event.preventDefault();
          event.stopPropagation();
          return;
        }

        div.style('top', (event.pageY - 115) + 'px')
          .style('left', (event.pageX - horizShift - 100) + 'px');
      })
      .on("touchend touchstart", (event, d) => {
        event.preventDefault();
      });

    // Y Axis Label
    svg.append("text")
      .attr("class", "axis")
      .attr("transform", "rotate(-90)")
      .attr("y", 0)
      .attr("x", -((height - margin.bottom + margin.top) / 2))
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("font-family", "Work Sans, sans-serif")
      .style("font-size", "15px")
      .style("color", "#4A4A4A")
      .text(this.data.y);

    // X Axis Label
    svg.append("text")
      .attr("class", "axis")
      .attr("y", height - margin.bottom + 40)
      .attr("x", width / 2)
      .attr("dy", "1em")
      .style("text-anchor", "middle")
      .style("font-family", "Work Sans, sans-serif")
      .style("font-size", "15px")
      .style("color", "#4A4A4A")
      .text(this.data.x);

    function resolveLegend(svg, chartType) {
      const g = svg
        .attr("transform", `translate(${margin.left + 20},${height - margin.bottom / 2})`)
        .attr("text-anchor", "start")
        .attr("class", "legend")
        .style("font-family", "Work Sans, sans-serif")
        .style("font-size", "16px")
        .style("color", "#4A4A4A")
        .selectAll("g")
        .data(color.domain().slice())
        .join("g")
        .attr("transform", (d, i) => `translate(${0},${i * 25})`);

      g.append("rect")
        .attr("x", -19)
        .attr("width", 19)
        .attr("height", 19)
        .attr("fill", d => {
          if (d == "*") return "none";
          return color(d);
        });

      // This is the asterisk section. Only used for withdrawal/course history chart
      if (chartType == ChartTypes.WITHDRAWALSCOURSEHISTORY) {
        g.append("text")
          .attr("x", -10)
          .attr("y", 23)
          .attr("text-anchor", "middle")
          .style("fill", "#73260D")
          .attr("font-size", 30)
          .text(d => {
            if (d == "*")
              return d;
            return "";
          });
      }

      g.append("text")
        .attr("x", 5)
        .attr("y", 9.5)
        .attr("dy", "0.35em")
        .text(d => legendMap[d]);
    }

    function resolveMargin(chartType) {
      return { top: 30, right: 10, bottom: 150, left: 80 };
    }

    function resolveGroupCategories(fullData) {
      return fullData.groupCategories;
    }

    function resolveXValues(fullData) {
      return fullData.xValues;
    }

    function resolveColors(chartType) {
      if (chartType == ChartTypes.WITHDRAWALSCOURSEHISTORY)
        return ["#808080", "#2CA02C"];
    }

    function resolveData(fullData) {
      let adjustedData = angular.copy(fullData.data);
      for (let row of adjustedData) {
        for (let point of row) {
          point.value = Number(point.value);
        }
      }
      fullData.data = angular.copy(adjustedData);
      return aggregate(angular.copy(fullData));
    }

    function resolveLegendMap(fullData) {
      return fullData.legendMap;
    }

    function resolveHover(element, hoverMap) {
      return hoverMap[element.data.category];
    }

    function resolveHoverMap(chartType, data) {
      let retMap = {};

      if (chartType == ChartTypes.WITHDRAWALSCOURSEHISTORY) {
        for (let element of data) {
          let total = element.history + element.none;
          let htmlBuilder = "";
          htmlBuilder = "Children Withdrawn: " + d3.format(",")(total) + "<br/>" +
              "Children Withdrawn with Course History Submitted: " + element.history + "<br/>";
          if (total > 0) {
              htmlBuilder += "Percent with Course History: " + d3.format(".2f")(element.history / (total) * 100) + "%<br/>";
          } else {
              htmlBuilder += "Percent with Course History: " + d3.format(".2f")(0) + "%<br/>";
          }

          retMap[element.category] = htmlBuilder;
        }
      }
      return retMap;
    }

    // Wraps text utilizing tspans
    function wrap(text, width) {
      text.each(function () {
        var text = d3.select(this);
        var words = text.text().split(/\s+/).reverse();
        var word = words.pop();
        var line = [];
        var lineNumber = 0;
        var lineHeight = 1; // ems
        var y = text.attr("y");
        var dy = parseFloat(text.attr("dy"));
        var tspan = text.text(null).append("tspan").attr("x", 0).attr("y", y).attr("dy", dy + "em");
        while (word != undefined) {
          line.push(word);
          tspan.text(line.join(" "));
          if (tspan.node().getComputedTextLength() > width) {
            line.pop();
            tspan.text(line.join(" "));
            line = [word];
            tspan = text.append("tspan").attr("x", 0).attr("y", y).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
          }
          word = words.pop();
        }
      });
    }

    function formatMonths(text) {
      text.each(function () {
        var text = d3.select(this);
        var words = text.text().split(/\s+/).reverse();
        var word = words.pop();
        // var line = [];
        var lineNumber = 0;
        var lineHeight = 1; // ems
        var y = text.attr("y");
        var dy = parseFloat(text.attr("dy"));
        text.text(null);
        while (word != undefined) {
          text.append("tspan").attr("x", 0).attr("y", y).attr("dy", lineNumber++ * lineHeight + dy + "em").text(word);
          word = words.pop();
        }
      });
    }

    function aggregate(thisData) {
      var stackedData = [];
      var stackMap = {};

      for (let x of thisData.xValues) {
        stackMap[x] = {};
      }

      for (let element of thisData.data[0]) {
        stackMap[element.category] = Object.assign({ [element.subcategory]: element.value }, stackMap[element.category]);
      }

      for (let element in stackMap) {
        stackedData.push(Object.assign({ "category": element }, stackMap[element]))
      }
      return stackedData;
    }
  }
}