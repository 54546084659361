<div class="login-page background-awesome" layout="row" layout-align="center start">
  <div class="md-content" [ngClass]="{'layoutMobP': (responsiveUI.isMobile && responsiveUI.isPortrait), 'layoutMobL': (responsiveUI.isMobile && !responsiveUI.isPortrait),
    'layoutTabP': (responsiveUI.isTablet && responsiveUI.isPortrait), 'layoutTabL': (responsiveUI.isTablet && !responsiveUI.isPortrait), 'layoutDesktop': responsiveUI.isDesktop}">

    <div class="title">
      <h1>Forgot your password?</h1>
    </div>

    <div>
      <p>Please enter your username then click Submit.</p>
      <br/>
      <p>If you have forgotten your username, please contact your designated State Contact for support. </p>
    </div>

    <form name="loginForm" (ngSubmit)="submitUsernameForm()">

      <div class="custom-input-container" style="padding-top: 30px">
        <custom-mat-input 
          customlabel="Username"
          errorlength="{{errors.length}}"
          [(ngModel)]="submitUsername"
          name="userNameSearch"
          (ngsubmit)="submitUsernameForm()">
        </custom-mat-input>
      </div>

      <div class="center layout-flex-row" style="padding-top: 15px;">
        <button class="md-button" type="submit" [disabled]="!submitUsername">Submit</button>
      </div>

    </form>
  </div>
  <p class="version-number">{{appVersion}}</p>
</div>
  