<div class="saved-list-modals">
  <div tabindex="-1">
    <div class="md-toolbar-tools toolbar">
      <h2>Create New List</h2>
      <span flex="" class="flex"></span>
      <button tabindex="0" class="md-icon-button md-button" type="button" (click)="onClose()" (keypress)="onClose()" style="margin-left: auto; margin-right: 0;">
        <i class="fa fa-times ng-scope"></i>
      </button>
    </div>

    <!-- Window copy and input box for new Savelist name-->
    <form (ngSubmit)="createNewSaveList()">
      <div class="md-dialog-content">
        <div class="layout-row newListCopy">
          <p>Enter a name for the new list:</p>
        </div>
        <div class="center layout-row">
          <div class="custom-input-container" [ngClass]="{'error': newListName.length > 60 || checkCreateListName()}">
            <custom-mat-input ngMaxlength="60"
              customlabel="List Name" 
              [(ngModel)]="newListName" 
              name="newListName"
              [userform]="false">
            </custom-mat-input>
          </div>
        </div>
        <div class="create-error-message">
          <p *ngIf="newListName.length > 60">List names must be 60 characters or less.</p>
          <p *ngIf="checkCreateListName()">List name already exists. Enter a different name.</p>
        </div>
      </div>
  
      <div class="layout-row filter-row">
        <hr class="thinHr">
        <a tabindex="0" (click)="onClose()" (keypress)="onClose()" style="margin-top: 15px;">
          <span>Cancel</span>
        </a>
        <span flex class="flex"></span>
        <button class="md-button button right-align" type="submit"
          [disabled]="!newListName || listNameExists || newListName.length > 60">Submit</button>
      </div>
    </form>
  </div>
</div>