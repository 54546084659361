import { Component, OnInit } from '@angular/core';
import { ToastService } from '../../services/toast.service';
import * as angular from "angular";
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie';
import { UserService } from '../../services/user.service';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';

@Component({
  selector: 'app-select-job-titles',
  templateUrl: './selectJobTitles.component.html',
  styleUrls: ['./selectJobTitles.component.scss']
})
export class SelectJobTitlesComponent implements OnInit {
  jobsArray: Jobs[];
  jobOtherValue: string;
  private currUser: any;
  /* private stateParams: {

  } */

  constructor(private _cookieService: CookieService,
    private userService: UserService,
    private _Toast: ToastService,
    private _router: Router,
    public responsiveUI: ResponsiveUIService) {
  }

  ngOnInit(): void {

    //this.currUser = new Object();
    this.getCurrentUser();
  }

  getCurrentUser() {
    // Get current user data;
    this.currUser = this.userService.getCurrentUser().then( data => {
      this.currUser = data;
      console.debug(this.currUser);
    });
    this.getJobTitles();
  }

  jsonStringify(obj) {
    return angular.toJson(obj);
  }

  //Gets the list of the job titles to display and select
  getJobTitles() {
    this.jobsArray = new Array();

    let apiCall = this.userService.getJobTitles(this.currUser.username);
    let subscribed = apiCall.subscribe({
      next: data => {
        this.jobsArray = data;

        subscribed.unsubscribe();
      },
      error: error => {
        console.error('Error occurred in getJobTitles() when calling _User.getJobTitles API: ' + error);

        subscribed.unsubscribe();
      }
    });
  }

  addRemoveJob(job, idx){
    // Toggle job selection
    this.jobsArray[idx].selected = !this.jobsArray[idx].selected;
  }

  updateOtherJobTitle(job, i) {
    this.jobsArray[i].otherJobTitleDesc = this.jobOtherValue;
  }

  isAnyJobTitleSelected() {
    console.log('isAnyJObtitleSelected called');
    for (let item of this.jobsArray) {
      if (item.selected) {
        if (item.jobTitleDesc === 'Other') {
          if (item.otherJobTitleDesc.trim().length > 0) {
            return true;
          }
        } else {
          return true;
        }
      }      
    }

    return false;
  }

  updateUser() {
    let apiCall = this.userService.updateJobTitlesForUser(this.currUser.username, this.jobsArray);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.debug(data);
        this._Toast.showToast('User updated!');
        let userCookie:any = this._cookieService.getObject('user');
        userCookie.needsJobTitle = false;
        this._cookieService.putObject('user', userCookie);

        this._router.navigate(['home']);

        subscribed.unsubscribe();
      },
      error: error => {
        console.error('Error occurred in updateUser() when calling _User.updateJobTitlesForUser API: ' + error.error.developerMessage);
        this._Toast.showToast(error.error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }
}

export interface UserFormObj {
  jobTitles: Jobs[],
  username: string
};

export interface Jobs {
  jobTitleDesc: string,
  selected: boolean,
  otherJobTitleDesc: string
}