<div class="selectJobTitles-page">

  <div layout-margin class="mainDiv">
    <div class="mainHeader">
      <div class="flexSpaceBetween">
        <h2>Update Job Title</h2>
      </div>
    </div>
  </div>

  <!-- Sub Header Row Desktop Begin -->
  <div layout-margin layout-gt-xs="row" class="desktopStudentIds">
    <div class="block2">MSIX now requires Job Titles for all users. Select at least one of the updated Job Titles 
      from the checkboxes below. If Other is selected, ensure that the text field contains an entry.</div>
  </div>
  <!-- Sub Header Row Desktop End -->

  <div layout-margin class="userDetails">
    <form name="selectJobTitlesForm" (ngSubmit)="updateUser()" flex>

      <!-- Job Titles Section Begin -->
      <div class="regions">
        <div class="flex-inline">
          <p>Job Title</p><p class="required-asterisk">&nbsp;*</p>
        </div>
        <hr class="thinHr">
        <div layout="row" layout-wrap flex class="userRoles">
          <div flex="30" *ngFor="let job of jobsArray; let i = index">
            <mat-checkbox [checked]="job.selected" (click)="addRemoveJob(job, i)" name="job{{job.jobTitleDesc}}" *ngIf="job.jobTitleDesc !== 'Other'">
              <p>{{job.jobTitleDesc}}</p>
            </mat-checkbox>
            <div *ngIf="job.jobTitleDesc === 'Other'">
              <mat-checkbox [checked]="job.selected" (click)="addRemoveJob(job, i)" name="job{{job.jobTitleDesc}}">
                <p>{{job.jobTitleDesc}}</p>
              </mat-checkbox>
              <input type="text" style="margin-left: 30px; width: 50%" [(ngModel)]="jobOtherValue" (ngModelChange)="updateOtherJobTitle(job, i)" [disabled]="!job.selected" name="otherJobTitleInput"/>
            </div>
          </div>
        </div>
      </div>
      <!-- Job Titles Section End -->

      <!-- Section 3 Begin -->
      <div flex class="buttonSection">
        <div class="buttons" >
        <!--<div class="buttons" [ngClass]="{'float-right' : ($mdMedia('gt-xs'))}">-->
          <button type="submit" class="md-button confirmButton" [disabled]="!(isAnyJobTitleSelected())">
            SUBMIT
          </button>
        </div>
      </div>
      <!-- Section 3 End -->
    </form>
  </div>

</div>
