import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CookieService } from 'ngx-cookie';
import { AuthService } from 'src/app/services/auth.service';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/services/user.service';
import * as angular from "angular";
import { AppConstants } from 'src/app/config/index.constants';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormsModule, FormControl, FormGroup, Validators, Form, FormArray } from '@angular/forms';
import { FloatLabelType } from '@angular/material/form-field';
import { Observable } from 'rxjs';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ToastService } from 'src/app/services/toast.service';
import { ToastrService } from 'ngx-toastr';
import { Toast } from 'ngx-toastr';
import { Jobs } from '../../views/selectJobTitles/selectJobTitles.component';
import { ReportInfoService } from 'src/app/services/reportinfo.service';
import { clone, cloneDeep, isEqual } from "lodash";
import { equals } from 'angular';
import { State } from 'src/app/views/userDetails/userDetails.component';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';

@Component({
  selector: 'app-myaccount2',
  templateUrl: './myaccount2.component.html',
  styleUrls: ['./myaccount2.component.scss']
})
export class Myaccount2Component implements OnInit {

  router: string;
  username: string;
  jobsArray: any[];
  jobsArrayCopy: any[];
  districtsArray: any;
  states: State[];
  //selectedJobsList: string[];
  authRoleClasses: any;
  authFlags: any;
  adminState: any;
  formData: UserData;
  currUser: UserData;
  jobTitle: {
    other: string/* ,
    newOther: string */
  };
  userRoles = AppConstants.userRoles;
  rolesArray = AppConstants.userRoles.allUsers;
  rolesArrayWithSelection = [];
  rolesArrayWithSelectionCopy = [];
  //newUserRolesObj = {};
  authorizedRoleClasses = {};
  rolesList = [];
  stateRegions: any;
  disabledFlag: boolean;
  roleFlag: boolean;
  otherIsSelected: boolean;
  otherJobTitle: string = "";
  isMFAConfigured: string;
  userShowDistricts: boolean;
  isSuperUser: boolean;
  myAccountForm: FormGroup;
  isDialogOpen: boolean;
  showModal: boolean;

  prevQuestion1: number;
  prevQuestion2: number;
  prevQuestion3: number;
  prevQuestion4: number;
  prevQuestion5: number;

  question1Array: number[];
  question2Array: number[];
  question3Array: number[];
  question4Array: number[];
  question5Array: number[];

  errors = '';
  currKeysList = [];
  currResponsesList = [];

  receiveNotifications: any;
  allChallengeQuestions: string[];
  private allKeys: Set<number> = new Set<number>();
  private question1Set: Set<number>;
  private question2Set: Set<number>;
  private question3Set: Set<number>;
  private question4Set: Set<number>;
  private question5Set: Set<number>;
  jobTitlesPristine: boolean = true;

  /* response1FormControl: FormControl;
  response2FormControl: FormControl;
  response3FormControl: FormControl;
  response4FormControl: FormControl;
  response5FormControl: FormControl; */

  constructor(private authService: AuthService,
    private cookieService: CookieService,
    private stateService: StateService,
    private userService: UserService,
    private formBuilder: FormBuilder,
    private _router: Router,
    public dialog: MatDialog,
    private _Toast: ToastService,
    private toastService: ToastrService,
    private activatedRoute: ActivatedRoute,
    private reportInfoService: ReportInfoService,
    public responsiveUI: ResponsiveUIService) {
    this.router = _router.url;

    const routeParams = this.activatedRoute.snapshot.paramMap;
    this.username = (routeParams.get('username'));
    this.isDialogOpen = false;

    this.formData = {
      challengeQuestionsAndAnswers: [],
      question1: '',
      question2: '',
      question3: '',
      question4: '',
      question5: '',
      response1: '',
      response2: '',
      response3: '',
      response4: '',
      response5: '',
      firstName: '',
      lastName: '',
      email: '',
      userPhone: '',
      userPhoneExt: '',
      activeDate: '',
      expirationDate: '',
      addLine1: '',
      addLine2: '',
      addCity: '',
      addState: '',
      robDate: '',
      firstLogin: '',
      lastLogin: '',
      isMFA: false,
      username: '',
      showRegistrationDecision: true,
      isRegistrationWorkflow: false,
      mfaCode: '',
      resetPassword: false,
      needQRCode: false,
      verifyQRCode: false,
      disableUser: '',
      districts: [],
      districtNames: [],
      regions: [],
      regionNames: [],
      rolesStr: [],
      receiveNotification: 'YES',
      jobTitles: [],
      userState: '',
      userStateName: '',
      deactivated: '',
      mfaregistered: false,
      roles: null
    };

    this.currUser = {
      challengeQuestionsAndAnswers: [],
      question1: '',
      question2: '',
      question3: '',
      question4: '',
      question5: '',
      response1: '',
      response2: '',
      response3: '',
      response4: '',
      response5: '',
      firstName: '',
      lastName: '',
      email: '',
      userPhone: '',
      userPhoneExt: '',
      activeDate: '',
      expirationDate: '',
      addLine1: '',
      addLine2: '',
      addCity: '',
      addState: '',
      robDate: '',
      firstLogin: '',
      lastLogin: '', isMFA: false,
      username: '',
      showRegistrationDecision: true,
      isRegistrationWorkflow: false,
      mfaCode: '',
      resetPassword: false,
      needQRCode: false,
      verifyQRCode: false,
      disableUser: '',
      districts: [],
      districtNames: [],
      regions: [],
      regionNames: [],
      rolesStr: [],
      receiveNotification: '',
      jobTitles: [],
      userState: '',
      userStateName: '',
      deactivated: '',
      mfaregistered: false,
      roles: null
    };

    this.jobTitle = {
      other: ''/* ,
      newOther: '' */
    };
    this.jobsArray = [];
    this.jobsArrayCopy = [];
    this.districtsArray = [];
    this.disabledFlag = false;
    this.roleFlag = false;
    this.otherIsSelected = false;
    this.isMFAConfigured = 'No';
    this.userShowDistricts = false;
    this.isSuperUser = false;
    //this.adminState = null;
    this.rolesArrayWithSelection = new Array();
    this.rolesArrayWithSelectionCopy = new Array();

    this.allChallengeQuestions = Array<string>();
    this.question1Set = new Set();
    this.question2Set = new Set();
    this.question3Set = new Set();
    this.question4Set = new Set();
    this.question5Set = new Set();
  }

  ngOnInit(): void {
    this.myAccountForm = new FormGroup({
      firstName: new FormControl({ value: '', disabled: this.disabledFlag }, [Validators.required]),
      lastName: new FormControl({ value: '', disabled: this.disabledFlag }, [Validators.required]),
      userName: new FormControl({ value: '', disbaled: this.disabledFlag }, [Validators.required]),
      email: new FormControl({ value: '', disabled: this.disabledFlag }, [Validators.required, Validators.email]),
      userPhone: new FormControl({ value: '', disabled: this.disabledFlag }, [Validators.required, Validators.maxLength(11)]),
      userPhoneExt: new FormControl({ value: '', disabled: this.disabledFlag }),
      receiveNotification: new FormControl({ value: '', disabled: this.disabledFlag }),
      isPasswordReset: new FormControl({ value: false, disabled: this.disabledFlag }),
      cq1: new FormControl({ value: '', disabled: this.disabledFlag }),

      activeDate: new FormControl({ value: '', disabled: this.disabledFlag }),
      expirationDate: new FormControl({ value: '', disabled: this.disabledFlag }),

      addLine1: new FormControl({ value: '', disabled: this.disabledFlag }),
      addLine2: new FormControl({ value: '', disabled: this.disabledFlag }),
      addCity: new FormControl({ value: '', disabled: this.disabledFlag }),
      addState: new FormControl({ value: '', disabled: this.disabledFlag }),

      response1: new FormControl({ value: '', disabled: this.disabledFlag }, [Validators.required, Validators.maxLength(35)]),
      response2: new FormControl({ value: '', disabled: this.disabledFlag }, [Validators.required, Validators.maxLength(35)]),
      response3: new FormControl({ value: '', disabled: this.disabledFlag }, [Validators.required, Validators.maxLength(35)]),
      response4: new FormControl({ value: '', disabled: this.disabledFlag }, [Validators.required, Validators.maxLength(35)]),
      response5: new FormControl({ value: '', disabled: this.disabledFlag }, [Validators.required, Validators.maxLength(35)]),
      jobTitles: new FormArray([], Validators.required)/* ,
      rolesStr: new FormArray([], Validators.required) */
    });

    this.getCurrentUser();
    let apiCall = this.userService.getAllChallengeQuestions();
    let subscribed = apiCall.subscribe({
      // Callback for success
      next: data => {
        for (let challengeQuestion of data) {
          let cq: any = challengeQuestion;
          this.allChallengeQuestions[cq.key] = cq.question;
          this.allKeys.add(cq.key);
        }

        console.log("Allkeys: ", this.allKeys);

        //store the previously selected challenge question
        this.prevQuestion1 = this.formData.question1;
        this.prevQuestion2 = this.formData.question2;
        this.prevQuestion3 = this.formData.question3;
        this.prevQuestion4 = this.formData.question4;
        this.prevQuestion5 = this.formData.question5;

        this.question1Set = new Set(this.allKeys);
        this.question2Set = new Set(this.allKeys);
        this.question3Set = new Set(this.allKeys);
        this.question4Set = new Set(this.allKeys);
        this.question5Set = new Set(this.allKeys);

        this.question1Array = Array.from(this.question1Set).sort();
        this.question2Array = Array.from(this.question2Set).sort();
        this.question3Array = Array.from(this.question3Set).sort();
        this.question4Array = Array.from(this.question4Set).sort();
        this.question5Array = Array.from(this.question5Set).sort();

        subscribed.unsubscribe();
      },
      // Callback for failure
      error: error => {
        console.log('Error received while getting all challenge questions for the user.' + error);
        this._Toast.showToast(error.data.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });

  }

  myAccountFormError = (controlName: string, errorName: string) => {
    return this.myAccountForm.controls[controlName].hasError(errorName);
  }

  getCurrentUser() {
    console.log("Getting current user...");
    // Get the current user data and set the user's state on the form.
    this.userService.getCurrentUser().then(data => {

      let userData: any = data;
      console.log('getCurrentUser(): ', userData);
      this.formData.username = userData.username;
      this.formData.firstName = userData.firstName;
      this.formData.lastName = userData.lastName;
      this.formData.email = userData.email;
      this.formData.userPhone = userData.userPhone;
      this.formData.userPhoneExt = userData.userPhoneExt;
      this.formData.addLine1 = userData.addLine1;
      this.formData.addLine2 = userData.addLine2;
      this.formData.addCity = userData.addCity;
      this.formData.addState = userData.addState;
      this.formData.userStateName = userData.userStateName;
      this.formData.receiveNotification = userData.receiveNotification;
      if (userData.receiveNotification === '') {
        this.formData.receiveNotification = 'YES';
      } else {
        this.formData.receiveNotification = this.formData.receiveNotification;
      }
      this.formData.roles = userData.roles;
      this.formData.mfaregistered = userData.mfaregistered;
      console.log("Username", this.formData.username);

      this.setRolesList();
      this.setToOriginal();
      this.getJobTitles();
      console.log("Setting up CQs with this username", this.formData.username);
      this.setUpQuestionsAndAnswersForUser();

      if (this.formData.mfaregistered) {
        this.isMFAConfigured = 'Yes';
      } else {
        this.isMFAConfigured = 'No';
      }
      console.log("MFA: ", this.isMFAConfigured);
    });
  }

  private setRolesList() {
    for (let x = 1; x <= 13; x++) {
      if (this.formData.roles[x]) {
        // Roles exist so add it to the roles list
        this.rolesList.push(this.formData.roles[x]);
      }
    }
  }

  jsonStringify(obj) {
    return angular.toJson(obj);
  }

  cancel(ev) {
    this.showModal = true;
    let dialogRef = this.dialog.open(ConfirmCancelMyAccountUpdate, {
      height: 'auto',
      minHeight: '185px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false
    });

    dialogRef.afterClosed().subscribe(result => {
      this.showModal = false;
      console.log('The cancel update dialog was closed', result);
    });
    /* this.$mdDialog.show({
        templateUrl: 'confirmCancel.html',
        parent: angular.element(document.body),
        targetEvent: ev,
        clickOutsideToClose: true,
        scope: this.$scope,
        preserveScope: true,
        fullscreen: this.$scope.customFullscreen // Only for -xs, -sm breakpoints.
      })
      .then(function() {
        //success - not using because scope not recognized
      }, function() {
        //cancel - not using because scope not recognized
      }); */
  }

  updateOtherJobTitle(job) {
    job.otherJobTitleDesc = this.jobTitle.other;
    this.formData.jobTitles = this.jobsArray;
  }

  updateUser() {

    /* this.formData.response1 = this.response1FormControl.value;
    this.formData.response2 = this.response2FormControl.value;
    this.formData.response3 = this.response3FormControl.value;
    this.formData.response4 = this.response4FormControl.value;
    this.formData.response5 = this.response5FormControl.value; */

    // TODO: Use EventEmitter with form value
    // console.warn(this.myAccountForm.value);
    if ((this.jsonStringify(this.formData) === this.jsonStringify(this.currUser)) && this.formData.resetPassword) {
      this.resetPassword();
    } else {
      let formDataRoles = this.formData.roles;
      let rolesArray = Object.keys(formDataRoles).map(function (key) {
        return formDataRoles[key];
      });
      this.formData.challengeQuestionsAndAnswers = [
        {
          key: this.formData.question1,
          answer: this.formData.response1.trim()
        },
        {
          key: this.formData.question2,
          answer: this.formData.response2.trim()
        },
        {
          key: this.formData.question3,
          answer: this.formData.response3.trim()
        },
        {
          key: this.formData.question4,
          answer: this.formData.response4.trim()
        },
        {
          key: this.formData.question5,
          answer: this.formData.response5.trim()
        }
      ]

      this.formData.rolesStr = this.setRoles(rolesArray);

      this.formData.jobTitles = this.jobsArray;

      let apiCall = this.userService.updateUserSelf(this.formData);
      let subscribed = apiCall.subscribe({
        next: data => {
          console.log('data: ', data);
          if (this.formData.resetPassword === true) {
            this.toastService.show(
              ` <p>User updated! </p>
                <h4>OK</h4>`,
              '',
              {
                enableHtml: true,
                timeOut: 5000,
              });
            this.resetPassword();
          } else {
            this.currUser = cloneDeep(this.formData);
            this.userService.setCurrentUser(this.currUser);
            this.rolesArrayWithSelectionCopy = cloneDeep(this.rolesArrayWithSelection);
            this.roleFlag = false;
            this.toastService.show(
              ` <p>User updated! </p>
                <h4>OK</h4>`,
              '',
              {
                enableHtml: true,
                timeOut: 5000,
              });
          }
          subscribed.unsubscribe();
        },
        error: error => {
          console.log("Error updating the user from the userDetails component: ", error);
          if (error.error.codes !== '' && error.error.codes.length > 0) {
            var showToast = false;
            for (let err of error.error.codes) {
              if (err === 3000) {
                this.errors = 'You cannot set the same question for more than one challenge question on your account. Please select five different questions for your challenge questions.';
              } else {
                showToast = true;
              }
            }
            if (showToast && (this.errors === '' || this.errors.length < 1)) {
              this._Toast.showToast('There was an error saving your challenge questions. Please contact the MSIX Help Desk if you keep getting this error.', 0);
            }
          } else {
            this.setToOriginal();
            this._Toast.showToast('There was an error saving your challenge questions. Please contact the MSIX Help Desk if you keep getting this error.', 0);
          }
          this._Toast.showToast(error.data.developerMessage, 0);
          subscribed.unsubscribe();
        }
      });
    }

  }

  setRoles(roles) {
    let rolesEnumArray = [];
    for (let key in this.userRoles) {
      if (roles.indexOf(this.userRoles[key] !== -1)) {
        rolesEnumArray.push(key);
      }
    }
    return rolesEnumArray;
  }

  setToOriginal() {
    //Space in data defaults form state to active, remove to prevent active state from showing ]
    if (this.formData.firstName == " ") {
      this.formData.firstName = "";
    }
    if (this.formData.lastName == " ") {
      this.formData.lastName = "";
    }
    if (this.formData.email == " ") {
      this.formData.email = "";
    }
    if (this.formData.userPhone == " ") {
      this.formData.userPhone = "";
    }
    if (this.formData.addLine1 == " ") {
      this.formData.addLine1 = "";
    }
    if (this.formData.addLine2 == " ") {
      this.formData.addLine2 = "";
    }
    if (this.formData.addCity == " ") {
      this.formData.addCity = "";
    }
    if (this.formData.userStateName == " ") {
      this.formData.userStateName = "";
    }
  }

  //Gets the list of the job titles to display and select
  getJobTitles() {
    this.jobsArray = [];
    let apiCall = this.userService.getJobTitles(this.formData.username);
    let subscribed = apiCall.subscribe({
      next: data => {
        this.jobsArray = data;
        this.jobsArrayCopy = cloneDeep(this.jobsArray);
        for (let job of data) {
          if (job.jobTitleDesc === 'Other') {
            this.jobTitle.other = job.otherJobTitleDesc;
          }
        }
      },
      error: error => {
        console.log('Error getting job titles');
        subscribed.unsubscribe();
      }
    });
  }

  setUpQuestionsAndAnswersForUser() {

    console.log("Setting up CQs with this username", this.formData.username);
    let apiCall = this.userService.getAllCQsforUser(this.formData.username);
    let subscribed = apiCall.subscribe({
      // Callback for success
      next: res => {

        let d: any = res;

        if (d.isChallengeQuestionSet) {
          for (var challengeQuestion of d.challengeQuestions) {
            this.currKeysList.push(challengeQuestion.key);
            this.currResponsesList.push(challengeQuestion.answer);
          }
          this.formData.question1 = this.currKeysList[0];
          this.formData.question2 = this.currKeysList[1];
          this.formData.question3 = this.currKeysList[2];
          this.formData.question4 = this.currKeysList[3];
          this.formData.question5 = this.currKeysList[4];
          this.formData.response1 = this.currResponsesList[0];
          this.formData.response2 = this.currResponsesList[1];
          this.formData.response3 = this.currResponsesList[2];
          this.formData.response4 = this.currResponsesList[3];
          this.formData.response5 = this.currResponsesList[4];
          /* this.response1FormControl = new FormControl(this.formData.response1, [Validators.required, Validators.maxLength(35)])
          this.response2FormControl = new FormControl(this.formData.response2, [Validators.required, Validators.maxLength(35)]);
          this.response3FormControl = new FormControl(this.formData.response3, [Validators.required, Validators.maxLength(35)]);
          this.response4FormControl = new FormControl(this.formData.response4, [Validators.required, Validators.maxLength(35)]);
          this.response5FormControl = new FormControl(this.formData.response5, [Validators.required, Validators.maxLength(35)]); */

          //store the previously selected challenge question
          this.prevQuestion1 = this.formData.question1;
          this.prevQuestion2 = this.formData.question2;
          this.prevQuestion3 = this.formData.question3;
          this.prevQuestion4 = this.formData.question4;
          this.prevQuestion5 = this.formData.question5;

          this.removeSelectedQuestion(1, this.formData.question1);
          this.removeSelectedQuestion(2, this.formData.question2);
          this.removeSelectedQuestion(3, this.formData.question3);
          this.removeSelectedQuestion(4, this.formData.question4);
          this.removeSelectedQuestion(5, this.formData.question5);
        } //else: questions and responses are already reset

        subscribed.unsubscribe();
      },
      // Callback for failure
      error: error => {
        console.log('Error receiving all challenge questions and answers for the user.' + error);
        this._Toast.showToast(error.data.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }

  //Brings the user to the login page and steps used to setup MFA
  setupMFA() {
    this.cookieService.putObject("resetupmfa", { resetupmfa: true });
    this._router.navigate(['/login']);
  }

  addAndRemoveSelectedQuestion(arrNumber, key) {
    this.addDeselectedQuestion(arrNumber);
    this.removeSelectedQuestion(arrNumber, key);
    this.clearAnswer(arrNumber);
  }

  addDeselectedQuestion(arrNumber) {
    if (1 === arrNumber && this.prevQuestion1 > 0) {
      this.question2Set.add(this.prevQuestion1);
      this.question3Set.add(this.prevQuestion1);
      this.question4Set.add(this.prevQuestion1);
      this.question5Set.add(this.prevQuestion1);
    } else if (2 === arrNumber && this.prevQuestion2 > 0) {
      this.question1Set.add(this.prevQuestion2);
      this.question3Set.add(this.prevQuestion2);
      this.question4Set.add(this.prevQuestion2);
      this.question5Set.add(this.prevQuestion2);
    } else if (3 === arrNumber && this.prevQuestion3 > 0) {
      this.question1Set.add(this.prevQuestion3);
      this.question2Set.add(this.prevQuestion3);
      this.question4Set.add(this.prevQuestion3);
      this.question5Set.add(this.prevQuestion3);
    } else if (4 === arrNumber && this.prevQuestion4 > 0) {
      this.question1Set.add(this.prevQuestion4);
      this.question2Set.add(this.prevQuestion4);
      this.question3Set.add(this.prevQuestion4);
      this.question5Set.add(this.prevQuestion4);
    } else if (5 === arrNumber && this.prevQuestion5 > 0) {
      this.question1Set.add(this.prevQuestion5);
      this.question2Set.add(this.prevQuestion5);
      this.question3Set.add(this.prevQuestion5);
      this.question4Set.add(this.prevQuestion5);
    }
    this.question1Array = Array.from(this.question1Set).sort();
    this.question2Array = Array.from(this.question2Set).sort();
    this.question3Array = Array.from(this.question3Set).sort();
    this.question4Array = Array.from(this.question4Set).sort();
    this.question5Array = Array.from(this.question5Set).sort();
  }

  removeSelectedQuestion(arrNumber, key) {
    if (1 === arrNumber) {
      this.prevQuestion1 = key;
      this.question2Set.delete(key);
      this.question3Set.delete(key);
      this.question4Set.delete(key);
      this.question5Set.delete(key);
    } else if (2 === arrNumber) {
      this.prevQuestion2 = key;
      this.question1Set.delete(key);
      this.question3Set.delete(key);
      this.question4Set.delete(key);
      this.question5Set.delete(key);
    } else if (3 === arrNumber) {
      this.prevQuestion3 = key;
      this.question1Set.delete(key);
      this.question2Set.delete(key);
      this.question4Set.delete(key);
      this.question5Set.delete(key);
    } else if (4 === arrNumber) {
      this.prevQuestion4 = key;
      this.question1Set.delete(key);
      this.question2Set.delete(key);
      this.question3Set.delete(key);
      this.question5Set.delete(key);
    } else if (5 === arrNumber) {
      this.prevQuestion5 = key;
      this.question1Set.delete(key);
      this.question2Set.delete(key);
      this.question3Set.delete(key);
      this.question4Set.delete(key);
    }
    this.question1Array = Array.from(this.question1Set).sort();
    this.question2Array = Array.from(this.question2Set).sort();
    this.question3Array = Array.from(this.question3Set).sort();
    this.question4Array = Array.from(this.question4Set).sort();
    this.question5Array = Array.from(this.question5Set).sort();
  }

  clearAnswer(arrNumber) {
    if (arrNumber === 1) {
      this.formData.response1 = '';
      //this.response1FormControl.setValue('');
    } else if (arrNumber === 2) {
      this.formData.response2 = '';
      //this.response2FormControl.setValue('');
    } else if (arrNumber === 3) {
      this.formData.response3 = '';
      //this.response3FormControl.setValue('');
    } else if (arrNumber === 4) {
      this.formData.response4 = '';
      //this.response4FormControl.setValue('');
    } else if (arrNumber === 5) {
      this.formData.response5 = '';
      //this.response5FormControl.setValue('');
    }
  }

  setNotifs(value) {
    this.formData.receiveNotification = value;
  }

  resetPassword() {
    this.formData.resetPassword = !this.formData.resetPassword;
  }

  // This function is called when the job title selection has been updated.
  addRemoveJob(event, job) {
    if (job.jobTitleDesc === 'Other') {
      this.otherIsSelected = event.checked;

      if (!this.otherIsSelected) {
        //this.jobTitle.other = '';
        this.otherJobTitle = '';
        job.otherJobTitleDesc = '';
      }
    }
    job.selected = event.checked;
    let idx = 0;
    while (idx < this.jobsArray.length) {
      if (this.jobsArray[idx] == job) {
        this.jobsArray[idx].selected = event.checked;
      }
      idx++;
    }
    this.jobTitlesPristine = false;
  }

  // This function checks if the job title has been selected. If selected, then it verifies if the list has been modified. Returns 'true' if not modified.
  isJobTitleSelectionValid() {
    var foundValidJobTitleSelected = false;

    for (let item of this.jobsArray) {
      if (item.selected) {
        if (item.jobTitleDesc === 'Other') {
          if (this.jobTitle.other.trim().length <= 0) {
            return false;
          } else {
            foundValidJobTitleSelected = true;
          }
        } else {
          foundValidJobTitleSelected = true;
        }
      }
    }

    return foundValidJobTitleSelected;
  }


  // Helper function to verify if job title has been selected. In case of 'Other' job title, it also verifies if 'Other' job title text is not empty.
  private isJobTitleSelected() {
    let isJobSelected = false;
    let isOtherSelectionValid = true;
    let jobsArraySize = this.jobsArray.length;
    let idx = 0;

    while (idx < jobsArraySize) {
      if (this.jobsArray[idx].selected == true) {
        if (this.jobsArray[idx].jobTitleDesc == 'Other') {
          if (this.jobsArray[idx].otherJobTitleDesc.trim() !== '') {
            isJobSelected = true;
          } else {
            isOtherSelectionValid = false;
          }
        } else {
          isJobSelected = true;
        }
      }

      idx++;
    }

    return (isJobSelected && isOtherSelectionValid);
  }
}

@Component({
  selector: 'confirmCancelMyAccountUpdate',
  templateUrl: './confirmCancelMyAccountUpdate.html',
  styleUrls: ['./myaccount2.component.scss']
})
export class ConfirmCancelMyAccountUpdate {

  constructor(public dialogRef: MatDialogRef<ConfirmCancelMyAccountUpdate>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _router: Router) {

  }

  closeDialog() {
    this.dialogRef.close();
  }

  cancelGoBack() {
    this.dialogRef.close();
    this._router.navigate(['/home']);
  }
}

interface UserData {
  challengeQuestionsAndAnswers: any[];
  question1: any;
  question2: any;
  question3: any;
  question4: any;
  question5: any;
  response1: any;
  response2: any;
  response3: any;
  response4: any;
  response5: any;
  firstName: string,
  lastName: string,
  email: string,
  userPhone: string,
  userPhoneExt: string,
  activeDate: string,
  expirationDate: string,
  robDate: string,
  firstLogin: string,
  lastLogin: string,
  addLine1: string,
  addLine2: string,
  addCity: string,
  addState: string,
  isMFA: boolean,
  username: string,
  showRegistrationDecision: boolean,
  isRegistrationWorkflow: boolean,
  mfaCode: string,
  resetPassword: boolean,
  needQRCode: boolean,
  verifyQRCode: boolean,
  disableUser: string,
  districts: string[],
  districtNames: any,
  regions: string[],
  regionNames: any,
  rolesStr: string[],
  receiveNotification: any,
  jobTitles: string[],
  userState: string,
  userStateName: any,
  deactivated: string,
  mfaregistered: boolean,
  roles: any
}
