import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subscription } from 'rxjs';


@Component({
    selector: 'app-custom-grade-level-control',
    templateUrl: './custom-grade-level-control.component.html',
    styleUrls: ['./custom-grade-level-control.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            multi: true,
            useExisting: CustomGradeLevelControlComponent
        }
]
})

export class CustomGradeLevelControlComponent implements OnInit, ControlValueAccessor, OnDestroy{
    private _form: FormGroup = new FormGroup({});
    onTouched: () => {};
    onChangeSub: Subscription;
    
    get form() {
        return this._form;
    }

    @Input() reportelement: string = "Assessments";

    gradeLevels = [
        // col 1
        {label: "P Values", value: "2,17,18,19,20,21,22,23"},
        {label: "Grade 2", value: "6"},
        {label: "Grade 5", value: "9"},
        {label: "Grade 8", value: "12"},
        {label: "Grade 11", value: "15"},
        {label: "Out-of-School", value: "4"},

        // col 2
        {label: "Kindergarten", value: "3"},
        {label: "Grade 3", value: "7"},
        {label: "Grade 6", value: "10"},
        {label: "Grade 9", value: "13"},
        {label: "Grade 12", value: "16"},
        {label: "Invalid/Not Submitted", value: "0,-1"},

        // col 3
        {label: "Grade 1", value: "5"},
        {label: "Grade 4", value: "8"},
        {label: "Grade 7", value: "11"},
        {label: "Grade 10", value: "14"},
        {label: "Ungraded", value: "1"}
        ]

    constructor() {
        
    }

    ngOnInit(): void {
      //Setting last item based on report
      this.gradeLevels[this.gradeLevels.length] = 
      { 
        label: this.reportelement + " Not Associated with Enrollment/Grade Level", value: '-100'
      }; // if selected, on submit, it is ignored

      this.gradeLevels.forEach(type => this.form.addControl(type.value, new FormControl(type.value)));
      this.form.get('-100').setValue('');
    }

    selectAll() {
        this.gradeLevels.forEach(type => this.form.get(type.value).setValue(type.value))
      }
    
    selectNone() {
        this.gradeLevels.forEach(type => this.form.get(type.value).reset())
    }

    writeValue(value: any): void {
        if (value) {
            this.form.setValue(value)
        }
    }

    registerOnChange(onChange: any): void {
        this.onChangeSub = this.form.valueChanges.subscribe(onChange)
    }

    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }

    setDisabledState?(isDisabled: boolean): void {
       if (isDisabled) {
        this.form.disable();
       } else {
        this.form.enable();
       }
    }

    reset() {
        this.selectAll();
        let value = this.gradeLevels[this.gradeLevels.length-1].value;
        this.form.get(value).setValue('');
    }
    
    ngOnDestroy(): void {
        this.onChangeSub?.unsubscribe();
    }
}