import { Injectable } from '@angular/core';
import { HttpClient} from '@angular/common/http';
import { Observable} from 'rxjs';
import { AppConstants }  from '../config/index.constants';
import { Router } from '@angular/router'; 

@Injectable({
  providedIn: 'root'
})
export class SavedListService {
  private apiUrl = AppConstants.api;
  router: string;
  current: {};
  constructor(
    private http: HttpClient,
    private _router: Router) {
      this.router = _router.url;
    }

  getSavelist(): Observable<ArrayBuffer[]> {
    let apiCall = this.http.get<ArrayBuffer[]>(this.apiUrl + 'savelist');
    let subscribed = apiCall.subscribe(data => this.current = data);
    subscribed.unsubscribe();
    return apiCall;
  }

  // API call to save a student records to a list of the user's Saved Lists
  updateStudentToSavedLists(savelistObject): Observable<ArrayBuffer[]> {
    return this.http.put<ArrayBuffer[]>(this.apiUrl + 'savelist/updateStudentOnSaveList', savelistObject);
  }

  removeStudentFromSavelist(savelistObject): Observable<ArrayBuffer[]> {
    return this.http.put<ArrayBuffer[]>(this.apiUrl + 'savelist/remove', savelistObject);
  }

  // API call to send a new list name to the backend for a new saved list to be created
  createNewSaveList(newListName): Observable<ArrayBuffer[]> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'savelist/saveNewList?listName=' + newListName, '');
  }

  // API call to get a list of the user's saved lists names
  getSaveListsNames(): Observable<ArrayBuffer[]> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'savelist/getListsNames');
  }

  // API call to get a list of the user's saved lists names
  getSaveListsForMsixId(msixId): Observable<ArrayBuffer[]> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'savelist/getListsForMsixId?msixId=' + msixId);
  }

  // API call to edit the name of a user's saved list
  editSavedList(savedListKey, listName): Observable<ArrayBuffer[]> {
    return this.http.put<ArrayBuffer[]>(this.apiUrl + 'savelist/editList?savedListKey=' + savedListKey + '&listName=' + listName, '');
  }

  // API call to delete a user's saved list
  deleteSavedList(savedListKey): Observable<ArrayBuffer[]> {
    return this.http.delete<ArrayBuffer[]>(this.apiUrl + 'savelist/deleteList?savedListKey=' + savedListKey);
  }

  exportSavedListFile(params): Observable<any> {
    return this.http.get(this.apiUrl + 'savelist/exportSavedList?savedListKey=' + params.saveListKey,  { observe: 'response', responseType: 'blob' as 'text' });
  }

  //API call to sort the saved lists by column header
  sortSavedList(savedList): Observable<ArrayBuffer[]> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'savelist/sortSavedList', savedList);
  }

  //API call to send a multi-move notice
  sendMultiMoveNotice(multiMoveNotification): Observable<any> {
    return this.http.put(this.apiUrl + 'student/multimovenotice', multiMoveNotification,  { observe: 'response', responseType: 'text' });
  }

  //Shares a user's saved list with another user
  shareMySavedList(shareSaveList): Observable<ArrayBuffer[]> {
    return this.http.post<ArrayBuffer[]>(this.apiUrl + 'savelist/shareMySavedLists', shareSaveList);
  }

  //Gets a list of shared lists that have been shared from other users to this user
  getSharedLists(): Observable<ArrayBuffer[]> {
    return this.http.get<ArrayBuffer[]>(this.apiUrl + 'savelist/getSharedWithMeList');
  }

  acceptDeleteSharedList(list): Observable<ArrayBuffer[]> {
    return this.http.put<ArrayBuffer[]>(this.apiUrl + 'savelist/acceptDeleteSharedList', list);
  }
}
