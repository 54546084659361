import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { AppConstants }  from '../config/index.constants';
import { EncoderService } from '../services/encoder.service';
import * as angular from 'angular';
import { CookieService } from 'ngx-cookie';
import { Router } from '@angular/router'; 
import { TemplateBindingParseResult, TmplAstBoundAttribute } from '@angular/compiler';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  private apiUrl = AppConstants.api;
  router: string;
  userObj: {
    roles: string,
    name: string,
    username: string,
    privacyFlag: boolean,
    robFlag: boolean,
    isOTP: boolean,
    betaUserFlag: boolean,
    passwordExpirationWarn: boolean
  };
  USER_CREDS_SESSION_ATTRIBUTE_NAME = 'usercreds';

  constructor(
    private http: HttpClient,
    private encoderService: EncoderService,
    private cookieService: CookieService,
    private _router: Router,
    private _toastService: ToastService) {
      this.router = _router.url;
      this.userObj = {
        roles: '',
        name: '',
        username: '',
        privacyFlag: false,
        robFlag: false,
        isOTP: false,
        betaUserFlag: false,
        passwordExpirationWarn: false};
    }

  login(credentials): Observable<any> {
    let params = {
      'isMFA': credentials.isMFA,
      'mfaCode': credentials.mfaCode,
      'showRegistrationDecision': credentials.showRegistrationDecision,
      'isRegistrationWorkflow': credentials.isRegistrationWorkflow,
      'needQRCode': credentials.needQRCode,
      'verifyQRCode': credentials.verifyQRCode
    };
    let apiCall = this.http.get<ArrayBuffer[]>(this.apiUrl + 'user', {params});

    return apiCall;
  }

  logout(msg = ''): Observable<ArrayBuffer[]> {
    let apiCall = this.http.post<any>(this.apiUrl + 'logout', {});
    let subscribed = apiCall.subscribe({
      next: data => {
          console.log('Logout Success');
          this.removeCookiesLogout(msg);
          subscribed.unsubscribe();
      },
      error: error => {
          console.error('There was an error!', error);
          this.removeCookiesLogout(msg);
          subscribed.unsubscribe();
      }
    });
    return apiCall;
  }

  removeCookiesLogout(msg) {
    console.log("this.router: ", this.router);
    console.log("this._router.url: ",this._router.url);
    if (this._router.url !== '/') {
      this.cookieService.removeAll();
      console.log("inside the if statement");
      
      // Do a hard reload of current state to ensure all data is flushed
      this._router.navigate(['/'])
        .then(() => {
          window.location.reload();
        });
      console.log("after the _router.navigate and reload");
      
      if (msg !== '') {
        this._toastService.showToast('You have been logged out.' + ' ' + msg);
      } else {
        this._toastService.showToast('You have been logged out.');
      }
      window.localStorage.clear();
      sessionStorage.removeItem(this.USER_CREDS_SESSION_ATTRIBUTE_NAME);
    }
  }

}
