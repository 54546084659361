import { Component, EventEmitter, Inject, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { cloneDeep, isEqual } from 'lodash';
import { Toast } from 'ngx-toastr';
import { forkJoin } from 'rxjs';
import { StateService } from 'src/app/services/state.service';
import { ToastService } from 'src/app/services/toast.service';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'dataAdmin',
  templateUrl: './dataAdmin.component.html',
  styleUrls: ['./dataAdmin.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DataAdminComponent implements OnInit {
  panelMargin = new FormControl('NoRegion');

  states: any;
  private stateRegionAdmin: boolean;
  private showStateIndex: number = -1;
  private showRegionIndex: number = -1;
  private showMembershipIndex: number = -1;
  private currentStateKey: number;
  private currentState: any;
  private regionDistricts: {
    originalRegion: string,
    districtID: number,
    currentRegionKey: number,
    currentRegion: string,
    districtName: string,
    stateID: number
  }[] = [];
  private formData: {
    name: string,
    description: string,
    active: string,
    regionCurrentKey: any
    stateCurrentKey: any
  };
  private regionCurrentKey: number;
  private originalForm: {
    name: string,
    description: string,
    active: string
  };
  private addedRegionName: string = "";
  private addedRegionDesc: string = "";
  private addedRegionStatus: string;
  disableUpdateButton: boolean = true;
  switch: {
    rda: boolean,
    rua: boolean,
    regionsEnabled: boolean
  };
  shouldDisplayStateList: Array<boolean>;
  addRegionParameters: any = {};
  defaultRegion: any;
  defaultRegionName: any;

  constructor(private _Toast: ToastService,
    private dialog: MatDialog,
    private _State: StateService,
    public responsiveUI: ResponsiveUIService) {

  }

  ngOnInit(): void {
    this.showStateIndex = -1;
    this.getStates();

  }

  getStates() {
    let apiCall = this._State.getStateRegionalAdmin();
    let subscribed = apiCall.subscribe({
      next: data => {
        this.states = data;
        this.defaultRegion = Object.values(this.states[0].regionMap)[0];
        this.defaultRegionName = this.defaultRegion.name;
        if (data.length === 1) {
          this.stateRegionAdmin = true;
        }

        this.setShouldDisplayForEachState();

        subscribed.unsubscribe();
      },
      error: error => {
        console.error(error);

        subscribed.unsubscribe();
      }
    });
  }

  setShouldDisplayForEachState() {
    if (!this.shouldDisplayStateList || this.shouldDisplayStateList.length <= 0) {
      // Set the array
      this.shouldDisplayStateList = new Array<boolean>(this.states.length);
    }

    for (let counter = 0; counter < this.shouldDisplayStateList.length; counter++) {
      this.shouldDisplayStateList[counter] = (this.showStateIndex == counter);
    }
  }

  //Show state accordion
  showStateFn(indx, currentKey, currentState) {

    // Set the current state index to show/not show any
    if (this.showStateIndex === indx) {
      this.showStateIndex = -1;

      //Clear flags before opening next accordion
    } else {
      this.showStateIndex = indx;
    }

    // Update the State list boolean tracker if the state section should be shown
    for (let counter = 0; counter < this.shouldDisplayStateList.length; counter++) {
      if (counter == indx) {
        // Set the state to show or not show depending on if user is opening of closing a state
        this.shouldDisplayStateList[counter] = (this.showStateIndex != -1);
      } else {
        // Set other states to false i.e. close
        this.shouldDisplayStateList[counter] = false;
      }
    }
    this.currentStateKey = currentKey;
    this.currentState = currentState;
    this.getStateInfo(this.currentStateKey);
  }

  //Show region accordion
  showRegionFn(indx) {
    if (this.showRegionIndex === indx) {
      this.showRegionIndex = -1;
    } else {
      this.showRegionIndex = indx;
    }
  }

  regionSelected(districtIndex, district, region, region2) {
    this.showMembershipIndex = districtIndex;
    this.regionDistricts[districtIndex] = {
      districtID: district.key,
      districtName: district.name,
      stateID: district.stateId,
      currentRegion: region2.value.name,
      originalRegion: region.name,
      currentRegionKey: region2.key
    }
  }

  //Find changes in districts object and send them to API
  submitUpdatedDistrict() {
    this.showRegionIndex = -1;
    let districtsList = [];
    for (var district in this.regionDistricts) {
      if (this.regionDistricts[district].originalRegion != this.regionDistricts[district].currentRegion) {
        let updatedDistrict = {
          ncesId: this.regionDistricts[district].districtID,
          regionKey: this.regionDistricts[district].currentRegionKey
        };
        districtsList.push(updatedDistrict);
      }
    }

    let apiCall = this._State.updateDistrict(districtsList);
    let subscribed = apiCall.subscribe({
      next: data => {
        this._Toast.showToast('Region was updated');
        this.getStates();

        subscribed.unsubscribe();
      },
      error: error => {
        console.error(error);
        this._Toast.showToast(error.data.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });

    this.regionDistricts = [];
  }

  disableDistrictUpdate() {
    if (!this.regionDistricts || isEqual(this.regionDistricts, {})) {
      return true;
    }
    return false;
  }

  //Open and populate the edit region modal
  editRegion(ev, regionName, regionDesc, regionKey, status) {
    this.formData = {
      name: regionName,
      description: regionDesc,
      active: status,
      regionCurrentKey: regionKey,
      stateCurrentKey: this.currentStateKey
    }
    this.regionCurrentKey = regionKey;

    //Used for enabling/disabling submit button (this object needs to be different form the edited data or you made no changes)
    this.originalForm = cloneDeep(this.formData);

    const dialogRef = this.dialog.open(EditRegion, {
      height: 'auto',
      width: '40%',
      minHeight: '410px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: { params: this.formData }
    });

    // This function will be called when the user submits reconciliation filter.
    dialogRef.componentInstance.updateStatesInfo.subscribe(() => {
      this.getStates();
    });
  }

  //Open the add region modal
  addRegion(ev) {
    this.addRegionParameters.addedRegionName = JSON.parse(JSON.stringify(this.addedRegionName));
    this.addRegionParameters.addedRegionDesc = JSON.parse(JSON.stringify(this.addedRegionDesc));
    const dialogRef = this.dialog.open(AddRegion, {
      height: 'auto',
      width: '40%',
      minHeight: '410px',
      maxHeight: 'fit-content',
      maxWidth: '95vw',
      hasBackdrop: true,
      disableClose: false,
      data: { params: this.addRegionParameters }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.addRegionParameters = result;
        this.addedRegionName = JSON.parse(JSON.stringify(result.addedRegionName));
        this.addedRegionDesc = JSON.parse(JSON.stringify(result.addedRegionDesc));
      }
    });
    // This function will be called when the user submits reconciliation filter.
    dialogRef.componentInstance.updateStatesInfo.subscribe(() => {
      this.getStates();
    });
  }

  //Send the flag states to the API
  submitFlags() {
    this.disableUpdateButton = true;

    this.setRDADistrictResidenceRUA().finally(() => {
      this.getStates();
      this._Toast.showToast('Settings Updated');
    });
  }

  // This function calls 3 APIs synchronously as thye are all independent calls.
  // Call APIs to set the regional district admin, regional user admin, and district of residence.
  async setRDADistrictResidenceRUA() {
    let forkJoinSubscribed = await forkJoin(
      [
        this._State.setDataAdmin(this.currentState, this.switch.rda.toString()),
        this._State.setUserAdmin(this.currentState, this.switch.rua.toString()),
        this._State.setResidence(this.currentState, this.switch.regionsEnabled.toString())
      ]
    ).subscribe({
      next: data => {
        console.log('forkJoin data sates: ' + data[0]);
        console.log('forkJoin data current RP: ' + data[1]);
        console.log('forkJoin data recently closed RP: ' + data[2]);

        forkJoinSubscribed.unsubscribe();
      },
      error: error => {
        console.error('Error occured in forkJoin call in submitFlags() for dataAdmin componenent: ' + error);
        forkJoinSubscribed.unsubscribe();
      }
    });
  }

  //set flags
  getStateInfo(stateKey) {
    this.switch = {
      rda: false,
      rua: false,
      regionsEnabled: false
    };

    for (var i = 0; i < 52; i++) {
      if (this.states[i].stateKey == stateKey) {
        if (this.states[i].regionsEnabled) {
          this.switch.regionsEnabled = true;
        } else {
          this.switch.regionsEnabled = false;
        }

        if (this.states[i].rua == "Enabled") {
          this.switch.rua = true;
        } else {
          this.switch.rua = false;
        }

        if (this.states[i].rda == "Enabled") {
          this.switch.rda = true;
        } else {
          this.switch.rda = false;
        }

        break;
      }
    }
  }

  disableUpdateSettings() {
    this.disableUpdateButton = false;
  }

}

@Component({
  selector: 'data-admin-edit-region',
  templateUrl: 'editRegion.html',
  styleUrls: ['./dataAdmin.component.scss']
})
export class EditRegion implements OnInit {

  originalForm: {};
  formData: {
    name: string,
    description: string,
    active: boolean,
    regionCurrentKey: any,
    stateCurrentKey: any
  };
  private regionCurrentKey: number;
  private currentStateKey: number;


  @Output() updateStatesInfo = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<EditRegion>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _Toast: ToastService,
    private _State: StateService) {
    this.formData = data.params;
  }

  ngOnInit(): void {

  }

  closeDialog() {
    this.dialogRef.close();
  }

  jsonStringify(obj) {
    return JSON.stringify(obj);
  }

  //Submit the changes made after editing the region in the modal
  submitEditedRegion() {
    let updatedRegion = {
      name: this.formData.name,
      description: this.formData.description,
      key: this.formData.regionCurrentKey,
      stateKey: this.formData.stateCurrentKey,
      active: this.formData.active
    };

    let apiCall = this._State.updateRegion(updatedRegion);
    let subscribed = apiCall.subscribe({
      next: data => {
        this._Toast.showToast('Region was updated');
        console.debug(data);
        this.updateStatesInfoWithEdits();

        subscribed.unsubscribe();
      },
      error: error => {
        console.error(error);
        this._Toast.showToast(error.error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
    this.closeDialog();
  }

  updateStatesInfoWithEdits() {
    // Parent (i.e. State/Regions (Data Admin)) has subscribed to this output event so it will be triggered with the selected filter parameters object.
    this.updateStatesInfo.emit();
  }
}

@Component({
  selector: 'data-admin-add-region',
  templateUrl: 'addRegion.html',
  styleUrls: ['./dataAdmin.component.scss']
})
export class AddRegion implements OnInit {

  addRegionParameters: {
    addedRegionName: '';
    addedRegionDesc: '';
  };
  private addedRegionStatus: boolean;
  private currentStateKey: number;

  @Output() updateStatesInfo = new EventEmitter();

  constructor(public dialogRef: MatDialogRef<AddRegion>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private _Toast: ToastService,
    private _State: StateService) {
    this.addRegionParameters = data.params;
  }

  ngOnInit(): void {

  }

  closeDialog() {
    this.dialogRef.close();
  }

  //Submit the new region after initializing in the modal
  submitAddedRegion() {
    let newRegion = {
      name: this.addRegionParameters.addedRegionName,
      description: this.addRegionParameters.addedRegionDesc,
      active: this.addedRegionStatus,
      stateKey: this.currentStateKey
    };

    let apiCall = this._State.createRegion(newRegion);
    let subscribed = apiCall.subscribe({
      next: data => {
        this._Toast.showToast('Region Added!');
        console.debug(data);
        //this.closeDialog();
        this.updateStatesInfoWithAddition();

        subscribed.unsubscribe();
      },
      error: error => {
        console.error(error);
        //this.closeDialog();
        this._Toast.showToast(error.error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
    this.closeDialog();
  }

  updateStatesInfoWithAddition() {
    // Parent (i.e. State/Regions (Data Admin)) has subscribed to this output event so it will be triggered with the selected filter parameters object.
    this.updateStatesInfo.emit();
  }
}
