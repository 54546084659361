import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { CookieService } from 'ngx-cookie';
import { ReportsService } from 'src/app/services/reports.service';
import { ReportInfoService } from 'src/app/services/reportinfo.service';
import { AuthService } from 'src/app/services/auth.service';
import { ToastService } from 'src/app/services/toast.service';
import { SavedListService } from 'src/app/services/savedlist.service';
import { SearchService } from 'src/app/services/search.service';
import { UserService } from 'src/app/services/user.service';
import { StateService } from 'src/app/services/state.service';
import { WorklistService } from 'src/app/services/worklist.service';
import { Router } from '@angular/router';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { cloneDeep } from 'lodash';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { BehaviorSubject, Observable, combineLatest, debounceTime, map, startWith } from 'rxjs';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'savedList',
  templateUrl: './savedList.component.html',
  styleUrls: ['./savedList.component.scss']
})
export class SavedListComponent {
  savelists: any[] = [];
  savedListsNames: any[] = [];
  checkedLists: any[] = [];
  uncheckedLists: any[] = [];
  newCheckedLists: any[] = [];
  newListIsChecked: boolean = false;
  deleteListisChecked: boolean = false;
  deleteShareListCheckbox: boolean = false;
  listNameExists: boolean = false;
  filterParameters: any = {};
  checkedMoveNotice: any[] = [];
  uncheckedMoveNotice: any[] = [];
  states: any[] = [];
  showSharedWithMe: boolean = false;
  isMySavedList: boolean = true;
  originialShareUserList: any[] = [];
  sharedLists: any[] = [];
  newListName: string = '';
  shareListName: string = '';
  savelistObject: any = {};
  msixId: number = 0;
  currentMultiMoveList: string = "";
  shareInfo: any = {
    mySavedListsArray: [],
    multiSelectMySavedListsData: [],
    shareUserList: [],
    multiSelectSelectedUsers: [],
    comments: ''
  }
  editDeleteListKey: any = {};
  editListName: string = '';
  deleteListName: string = '';
  deleteShareListName: string = '';
  editListNameExists: boolean = false;
  shareListNameExists: boolean = false;
  submitDisabled: boolean = true;
  corObject: any = {};
  selectedState: any = {};
  selectedDistrict: any = {};
  selectedSchool: any = {};
  districts: any[] = [];
  schools: any[] = [];
  corType: string = '';
  mainPage: string = '';
  indicator: boolean = false;
  stateKey: any = {};
  errors: any = {};
  authRoleClasses: any = {};
  shareListKey: any = {};
  sharedByUser: any = {};
  acceptedOrDeletedList: any = {};
  exportCall: string = "exportSavedListFile";
  CORFilterParameters: any = {};
  constructor(
    public dialog: MatDialog,
    private cookieService: CookieService,
    private reportsService: ReportsService,
    private reportInfoService: ReportInfoService,
    private _router: Router,
    private authService: AuthService,
    private toastService: ToastService,
    private savedListService: SavedListService,
    private searchService: SearchService,
    private userService: UserService,
    private stateService: StateService,
    private worklistService: WorklistService,
    public responsiveUI: ResponsiveUIService) { }

  ngOnInit() {
    this.checkRoleClasses();
    this.getSavedList();
    this.getSavedListsNames();
    this.getStateList();
    this.getShareListUsers();
    this.getSharedLists();
  }


  getSavedList() {
    let apiCall = this.savedListService.getSavelist();
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("SavedList.getSavedList: ", data);
        this.savelists = data;
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.getSavedList: ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  //Retrieves a list of the names of a user's saved lists
  getSavedListsNames() {
    let apiCall = this.savedListService.getSaveListsNames();
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("SavedList.getSavedListsNames: ", data);
        this.savedListsNames = data;
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.getSavedListsNames: ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  async getSaveListsforMsixId(msixId) {
    let apiCall = await this.savedListService.getSaveListsForMsixId(msixId);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("SavedList.getSaveListsForMsixId: ", data);
        this.checkedLists = data;
        this.openSaveEditDialog(msixId);
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.getSaveListsForMsixId: ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  async checkRoleClasses() {
    await this.authService.isRoleClassAuthorized(['multiMoveNotice']).then(
      (res) => {
        this.authRoleClasses = res;
      }
    );
  }

  // may change once comps are finalized
  async getStateList() {
    this.states = await this.stateService.getStates();
  }

  viewDetails(student) {
    let userActivity = {
      msixID: student.msixId,
      pageName: 'APP_STU_VW_CONS',
      activityNotes: '',
      sessionID: ''
    }
    this.searchService.logUserActivity(userActivity);
    this._router.navigate(['studentDetails'], {
      queryParams: {
        msixId: student.msixId,
        name: student.stuName
      }
    });
  }

  // Share My List Dialog
  shareMyList() {
    this.shareInfo.mySavedListsArray = cloneDeep(this.savelists);
    this.shareInfo.shareUserList = cloneDeep(this.originialShareUserList);

    // Remove 'My Default List' as an option to be able to share the list.
    this.shareInfo.mySavedListsArray = this.shareInfo.mySavedListsArray.filter(
      listItem => (listItem.saveListName !== 'My Default List' && listItem.listStudents.length > 0));

    this.shareInfo.multiSelectMySavedListsData = [];
    this.shareInfo.multiSelectSelectedUsers = [];

    const dialogRef = this.dialog.open(ShareMyList, {
      height: 'auto',
      width: '650px',
      minHeight: 'fit-content',
      maxHeight: '550px',
      hasBackdrop: true,
      disableClose: false,
      data: {
        params: this.shareInfo
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("List has been shared.");
    });
  }

  //Retrieves a list of users and their details to share a list with
  getShareListUsers() {
    var userDisplayString = '';
    let apiCall = this.userService.getActiveLockedExpiredUsers();
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("SavedList.getActiveLockedExpiredUsers: ", data);
        for (let user of data) {
          let loggedInUsername = this.cookieService.get('username');
          if (loggedInUsername.toUpperCase() != user.username.toUpperCase()) {
            userDisplayString = user.firstName + " " + user.lastName + " [" + user.userState + "; " + user.email + "; " + user.username + "]";
            user.userDisplayString = userDisplayString;
            this.originialShareUserList.push(user);
          }
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.getActiveLockedExpiredUsers: ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  //Opens Create New List popup window
  openCreateListDialog() {
    //Object to pass info needed to the CreateNewList dialog
    const dialogRef = this.dialog.open(CreateNewList, {
      height: 'auto',
      width: this.responsiveUI.isMobile ? '65%' : '550px',
      minHeight: '250px',
      maxHeight: 'fit-content',
      hasBackdrop: true,
      disableClose: false,
      data: {
        params: this.savedListsNames
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getSavedList();
      this.getSavedListsNames();
    });
  }

  openSaveEditDialog(msixId) {
    //await this.getSaveListsforMsixId(msixId);
    this.msixId = msixId;

    //Object to pass info needed to the SaveEditList dialog
    let savedEditListObj = {
      checkedListsTracker: {
        checkedLists: this.checkedLists,
        newCheckedLists: this.newCheckedLists,
        uncheckedLists: this.uncheckedLists,
        newListName: ''
      },
      savedListsNames: this.savedListsNames,
      studentMsixId: msixId
    };
    const dialogRef = this.dialog.open(SaveEditList, {
      height: 'auto',
      width: '650px',
      minHeight: '250px',
      maxHeight: 'fit-content',
      hasBackdrop: true,
      disableClose: false,
      data: {
        params: savedEditListObj
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getSavedList();
      this.getSavedListsNames();
    });
  }

  openEditDeleteListDialog(saveListName, saveListKey) {
    //Object to pass info needed to the EditDeleteList dialog
    let editDeleteListObj = {
      editListName: saveListName,
      deleteListName: saveListName,
      editDeleteListKey: saveListKey,
      savedListsNames: this.savedListsNames
    }
    const dialogRef = this.dialog.open(EditDeleteList, {
      height: 'auto',
      width: '550px',
      minHeight: '250px',
      maxHeight: 'fit-content',
      hasBackdrop: true,
      disableClose: false,
      data: {
        params: editDeleteListObj
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getSavedList();
      this.getSavedListsNames();
    });
  }

  sortColumn(columnName, saveList) {
    if (saveList.sortColumn == columnName) {
      saveList.sortAscending = !saveList.sortAscending;
    } else {
      saveList.sortAscending = true;
    }
    saveList.sortColumn = columnName;

    let apiCall = this.savedListService.sortSavedList(saveList);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("SavedList.sortSavedList: ", data);
        for (let list of this.savelists) {
          if (list.saveListKey == saveList.saveListKey) {
            let index = this.savelists.indexOf(list);
            this.savelists[index] = data;
            this.savelists[index].showList = true;
          }
        }
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.sortSavedList: ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  openMultiMoveNoticeDialog() {
    let corDialogObj: any = {};
    if (this.checkedMoveNotice.length > 0) {
      corDialogObj = {
        corObject: {
          comments: '',
          isLeaving: true,
          district: {},
          school: {},
          state: {}
        },
        selectedState: {
          stateIndex: ''
        },
        selectedDistrict: {
          districtIndex: ''
        },
        selectedSchool: {
          schoolIndex: ''
        },
        states: this.states,
        districts: [],
        schools: [],
        corType: 'moveNotice',
        mainPage: "savedList",
        checkedMoveNotice: this.checkedMoveNotice
      };

      const dialogRef = this.dialog.open(CorDialog, {
        height: 'auto',
        minHeight: '325px',
        maxHeight: 'fit-content',
        width: '750px',
        hasBackdrop: true,
        disableClose: false,
        data: { params: corDialogObj },
      });
      
      dialogRef.afterClosed().subscribe(result => { });
    }
  }

  //Functionality reduced to only remove the student from saved lists while in mobile view
  removeStudentInMobile(msixId, listId) {
    this.savelistObject = {
      msixID: msixId,
      savedListKeys: [],
      unsavedListKeys: [listId],
      newListName: this.newListName
    }
    let apiCall = this.savedListService.updateStudentToSavedLists(this.savelistObject);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("SavedList.removeStudentInMobile(): ", data);
        this.getSavedList();
        this.checkedLists = [];
        this.uncheckedLists = [];
        this.newCheckedLists = [];
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.removeStudentInMobile(): ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  //Removes list in mobile view
  deleteListInMobile(saveListKey) {
    let apiCall = this.savedListService.deleteSavedList(saveListKey);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("SavedList.deleteListInMobile(): ", data);
        this.getSavedList();
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.deleteListInMobile(): ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  //
  //Retrieves a list of the lists that have been shared from other users
  getSharedLists() {
    let apiCall = this.savedListService.getSharedLists();
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("SavedList.getSharedLists(): ", data);
        this.sharedLists = data;
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.getSharedLists(): ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  //Opens the modal to accept or delete a shared list
  openAcceptDeleteListDialog(saveListName, saveListKey, sharedByUser) {
    this.getSavedListsNames();

    //Object to pass info needed to the AcceptDeleteSharedList dialog
    let acceptDeleteSharedListObj = {
      shareListName: saveListName,
      deleteShareListName: saveListName,
      shareListKey: saveListKey,
      sharedByUser: sharedByUser,
      savedListsNames: this.savedListsNames
    }
    const dialogRef = this.dialog.open(AcceptDeleteSharedList, {
      height: 'auto',
      width: '550px',
      minHeight: '250px',
      maxHeight: 'fit-content',
      hasBackdrop: true,
      disableClose: false,
      data: {
        params: acceptDeleteSharedListObj
      },
    });

    dialogRef.afterClosed().subscribe(result => {
      this.getSavedList();
      this.getSharedLists();
    });
  }

  deleteSharedListFromMobile(shareListName, shareListKey, sharedByUser) {
    let acceptedOrDeletedList = {
      sharedSaveListName: shareListName,
      sharedSaveListKey: shareListKey,
      sharedByUser: sharedByUser,
      toDelete: true
    }

    let apiCall = this.savedListService.acceptDeleteSharedList(acceptedOrDeletedList);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("SavedList.acceptDeleteSharedListAPICall(): ", data);
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.acceptDeleteSharedListAPICall(): ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
  }

  //Displays the Send Multi-Move Notice button and checkboxes if items 
  //from another saved list have not already been selected
  checkCurrentMultiMoveList(saveListKey) {
    if (this.currentMultiMoveList == '') {
      return true;
    }
    else if (this.currentMultiMoveList === saveListKey) {
      return true;
    } else {
      return false;
    }
  }

  selectAllforMoveNotice(saveListKey, listStudents) {
    for (let student of listStudents) {
      let index = this.checkedMoveNotice.indexOf(student.msixId);
      if (index === -1) {
        this.checkedMoveNotice.push(student.msixId);
      }
    }

    if (!this.currentMultiMoveList) {
      this.currentMultiMoveList = saveListKey;
    }
  }

  selectNoneforMoveNotice(saveListKey) {
    if (this.currentMultiMoveList === saveListKey) {
      this.checkedMoveNotice = [];
      this.currentMultiMoveList = "";
    }
  }

  checkedMoveNoticeStudents(saveListKey, msixId) {
    this.currentMultiMoveList = saveListKey;
    let checkedIndex = this.checkedMoveNotice.indexOf(msixId);
    if (checkedIndex === -1) {
      this.checkedMoveNotice.push(msixId);
    } else {
      this.checkedMoveNotice.splice(checkedIndex, 1);
    }

    if (this.checkedMoveNotice.length == 0) {
      this.currentMultiMoveList = "";
    }
  }

  existsInMoveNotice(msixId) {
    let index = this.checkedMoveNotice.indexOf(msixId);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

  createExportObj(key) {
    let exportList: any = {
      saveListKey: key
    };
    return exportList;
  }
}

// Separate component for the createNewList dialog
@Component({
  selector: 'createNewList',
  templateUrl: './createNewList.html',
  styleUrls: ['./savedList.component.scss']
})
export class CreateNewList {
  savedListsNames: any[] = [];
  //step: number = 1;
  newListName: string = '';
  listNameExists: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<CreateNewList>,
    private savedListService: SavedListService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.savedListsNames = cloneDeep(data.params);
  }

  onClose() {
    this.dialogRef.close();
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  // checks the input box on the Create New List page to see if it matches the name of another list
  checkCreateListName() {
    for (let list of this.savedListsNames) {
      if (this.newListName && list.saveListName.toUpperCase() === this.newListName.trim().toUpperCase()) {
        this.listNameExists = true;
        return true;
      }
    }
    this.listNameExists = false;
    return false;
  }

  // Calls service api to send new list name to the backend to be created
  createNewSaveList() {
    let apiCall = this.savedListService.createNewSaveList(this.newListName);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("SavedList.createNewSaveList: ", data);
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.createNewSaveList: ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });

    this.dialogRef.close();
  }
}

// Separate component for the ShareMyList dialog
@Component({
  selector: 'shareMyList',
  templateUrl: './shareMyList.html',
  styleUrls: ['./savedList.component.scss']
})
export class ShareMyList {
  shareInfo: any = {
    mySavedListsArray: [],
    multiSelectMySavedListsData: [],
    shareUserList: [],
    multiSelectSelectedUsers: []
  };
  comments: string = '';
  step: number = 1;
  savedListsSearchText;
  shareUsersSearchText: string = '';
  private onChange: (value: any[]) => void = () => { };
  private onTouched: () => void = () => { };
  form: FormGroup;
  searchControl = new FormControl();
  filteredCollection$: Observable<any>;
  collection: any[] = [];
  collectionChangeSource = new BehaviorSubject<any[]>([]);
  selected = new Set<any>();
  listItems = [];
  userItems = [];
  constructor(
    public dialogRef: MatDialogRef<ShareMyList>,
    private savedListService: SavedListService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.shareInfo = cloneDeep(data.params);
    this.listItems = [...this.shareInfo.mySavedListsArray];
    this.userItems = [...this.shareInfo.shareUserList];
  }

  onListSearchChange(value: string) {
    this.listItems = this.shareInfo.mySavedListsArray.filter((item: {saveListName: string, savedListKey: number}) => item.saveListName.toLowerCase().includes(value.toLowerCase().trim()));
  }

  onUserSearchChange(value: string) {
    this.userItems = this.shareInfo.shareUserList.filter((item: {userDisplayString: string, userKey: number}) => item.userDisplayString.toLowerCase().includes(value.toLowerCase().trim()));
  }

  selectList(option: MatAutocompleteSelectedEvent) {
    const item = this.shareInfo.mySavedListsArray.find(item => item.saveListKey === option.option.value);
    this.shareInfo.multiSelectMySavedListsData.push(item);
    this.savedListsSearchText = '';
  }

  // Remove selected list to share with user(s).
  removeList(item) {
    // Add it back to the display list
    //this.shareInfo.mySavedListsArray.push(item);

    // Remove from multiselect list
    let index = this.shareInfo.multiSelectMySavedListsData.indexOf(item);
    this.shareInfo.multiSelectMySavedListsData.splice(index, 1);
  }

  selectUser(option: MatAutocompleteSelectedEvent) {
    const item = this.shareInfo.shareUserList.find(item => item.userKey === option.option.value);
    this.shareInfo.multiSelectSelectedUsers.push(item);
    this.shareUsersSearchText = '';
  }

  // Remove selected user to share the list with
  removeUser(selectionToRemove) {
    // Add it back to the display list
    //this.shareInfo.shareUserList.push(selectionToRemove);

    // Remove from multiselect list
    let index = this.shareInfo.multiSelectSelectedUsers.indexOf(selectionToRemove);
    this.shareInfo.multiSelectSelectedUsers.splice(index, 1);
  }

  checkListsForItem(item) {
    return this.shareInfo.multiSelectMySavedListsData.some(e => e.saveListName == item.saveListName);
  }

  checkUsersForItem(item) {
    return this.shareInfo.multiSelectSelectedUsers.some(e => e.userKey == item.userKey);
  }

  trackByListName(_, item: any) {
    return item.saveListName;
  }

  trackByUserDisplayString(_, item: any) {
    return item.userDisplayString;
  }

  onSelectAll() {
    switch (this.step) {
      case 1: {
        for (const item of this.shareInfo.mySavedListsArray) {
          if (!this.shareInfo.multiSelectMySavedListsData.includes(item)) {
            this.shareInfo.multiSelectMySavedListsData.push(item);
          }
        }
        break;
      }
      case 2: {
        for (const item of this.shareInfo.shareUserList) {
          if (!this.shareInfo.multiSelectSelectedUsers.includes(item)) {
            this.shareInfo.multiSelectSelectedUsers.push(item);
          }
        }
        break;
      }
    }
  }

  onSelectNone() {
    switch (this.step) {
      case 1: {
        this.shareInfo.multiSelectMySavedListsData = [];
        this.shareInfo.mySavedListsArray = this.data.params.mySavedListsArray;
        break;
      }
      case 2: {
        this.shareInfo.multiSelectSelectedUsers = [];
        this.shareInfo.shareUserList = this.data.params.shareUserList;
        break;
      }
    }
  }

  //Moves forward a step on the modal
  shareMyListNext() {
    if (this.step == 1) {
      this.step = 2;
    }
    else if (this.step == 2) {
      this.step = 3;
    }
    else if (this.step == 3) {
      // Create the object to pass in the API call based on the selection made by the user
      let shareSaveList = {
        saveLists: this.shareInfo.multiSelectMySavedListsData,
        users: this.shareInfo.multiSelectSelectedUsers,
        comments: this.comments
      };

      let apiCall = this.savedListService.shareMySavedList(shareSaveList);
      let subscribed = apiCall.subscribe({
        next: data => {
          console.log("SavedList.shareMySavedList: ", data);

          // Clear comments
          this.shareInfo.comments = '';
          this.toastService.showToast('Your List(s) have been shared successfully.');
          subscribed.unsubscribe();
        },
        error: error => {
          console.log("Error - SavedList.shareMySavedList: ", error);

          // Clear comments
          this.shareInfo.comments = '';
          this.toastService.showToast(error.data.developerMessage, 0);
          subscribed.unsubscribe();
        }
      });

      this.dialogRef.close(this.shareInfo);
    }
  }

  //Moves back a step on the modal
  shareMyListBack() {
    switch (this.step) {
      case 2: {
        this.step = 1;
        break;
      }
      case 3: {
        this.step = 2;
        break;
      }
    }
  }

  onClose() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

// Separate component for the accepting or deleting a shared list
@Component({
  selector: 'acceptDeleteSharedList',
  templateUrl: './acceptDeleteSharedList.html',
  styleUrls: ['./savedList.component.scss']
})
export class AcceptDeleteSharedList {
  acceptDeleteSharedListObj: any = {
    shareListName: '',
    deleteShareListName: '',
    savedListsNames: [],
    shareListNameExists: false,
    shareListKey: 0,
    sharedByUser: {}
  };
  deleteShareListCheckbox: boolean = false;
  constructor(
    public dialogRef: MatDialogRef<AcceptDeleteSharedList>,
    private savedListService: SavedListService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.acceptDeleteSharedListObj = cloneDeep(data.params);
  }

  // checks the input box on the Share/Delete List modal to see if it matches the name of another list
  checkShareListName() {
    for (let list of this.acceptDeleteSharedListObj.savedListsNames) {
      if (this.acceptDeleteSharedListObj.shareListName.toUpperCase() === list.saveListName.toUpperCase()) {
        this.acceptDeleteSharedListObj.shareListNameExists = true;
        return true;
      }
    }
    this.acceptDeleteSharedListObj.shareListNameExists = false;
    return false;
  }


  //toggles the checkbox to delete a shared list and display a message
  toggleShareDelete() {
    this.deleteShareListCheckbox = !this.deleteShareListCheckbox;
    if (this.deleteShareListCheckbox) {
      this.acceptDeleteSharedListObj.shareListName = this.acceptDeleteSharedListObj.deleteShareListName;
    }
  }

  // This function is the main call from desktop view to accept or delete the shared list
  acceptDeleteSharedList() {
    let acceptedOrDeletedList: any = {
      sharedSaveListName: this.acceptDeleteSharedListObj.shareListName,
      sharedSaveListKey: this.acceptDeleteSharedListObj.shareListKey,
      sharedByUser: this.acceptDeleteSharedListObj.sharedByUser,
      toDelete: this.deleteShareListCheckbox
    }

    let apiCall = this.savedListService.acceptDeleteSharedList(acceptedOrDeletedList);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("SavedList.acceptDeleteSharedListAPICall(): ", data);
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.acceptDeleteSharedListAPICall(): ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });

    this.dialogRef.close();
  }

  onClose() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}

// Separate component for the saveEditList dialog
@Component({
  selector: 'saveEditList',
  templateUrl: '../student-details/student-details-dialog.html',
  styleUrls: ['./savedList.component.scss']
})
export class SaveEditList {
  savedListsNames: any[] = [];
  isSaved: boolean = true;
  newListIsChecked: boolean = false;
  listNameExists: boolean = false;
  checkedListsTracker: any = {};
  msixId: number = 0;

  constructor(
    public dialogRef: MatDialogRef<SaveEditList>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private savedlistService: SavedListService,
    private toastService: ToastService,
    public responsiveUI: ResponsiveUIService) {
    this.checkedListsTracker = cloneDeep(data.params.checkedListsTracker);
    this.savedListsNames = this.data.params.savedListsNames;
    this.msixId = data.params.studentMsixId;
  }

  // creates a new list and adds the student to that list
  checkNewList() {
    if (!this.newListIsChecked) {
      this.checkedListsTracker.newListName = '';
    }
  }

  // sets the ng-checked to true or false based on if savedListKey exists in the checkedLists
  existsInCheckedLists(saveListKey) {
    let index = this.checkedListsTracker.checkedLists.indexOf(saveListKey);
    if (index > -1) {
      return true;
    } else {
      return false;
    }
  }

  checkCreateListName() {
    for (let list of this.savedListsNames) {
      if (this.checkedListsTracker.newListName && list.saveListName.toUpperCase() === this.checkedListsTracker.newListName.trim().toUpperCase()) {
        this.listNameExists = true;
        return true;
      }
    }
    this.listNameExists = false;
    return false;
  }

  // adds and removes a saved student from the selected saved lists
  setCheckedLists(saveListKey) {
    let checkedIndex = this.checkedListsTracker.checkedLists.indexOf(saveListKey);
    let uncheckedIndex = this.checkedListsTracker.uncheckedLists.indexOf(saveListKey);
    let newCheckedIndex = this.checkedListsTracker.newCheckedLists.indexOf(saveListKey);
    if (checkedIndex === -1) {
      this.checkedListsTracker.checkedLists.push(saveListKey);
      this.checkedListsTracker.newCheckedLists.push(saveListKey);
      if (uncheckedIndex > -1) {
        this.checkedListsTracker.uncheckedLists.splice(uncheckedIndex, 1);
      }
    } else {
      this.checkedListsTracker.checkedLists.splice(checkedIndex, 1);
      if (newCheckedIndex > -1) {
        this.checkedListsTracker.newCheckedLists.splice(newCheckedIndex, 1);
      }
      this.checkedListsTracker.uncheckedLists.push(saveListKey);
    }
  }

  //checks the checkbox to create a new list if the user enters a new list name
  selectCreateListCheckbox() {
    if (this.checkedListsTracker.newListName.length > 0) {
      this.newListIsChecked = true;
    } else {
      this.newListIsChecked = false;
    }
  }

  //Add student to all of the saved lists
  selectAll() {
    for (let list of this.savedListsNames) {
      let checkedIndex = this.checkedListsTracker.checkedLists.indexOf(list.saveListKey);
      if (checkedIndex === -1) {
        this.checkedListsTracker.checkedLists.push(list.saveListKey);
        this.checkedListsTracker.newCheckedLists.push(list.saveListKey);
      }
    }
    this.checkedListsTracker.uncheckedLists = [];
  }

  //Remove student from all lists
  selectNone() {
    for (let key of this.checkedListsTracker.checkedLists) {
      this.checkedListsTracker.uncheckedLists.push(key);
    }
    this.checkedListsTracker.checkedLists = [];
    this.checkedListsTracker.newCheckedLists = [];
    this.newListIsChecked = false;
  }

  onClose() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    let savelistObject = {
      msixID: this.msixId,
      savedListKeys: this.checkedListsTracker.newCheckedLists,
      unsavedListKeys: this.checkedListsTracker.uncheckedLists,
      newListName: this.checkedListsTracker.newListName
    }

    let apiCall = this.savedlistService.updateStudentToSavedLists(savelistObject);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log(data);

        this.checkedListsTracker.uncheckedLists = [];
        this.checkedListsTracker.newCheckedLists = [];
        subscribed.unsubscribe();
      },
      error: error => {
        console.log(error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
    this.dialogRef.close();
  }
}

// Separate component for the EditDeleteList dialog
@Component({
  selector: 'editDeleteList',
  templateUrl: './editDeleteList.html',
  styleUrls: ['./savedList.component.scss']
})
export class EditDeleteList {
  savedListsNames: any[] = [];
  editListName: string = '';
  deleteListName: string = '';
  deleteListisChecked: boolean = false;
  editListNameExists: boolean = false;
  editDeleteListKey: any = {};
  constructor(
    public dialogRef: MatDialogRef<EditDeleteList>,
    private savedListService: SavedListService,
    private toastService: ToastService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.savedListsNames = this.data.params.savedListsNames;
    this.editListName = this.data.params.editListName;
    this.deleteListName = this.data.params.editListName;
    this.editDeleteListKey = this.data.params.editDeleteListKey;
  }

  onClose() {
    this.dialogRef.close();
  }


  onNoClick(): void {
    this.dialogRef.close();
  }

  // checks the input box on the Edit/Delete List page to see if it matches the name of another list
  checkEditListName() {
    for (let list of this.savedListsNames) {
      if (this.editListName.toUpperCase() !== this.deleteListName.toUpperCase() && list.saveListName.toUpperCase() === this.editListName.trim().toUpperCase()) {
        this.editListNameExists = true;
        return true;
      }
    }
    this.editListNameExists = false;
    return false;
  }

  toggleDelete() {
    this.deleteListisChecked = !this.deleteListisChecked;
    if (this.deleteListisChecked) {
      this.editListName = this.deleteListName;
    }
  }

  editDeleteList() {
    if (this.deleteListisChecked) {
      let apiCall = this.savedListService.deleteSavedList(this.editDeleteListKey);
      let subscribed = apiCall.subscribe({
        next: data => {
          console.log("SavedList.deleteSavedList: ", data);
          subscribed.unsubscribe();
        },
        error: error => {
          console.log("Error - SavedList.deleteSavedList: ", error);
          this.toastService.showToast(error.data.developerMessage, 0);
          subscribed.unsubscribe();
        }
      });
    } else {
      let apiCall = this.savedListService.editSavedList(this.editDeleteListKey, this.editListName);
      let subscribed = apiCall.subscribe({
        next: data => {
          console.log("SavedList.editSavedList: ", data);
          subscribed.unsubscribe();
        },
        error: error => {
          console.log("Error - SavedList.editSavedList: ", error);
          this.toastService.showToast(error.data.developerMessage, 0);
          subscribed.unsubscribe();
        }
      });
    }

    this.dialogRef.close();
  }
}

// Separate component for the COR dialog
@Component({
  selector: 'corDialog',
  templateUrl: '../student-details/corDialog.html',
  styleUrls: ['./savedList.component.scss']
})
export class CorDialog {
  filterParameters: any;
  CORForm = this.formBuilder.group({
    stateIndex: null,
    districtIndex: null,
    schoolIndex: null,
    moveNotice: null,
    multiMoveNotice: null,
    comments: null
  });
  
  indicator: boolean = false;
  textValue: string = '';
  submitDisabled: boolean = true;
  selectedDistrict: any;
  selectedSchool: any;
  corObject: any;
  selectedState: any;
  stateKey: any[] = [];
  states: any[] = [];
  districts: any[] = [];
  errors: any;
  currentMultiMoveList: string = "";
  constructor(
    public dialogRef: MatDialogRef<CorDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    public responsiveUI: ResponsiveUIService,
    private stateService: StateService,
    private toastService: ToastService,
    private savedListService: SavedListService) {
    this.filterParameters = cloneDeep(this.data.params);
    this.filterParameters.moveNotice = true;
  }

  getDistricts() {
    this.submitDisabled = false;
    this.filterParameters.selectedSchool.schoolIndex = null;
    this.filterParameters.corObject.district = null;
    this.filterParameters.corObject.school = null;

    if (!this.filterParameters.selectedState.stateIndex) {
      this.filterParameters.selectedState.stateIndex = 0;
    }

    this.stateKey = this.filterParameters.states[this.filterParameters.selectedState.stateIndex].stateKey;
    this.filterParameters.corObject.state = this.filterParameters.states[this.filterParameters.selectedState.stateIndex];
    if (this.stateKey) {
      let apiCall = this.stateService.getStateDistricts2(this.stateKey);
      // Callback for success
      let subscribed = apiCall.subscribe({
        next: data => {
          this.filterParameters.districts = data;
          subscribed.unsubscribe();
        },
        // Callback for failure
        error: err => {
          console.log(err);
          this.toastService.showToast(err.developerMessage, 0);
          this.errors = err.data.errors;
          subscribed.unsubscribe();
        }
      });
    } else {
    }
  }

  getSchoolsList() {
    this.filterParameters.selectedSchool.schoolIndex = null;
    this.filterParameters.corObject.district = this.filterParameters.districts[this.filterParameters.selectedDistrict.districtIndex];
    this.filterParameters.corObject.school = null;


    if (this.filterParameters.corObject.district) {
      let apiCall = this.stateService.getDistrictSchools(this.stateKey, this.filterParameters.corObject.district.key);
      // Callback for success
      let subscribed = apiCall.subscribe({
        next: data => {
          console.log(data);
          this.filterParameters.schools = data;
          subscribed.unsubscribe();
        },
        // Callback for failure
        error: err => {
          console.log(err);
          this.toastService.showToast(err.developerMessage, 0);
          this.errors = err.data.errors;
          subscribed.unsubscribe();
        }
      });
    } else {
    }
  }

  onSubmit() {
    this.filterParameters.corObject.comments = this.textValue;
    this.filterParameters.corObject.school = this.filterParameters.selectedSchool;
    this.filterParameters.corObject.isLeaving = this.filterParameters.moveNotice;
    let multiMoveNotification = {
      msixIDs: this.filterParameters.checkedMoveNotice,
      moveNotification: this.filterParameters.corObject
    }
    
    let apiCall = this.savedListService.sendMultiMoveNotice(multiMoveNotification);
    let subscribed = apiCall.subscribe({
      next: data => {
        console.log("SavedList.sendMultiMoveNotice: ", data);
        this.filterParameters.checkedMoveNotice = [];
        this.currentMultiMoveList = "";
        this.toastService.showToast('Your multi-move notification has been sent');
        subscribed.unsubscribe();
      },
      error: error => {
        console.log("Error - SavedList.sendMultiMoveNotice: ", error);
        this.toastService.showToast(error.data.developerMessage, 0);
        subscribed.unsubscribe();
      }
    });
    this.dialogRef.close();
  }

  closeDialog() {
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}