<div class="pwReset-page background-awesome" style="display: flex; flex-direction: row" layout-align="center start">
  <!-- TODO - Need to figure out flex stuff for different size screens-->
  <!-- <div class="md-content pwReset-container-max-height" flex-xs="95" flex-sm="50" flex-md="35" flex-lg="30" flex-xl="15"> -->
  <div class="md-content pwReset-container-max-height" 
    [ngClass]="{'pwRstBxDsktp': responsiveUI.isDesktop, 'pwRstBxTbltPrtrt': (responsiveUI.isTablet && responsiveUI.isPortrait), 'pwRstBxTbltLndscp': (responsiveUI.isTablet && !responsiveUI.isPortrait), 'pwRstBxMblPrtrt': (responsiveUI.isMobile && responsiveUI.isPortrait), 'pwRstBxMblLndscp': (responsiveUI.isMobile && !responsiveUI.isPortrait)}" >

    <h2 class="left-right-padding">{{header}}</h2>
    <br>
    <p class="p1 left-right-padding" *ngIf="header === 'Resetting Your Password'">For a password reset, please search for, and contact your designated MSIX State Contact.</p>
    <p class="p1 left-right-padding" *ngIf="header !== 'Resetting Your Password'">Search for your designated MSIX State Contact.</p>

    <div style="display: flex; flex-direction: column; padding-left: 8px;" *ngIf="states && states.length > 0" class="pwResetForm">
      <form name="pwResetForm" (ngSubmit)="goToAdminSearchResults()">
        <mat-form-field class="md-block"
          style="display: flex;" placeholder="State">
          <mat-label style="font-family: 'Work Sans'; padding-bottom: 10px; padding-left: 10px" 
          class="custom-label select-label">State</mat-label>
          <mat-select [(ngModel)]="selectedState" (selectionChange)="getDistrictList()" 
            name="stateSelect" [ngClass]="{'mat-input-has-value': stateSelected}">
            <mat-option style="font-family: 'Work Sans';margin-left: 7px" *ngFor="let state of states" [value]="state">
              {{state.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
        
        <mat-form-field *ngIf="selectedState && districts && districts.length > 0" class="md-block" 
          style="display: flex;" placeholder="District">
          <mat-label style="font-family: 'Work Sans'; padding-left: 10px; padding-bottom: 10px; margin-top: 10px;" 
          class="custom-label select-label">District</mat-label>
          <mat-select [(ngModel)]="selectedDistrict" name="districtSelect" (selectionChange)="selectDistrictFromList()"
          [ngClass]="{'mat-input-has-value': districtSelected}">
            <mat-option style="font-family: 'Work Sans';margin-left: 4px" *ngFor="let district of districts | sortBy:'asc':'name'" [value]="district.name">
              {{district.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div layout-gt-md="row" class="center">
          <button class="md-button button" type="Submit" [disabled]="determineDisableStatus()">Submit
          </button>
        </div>
      </form>
    </div>

    <hr class="thickHr">

    <div class="helpDesk">
      <h2>Help Desk</h2>
      <div class="flex-inline">
        <a href="mailto:MSIXSupport@deloitte.com?Subject=MSIX%20Password%20Reset%20Request"><i class="fa fa-envelope" aria-hidden="true"></i>&nbsp;MSIXSupport@deloitte.com</a>
      </div>
      <a href="tel:+1-866-878-9525" class="flex-inline"><i class="fa fa-phone" aria-hidden="true"></i>&nbsp;1-866-878-9525</a>
      <p>NOTE: The Help Desk cannot reset passwords.</p>
    </div>

  </div>

</div>