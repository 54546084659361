<div class="studentRecordSearch-page">

  <!--desktop and mobile search module-->
  <div style="display: flex; flex-direction: column;" class="dashboard-module no-padding">

    <div layout="row" layout-align="center center" class="hide-gt-xs dashboard-module-title-background layout-padding">
      <h1 style="display: flex" class="h1-no-margins no-padding">Student Record Search</h1>
    </div>
    
    <div layout="row" layout-align="center center" class="hide-xs dashboard-module-title-background layout-padding">
      <h3 style="display: flex" class="dashboard-module-title-text-2">Student Record Search</h3>
    </div>

    <div class="dashboard-module-body layout-padding">  

      <div [ngClass]="{'layout-flex-column': (responsiveUI.isMobile)}" class="custom-input-container">

        <div style="display: flex; flex-direction: row; align-items: center;" layout-align="center center" class="layout-row">
          <p class="layout-padding-l search-form-instructions">
            Ensure that your search contains at least one of the following elements: 
            First Name, Last Name, Date of Birth, ID, Parent First Name, or Parent Last Name. 
            Free form search fields allow for partial search and wild card search. 
            For more information on how to use partial search and wild card search, 
            click <a href="{{help_url}}help/index.htm#t=Student_Record_Search%2FSearching.htm" 
            target="_blank" rel="noopener noreferrer">here</a>. 
          </p>
          <div style="flex: 20%" class="align-right">
            <button class="layout-padding-l-10 layout-padding-r align-right clear-button" (click)="resetSearchValues()" [disabled]="!query" aria-label="Clear" tabindex="0">
              <i class="fa fa-times" aria-hidden="true"></i>
              <span>Clear</span>
            </button>
          </div>
        </div>
        <form (ngSubmit)="goToSearchResults()" [ngClass]="{'layout-padding-l-14 layout-padding-r-14': (responsiveUI.isMobile && !responsiveUI.isPortrait)}">
          <div [ngClass]="responsiveUI.isMobile ? 'layout-flex-column': 'layout-flex-row'">
            <!-- Begin Left Side -->
            <div [ngClass]="{'form-container' : !responsiveUI.isMobile}">
              <div class="custom-input-container" style="display: flex; flex-direction: column; width: 100%">
                <custom-mat-input 
                  customlabel="First Name"
                  [(ngModel)]="query.firstName"
                  name="searchFName"
                  [userform]="false"
                  (ngsubmit)="goToSearchResults()">
                </custom-mat-input>
                <custom-mat-input 
                  *ngIf="recordSearch"
                  customlabel="Middle Name"
                  [(ngModel)]="query.middleName"
                  name="searchMName"
                  [userform]="false"
                  (ngsubmit)="goToSearchResults()">
                </custom-mat-input>
                <custom-mat-input 
                  *ngIf="!recordSearch"
                  customlabel="Last Name"
                  [(ngModel)]="query.lastName"
                  name="searchLName"
                  [userform]="false"
                  (ngsubmit)="goToSearchResults()">
                </custom-mat-input>
                <custom-mat-input 
                  *ngIf="recordSearch"
                  customlabel="Last Name 1"
                  [(ngModel)]="query.lastName"
                  name="searchLName1"
                  [userform]="false"
                  (ngsubmit)="goToSearchResults()">
                </custom-mat-input>
                <custom-mat-input 
                  *ngIf="recordSearch"
                  customlabel="Last Name 2"
                  [(ngModel)]="query.lastName2"
                  name="searchLName2"
                  [userform]="false"
                  (ngsubmit)="goToSearchResults()">
                </custom-mat-input>

                <div *ngIf="recordSearch">
                  <mat-form-field style="width:31.875rem; border: 1px solid; height: 44px; border-color: #888; margin-top: .5rem">
                    <mat-label class="custom-label" for="genderInput">Sex</mat-label>
                    <mat-select class="full-sized-box" [(ngModel)]="query.gender" id="genderInput" name="genderInput">
                      <mat-option [value]="1">Male</mat-option>
                      <mat-option [value]="2">Female</mat-option>
                      <mat-option [value]="3">Other</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
                <div *ngIf="recordSearch">
                  <mat-form-field style="width:31.875rem; border: 1px solid; height: 44px; border-color: #888; margin-top: .8rem; margin-bottom: .3rem">
                    <mat-label class="custom-label" for="stateInput">State</mat-label>
                    <mat-select class="full-sized-box" name="stateInput" [(value)]="query.state">
                      <mat-option *ngFor="let state of states" [(value)]="state.stateKey">{{state.name}}</mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>

                <custom-mat-input 
                  *ngIf="recordSearch"
                  customlabel="Parent First Name"
                  [(ngModel)]="query.parentFirstName"
                  name="searchPFName"
                  [userform]="false"
                  (ngsubmit)="goToSearchResults()">
                </custom-mat-input>
                <custom-mat-input 
                  *ngIf="recordSearch"
                 
                  customlabel="Parent Last Name"
                  [(ngModel)]="query.parentLastName"
                  name="searchPLName"
                  [userform]="false"
                  (ngsubmit)="goToSearchResults()">
                </custom-mat-input>
              </div>
            </div>
            <!--End Left Side-->
            <!-- Begin Right Side -->
            <div [ngClass]="{'form-container-r' : !responsiveUI.isMobile}" style="margin-top: -1px;">
              <div style="display: flex; flex-direction: row;" [ngClass]="{'dob-mobile': responsiveUI.isMobile}" class="custom-input-container input-container-margins flex-container layout-row">
                <div *ngIf="responsiveUI.isMobile || responsiveUI.isTablet" style="display: flex; width: 17%">
                  <p>DOB:</p>
                </div>
                <div *ngIf="!(responsiveUI.isMobile || responsiveUI.isTablet)" style="display: flex; width: 25%; margin-top: 10px;">
                  <p class="search-form-label">Date of Birth:</p>
                </div>
                <div style="display: flex; flex-direction: row; gap: .5rem" [ngClass]="(responsiveUI.isMobile || responsiveUI.isTablet) ? 'width-80' : 'width-70'">
                  <custom-mat-input 
                    style="width: 25%;"
                    customlabel="MM"
                    [(ngModel)]="query.month"
                    name="searchBirthMonth"
                    maxcharacter=2
                    [isNumeric]="true"
                    [userform]="false"
                  (ngsubmit)="goToSearchResults()">
                  </custom-mat-input>
                  <custom-mat-input 
                    style="width: 25%;"
                    customlabel="DD"
                    [(ngModel)]="query.day"
                    name="searchBirthDay"
                    maxcharacter=2
                    [isNumeric]="true"
                    [userform]="false"
                  (ngsubmit)="goToSearchResults()">
                  </custom-mat-input>
                  <custom-mat-input 
                    style="width: 50%"
                    customlabel="YYYY"
                    [(ngModel)]="query.year"
                    name="searchBirthYear"
                    maxcharacter=4
                    [isNumeric]="true"
                    [userform]="false"
                  (ngsubmit)="goToSearchResults()">
                  </custom-mat-input>
                </div>
              </div>
              <custom-mat-input 
                style="display: grid; width: 100%;"
                customlabel="ID (MSIX, State, or Alternative)"
                [(ngModel)]="query.identifier"
                name="searchIdentifier"
                [userform]="false"
                  (ngsubmit)="goToSearchResults()">
              </custom-mat-input>
              <div [ngClass]="(responsiveUI.isMobile && responsiveUI.isPortrait) ? 'layout-flex-column' : 'layout-flex-row'">
                <p class="search-form-label" style="flex: 25%; padding-top: 8px;">ID Type:</p>
                <div style="flex: 75%;">
                  <mat-radio-group class="flex-inline" [(ngModel)]="query.identifierType" style="margin-left: -5px; justify-content: space-around;" name="searchIdentifierType">
                    <mat-radio-button value="msix" style="padding-right: 45px;">
                      <p class="radio-label">MSIX</p>
                    </mat-radio-button>
                    <mat-radio-button value="state" flex-offset="10">
                      <p class="radio-label">State</p>
                    </mat-radio-button>
                    <mat-radio-button value="alternate" flex-offset="10">
                      <p class="radio-label">Alternate</p>
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
              <div style="padding-top: 19px" *ngIf="recordSearch">
                <mat-form-field style="width: 34.5rem; border: 1px solid; height: 44px; border-color: #888;">
                  <mat-label class="custom-label" for="multiBirthInput">Multi-Birth</mat-label>
                  <mat-select class="full-sized-box" [(value)]="query.multiBirth" name="multiBirthInput" id="multiBirthInput">
                    <mat-option value="Yes">Yes</mat-option>
                    <mat-option value="No">No</mat-option>
                  </mat-select>
                </mat-form-field>
              </div>

              <div class="custom-input-container" style="display: grid; padding-top: 15px">
                <custom-mat-input 
                  *ngIf="recordSearch"
                  customlabel="District of Residence/School District Name"
                  [(ngModel)]="query.schoolDistrict"
                  name="searchSchoolDistrict"
                  [userform]="false"
                  (ngsubmit)="goToSearchResults()">
                </custom-mat-input>
                <custom-mat-input 
                  *ngIf="recordSearch"
                 
                  customlabel="School or Project Name"
                  [(ngModel)]="query.schoolProjectName"
                  name="searchSchoolProjectName"
                  [userform]="false"
                  (ngsubmit)="goToSearchResults()">
                </custom-mat-input>
                <custom-mat-input 
                  *ngIf="recordSearch"
                  customlabel="Facility Name"
                  [(ngModel)]="query.facilityName"
                  name="searchFacilityName"
                  [userform]="false"
                  (ngsubmit)="goToSearchResults()">
                </custom-mat-input>
              </div>
            </div>
          </div>
          <div style="display: flex; flex-direction: row; align-items: center; justify-content: space-between" layout-align="center center" class="input-container-margins layout-padding-t">
            <div >
              <button [ngClass]="{'no-top-bottom-right-margin-padding': responsiveUI.isMobile, 
              'no-top-bottom-margin-padding': !responsiveUI.isMobile, 
              'layout-padding-l-14': responsiveUI.isDesktop}" 
              class="clear-button" 
              (click)="toggleSearch()" aria-label="Search Type" tabindex="0" type="button">
                <span>{{getText}}</span>
              </button>
            </div>

            <div class="layout-padding-r">
              <button class="md-button" type="submit" [disabled]="!(query && (query.identifier || query.firstName || query.lastName || query.parentFirstName || query.parentLastName || (query.year && query.year.length == 4)))">Search</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
