<div class="wrapper">
    <h1 class="title">{{isExcluded ? 'Excluded' : 'Included'}} Child Detail, Age Birth through 2 {{isSummer ? 'Summer/Intersession' : ''}}</h1>

    <mat-divider></mat-divider>

    <p *ngIf="isSummer">This page displays the relevant Minimum Data Elements (MDEs) of the student record for a child who is
        {{isExcluded ? 'excluded from' : 'included in'}}
        the Age Birth through 2 Summer/Intersession count for the selected performance period.</p>
    <p *ngIf="!isSummer">
        This page displays the relevant Minimum Data Elements (MDEs) of the student record for a child who is
         included in the Age Birth through 2 count for the selected performance period.
    </p>


    <div class="header">
        <div class="showing-container">
            <span>Showing: </span> <span class="showing">{{displayDate}}</span>
        </div>

        <div class="nav-buttons">
            <button [disabled]="idx === 0" mat-flat-button
                (click)="getStudentInfo(-1)"><mat-icon>chevron_left</mat-icon>Previous Record</button>
            <button [disabled]="idx === ids.length-1" class="chevron-right" mat-flat-button
                (click)="getStudentInfo(1)">Next Record<mat-icon>chevron_right</mat-icon></button>
        </div>
    </div>

    <ng-container *ngIf="childDetails$ | async as childDetails">
        <div class="card-container">
            <mat-card appearance="outlined">
                <mat-card-content class="student-info">
                    <div class="line">
                        <div>First Name</div>
                        <div class="dash-line"></div>
                        <div>{{childDetails.firstName ?? '-'}}</div>
                    </div>
                    <div class="line">
                        <div>Last Name 1</div>
                        <div class="dash-line"></div>
                        <div>{{childDetails.lastName1 ?? '-'}}</div>
                    </div>
                    <div class="line">
                        <div>Last Name 2</div>
                        <div class="dash-line"></div>
                        <div>{{childDetails.firstName2 ?? '-'}}</div>
                    </div>
                    <div class="line">
                        <div>MSIX ID</div>
                        <div class="dash-line"></div>
                        <div (click)="goToStudentDetails(childDetails.msixId)" class="msix-id-link">
                            {{childDetails.msixId ?? '-'}}</div>
                    </div>
                    <div class="line">
                        <div>State ID</div>
                        <div class="dash-line"></div>
                        <div>{{childDetails.stateStudentId ?? '-'}}</div>
                    </div>
                    <div class="line">
                        <div>DOB (Date of Birth)</div>
                        <div class="dash-line"></div>
                        <div>{{(childDetails.dob | date:'MM/dd/yyyy') ?? '-'}}</div>
                    </div>
                    <div class="line">
                        <div>Grade</div>
                        <div class="dash-line"></div>
                        <div>{{childDetails.gradeLevel ?? '-'}}</div>
                    </div>
                    <div class="line">
                        <div>District</div>
                        <div class="dash-line"></div>
                        <div>{{childDetails.district ?? '-'}}</div>
                    </div>
                    <div class="line">
                        <div>School</div>
                        <div class="dash-line"></div>
                        <div>{{childDetails.schoolProjectName ?? '-'}}</div>
                    </div>

                    <mat-divider></mat-divider>
                    <div class="line">
                        <div>Enrollment Date</div>
                        <div class="dash-line"></div>
                        <div>{{(childDetails.enrollmentDate | date: 'MM/dd/yyyy') ?? '-'}}</div>
                    </div>
                    <div class="line">
                        <div>Enrollment Type</div>
                        <div class="dash-line"></div>
                        <div>{{childDetails.enrollmentType ?? '-'}}</div>
                    </div>

                    <div class="line">
                        <div>Withdrawal Date</div>
                        <div class="dash-line"></div>
                        <div>{{(childDetails.withdrawalDate | date:'MM/dd/yyyy') ?? '-'}}</div>
                    </div>
                    <div class="line">
                        <div>Residency Date</div>
                        <div class="dash-line"></div>
                        <div>{{(childDetails.residencyDate | date:'MM/dd/yyyy') ?? '-'}}</div>
                    </div>
                    <div class="line">
                        <div>Residency Verification Date</div>
                        <div class="dash-line"></div>
                        <div>{{(childDetails.residencyVerificationDate | date:'MM/dd/yyyy') ?? '-'}}</div>
                    </div>
                    <div class="line">
                        <div>Qualifying Arrival Date</div>
                        <div class="dash-line"></div>
                        <div>{{(childDetails.qualifyingArrivalDate | date:'MM/dd/yyyy' ) ?? '-'}}</div>
                    </div>
                    <div class="line">
                        <div>Eligibility Expiration Date</div>
                        <div class="dash-line"></div>
                        <div>{{(childDetails.eligibilityExpirationDate | date:'MM/dd/yyyy' ) ?? '-'}}</div>
                    </div>
                    <div class="line">
                        <div>Continuation of Services Reason</div>
                        <div class="dash-line"></div>
                        <div>{{childDetails.cos ?? '-'}}</div>
                    </div>
                    <div class="line">
                        <div>Graduation/HSE Date</div>
                        <div class="dash-line"></div>
                        <div>{{(childDetails.graduationDate |date:'MM/dd/yyyy') ?? '-'}}</div>
                    </div>

                </mat-card-content>
            </mat-card>

            <div class="accordion-container">
                <mat-accordion class="logic" *ngIf="isSummer; else nonSummerLogic">
                    <mat-expansion-panel [expanded]="false">
                        <mat-expansion-panel-header>
                            <h3>Age Birth through 2 Summer/Intersession Logic</h3>
                        </mat-expansion-panel-header>
                        <div class="logic-header">
                            What children appear on the Age Birth through 2 Summer/Intersession Included list?
                        </div>
                        <div class="logic-details">
                            <p>
                                Children appear on the Age Birth through 2 Summer/Intersession Included list if they are
                                age
                                birth through
                                2 throughout the entire performance period, at least one of the following dates that
                                establishes residency
                                (Enrollment Date, Withdrawal Date, Residency Date, Residency Verification Date) occurs
                                <span class="bold">during the performance period,
                                    and satisfies all of the following criteria:</span>
                            </p>
                            <ol type="a">
                                <li> This date must occur <span class="bold">on or after</span> the child's Birth Date.
                                </li>
                                <li> The Qualifying Arrival Date (QAD) must occur.
                                    <ul type="i">
                                        <li> <span class="bold">on or within 36 months minus one day before </span> this
                                            date AND</li>
                                        <li><span class="bold">after</span> the birth date.</li>
                                    </ul>
                                </li>
                                <li>
                                    The Eligibility Expiration Date must occur <span class="bold">on or after</span>
                                    this
                                    date.
                                </li>
                                <li>If a Graduation/HSE Date is submitted, it must occur on or after this date. </li>
                                <li> Enrollment Type associated with this date must be 03 Summer/Intersession MEP-Funded
                                    Project.</li>
                            </ol>

                            <div class="logic-header">What children appear on the Age Birth through 2
                                Summer/Intersession
                                Excluded list?</div>
                            <p>The Age Birth through 2 Summer/Intersession Excluded list includes children who do not
                                appear
                                in the Age Birth through 2
                                Summer/Intersession Included list but who may be included in the State-generated Age
                                Birth
                                through 2 Summer/Intersession Child Count.
                                States should match children appearing in their Age Birth through 2 Summer/Intersession
                                count to children in the MSIX Age Birth
                                through 2 Summer/Intersession Excluded list in order to examine reasons why these
                                children
                                have not been included in the MSIX count.
                                Please note that this list should be used by States for comparison purposes only.</p>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

                <mat-accordion class="data-logic-issues" *ngIf="isExcluded && childDetails.failedLogicRules.length">
                    <mat-expansion-panel [expanded]="true">
                        <mat-expansion-panel-header>
                            <div class="header-warning">
                                <mat-icon>warning</mat-icon>
                                <h3>Data Logic Issue</h3>
                            </div>
                        </mat-expansion-panel-header>
                        <div class="logic-header">
                            What children appear on the Age Birth through 2 Summer/Intersession Excluded list?
                        </div>
                        <div class="logic-details">
                            <div *ngIf="hasMissingDateLogicIssue">
                                <p class="bold">No date that establishes residency</p>
                                <p>At least one of the following dates that establish residency
                                    (Enrollment Date, Withdrawal Date, Residency Date, Residency Verification Date)
                                    must occur during the performance period. If your State determines that a date that
                                    establishes residency exists for this child verify that the date also meets the
                                    following criteria:
                                </p>
                                <ul>
                                    <li>
                                        This date must <span class="bold">occur on or after</span> the child's Birth
                                        Date.
                                    </li>
                                    <li>
                                        QAD occurs <span class="bold"> on or within 36 months minus one day
                                            before</span>
                                        this date;
                                    </li>
                                    <li>EED occurs <span class="bold"> on or after</span> this date;</li>
                                    <li>
                                        If a Graduation/HSE Date is submitted, it must occur <span class="bold"> on or
                                            after</span> this date.
                                    </li>
                                </ul>
                            </div>
                            <ng-container *ngIf="!hasMissingDateLogicIssue">
                            
                            <div *ngIf="EEDOccursBeforeResidencyDataLogic">
                                <p class="bold">
                                    Eligibility Expiration Date occurs before dates that establishes residency
                                </p>
                                <p>The Eligibility Expiration Date must occur on or after the date that establishes
                                    residency (Enrollment Date).
                                </p>
                            </div>
                            <br>

                            <div *ngIf="qadOccursAfterResidencyEstablishingDateDataLog">
                                <p class="bold">QAD occurs after or more than 36 months minus one
                                    day before the date that establishes residency OR before the Birth Date</p>
                                <p>The Qualifying Arrival Date (QAD) must occur on or within 36 months minus one day
                                    before
                                    this date AND after the birth date.</p>
                            </div>
                            <br>
                            <div>
                                <p class="bold">Graduation/HSE Date occurs before date that establishes residency</p>
                                <p>If a Graduation/HSE Date is submitted, it must occur on or after the date that
                                    establishes residency (Enrollment Date).
                                </p>
                            </div>
                            <br>
                            <div *ngIf="residencyEstablishingDateOccursBeforeBirthDateDataLogic">
                                <p class="bold">Date that establishes residency occurs before Birth Date</p>
                                <p>The date that establishes residency (Enrollment Date, Withdrawal Date, Residency
                                    Date, or
                                    Residency Verification Date) must occur after the child's Birth Date.</p>
                            </div>
                            <br>
                            <div *ngIf="enrollmentTypeIs07OrMissingDataLogic">
                                <p class="bold">Enrollment Type is 07 or missing</p>
                                <p>Enrollment Type must be 01, 02, 03, 04, 05, or 06.</p>
                            </div>
                            <br>
                            <div *ngIf="graduationDateOccursBeforeResidencyEstablishingDateDataLogic">
                                <p class="bold">Graduation/HSE Date occurs before date that establishes residency</p>
                                <p>If a Graduation/HSE Date is submitted, it must occur on or after the date that establishes residency (Enrollment Date).</p>
                            </div>
                        </ng-container>
                        </div>
                    </mat-expansion-panel>
                </mat-accordion>

            </div>

        </div>
        <br />
        <div>
            Last Updated: {{childDetails.lastUpdated ? (childDetails.lastUpdated | date:'MM/dd/yyyy') :
            (lastUpdatedDefault | date:'MM/dd/yyyy')}}
        </div>
    </ng-container>

</div>

<ng-template #nonSummerLogic>
    <mat-accordion class="logic">
        <mat-expansion-panel [expanded]="false">
            <mat-expansion-panel-header>
                <h2>Age Birth through 2 Logic</h2>
            </mat-expansion-panel-header>
            <div class="logic-header">
                What children appear on the Age Birth through 2 Included list?
            </div>
            <div class="logic-details">
                <p>
                    Children appear on the Age Birth through 2 Included list if they are age birth through 2 
                    throughout the entire performance period, at least one of the following dates that establishes
                     residency (Enrollment Date, Withdrawal Date, Residency Date, Residency Verification Date) occurs
                    <span class="bold">during the performance period,
                        and satisfies all of the following criteria:</span>
                </p>
                <ol type="a">
                    <li> This date must occur <span class="bold">on or after</span> the child's Birth Date.
                    </li>
                    <li> The Qualifying Arrival Date (QAD) must occur.
                        <ol type="i">
                            <li> <span class="bold">on or within 36 months minus one day before </span> this
                                date AND</li>
                            <li><span class="bold">after</span> the birth date.</li>
                        </ol>
                    </li>
                    <li>
                        The Eligibility Expiration Date must occur <span class="bold">on or after</span>
                        this
                        date.
                    </li>
                    <li>If a Graduation/HSE Date is submitted, it must occur <span class="bold">on or after</span> this date. </li>
                    <li>Enrollment Type associated with this date must be 01, 02, 03, 04, 05, or 06.</li>
                </ol>

                <div class="logic-header">What children appear on the Age Birth through 2 Excluded list?
                </div>
                <p>The Age Birth through 2 Excluded list includes children who do not appear in the Age Birth
                     through 2 Included list but who may be included in the State-generated Age Birth through 
                     2 Child Count. States should match children appearing in their Age Birth through 2 count
                      to children in the MSIX Age Birth through 2 Excluded list in order to examine reasons why
                       these children have not been included in the MSIX count. Please note that this list should
                        be used by States for comparison purposes only.</p>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
</ng-template>