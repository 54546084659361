import { Component, Output, EventEmitter, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { throwError } from 'rxjs';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { StateService } from '../../services/state.service';
import { SearchService } from 'src/app/services/search.service';
import { AppConstants } from 'src/app/config/index.constants';
import { searchType } from 'src/app/views/search-results/search-results.component';

@Component({
  selector: 'student-search',
  templateUrl: './student-search.component.html',
  styleUrls: ['./student-search.component.scss']
  })
export class StudentSearchComponent implements OnInit {

  @Input() query: searchType;

  @Output() searchStudents = new EventEmitter<searchType>();

  search: searchType;
  states: String[];
  countries: String[];
  help_url = AppConstants.help;
  getText = "Advanced Search";
  state = {
    name: ""
  }
  country = {
    description: ""
  }
  mobile = false;
  recordSearch = false;
  public fname;
  public lname;
  public parentfname;
  public parentlname;
  public year;

  constructor(private stateService: StateService,
    private _router: Router,
    public responsiveUI: ResponsiveUIService,
    private searchService: SearchService) {
  }

  ngOnInit(): void {
    this.getStates();
    this.getCountries();
    this.recordSearch = false;
    if (this.query) {
      if (this.query.middleName || this.query.gender || this.query.multiBirth || this.query.parentFirstName ||
          this.query.parentLastName || this.query.state || this.query.birthState || this.query.birthCountry ||
          this.query.schoolDistrict || this.query.schoolProjectName || this.query.facilityName) {
        this.recordSearch = true;
      }
    }

    this.resetSearchValues();
  }
  
  async getStates(): Promise<void> {
    this.states = await this.stateService.getStates();
  }

  getCountries(): void {
    this.stateService.getCountries()
        .subscribe(countries => this.countries = countries);
  }

  resetSearchValues() {
    this.query = {
      firstName: '',
      lastName: '',
      lastName2: '',
      username: '',
      gender: '',
      month: '',
      day: '',
      year: '',
      multiBirth: '',
      middleName: '',
      parentFirstName: '',
      parentLastName: '',
      state: '',
      birthState: '',
      birthCountry: '',
      schoolDistrict: '',
      schoolProjectName: '',
      facilityName: '',
      identifier: '',
      identifierType: 'msix',
      pageNum: 1
    };
  }

  toggleSearch(): void{
    this.recordSearch = !this.recordSearch;
    this.getText = this.recordSearch ? 'Basic Search' : 'Advanced Search';
  }

  goToSearchResults() {
    let userActivity = {
      msixID: '',
      pageName: 'APP_STU_SRCH',
      activityNotes: '',
      sessionID: ''
    };

    this.searchService.logUserActivity(userActivity);

    this.query.pageNum = 1;

    /* this._router.navigate(['searchResults'], {
      queryParams: this.query
    }); */

    if (this._router.url.includes('/searchResults')) {
      // On the search results page already so student-search is child component of the search-results page.
      console.log('on search results page so emitting the value');

      this.searchStudents.emit(this.query);
    } else {
      // On the home page.
      console.log('on home page so going to navigate to it.');

      this._router.navigate(['searchResults'], {
        queryParams: this.query
      });
    }
  }
 }
