<div class="reconPotentialDuplicates">
  <div class="md-content">
    <app-ng-dynamic-breadcrumb symbol="   > " bgColor="#FAFAFA"></app-ng-dynamic-breadcrumb>

    <div class="header" style="display: flex; flex-direction: row">
      <div class="layout-column flex">
        <div style="display: flex; flex-direction: row">
          <div style="display: flex; flex-direction: column">
            <h1 class="header-padding">Potential Duplicates</h1>
          </div>
        </div>
      </div>
      <div style="max-width: 15%;" class="flex-10" *ngIf="authRoleClasses.dataAdmins">
        <div class="layout-row flex flagButton" (click)="isMergeListEmpty || flagForMergeAllStudents()" [disabled]="isMergeListEmpty">
          <i class="flex-10 fa fa-compress" [ngClass]="isMergeListEmpty ? 'disabledColor' : 'iconColor'" aria-hidden="true"></i>
          <p class="flex-90 small" [ngClass]="isMergeListEmpty ? 'disabledColor' : 'iconColor'">Flag for Merge</p>
        </div>
      </div>
      <div class="layout-column flex-10">
        <export-report tabindex="0" [exportlink]="exportCall" [exportfilter]="exportParams"
        [showpdf]="false" [showcsv]="true" [blankres]="blankRes"></export-report>
      </div>
    </div>

    <hr layout-padding class="thickHr">

    <div style="display: flex; flex-direction: row" layout-align="center center">
      <div style="display: flex; flex-direction: column; flex: 90%">
        <div class="report-summary" style="display: flex; flex-direction: row">
          <div style="display: flex; flex-direction: column; flex: 80%">
            <p>
              Below is a list of student records for children with the same State ID and at least two MSIX IDs. 
              <a class="learn-more" (click)="learnMoreActivate()" (keypress)="learnMoreActivate()" tabindex="0">Learn More</a>
            </p>
          </div>
        </div>
        <div style="display: flex; flex-direction: row" class="totalDupesText">
          Total Count: {{response.totalAvailableRows}}
        </div>
        <reports-table
          [responsedata]="response"
          [hasreportlevelspecialsort]="true"
          (reportlevelspecialsort)="handleReportSpecialSortClick($event)"
          [showcheckbox]="authRoleClasses.dataAdmins"
          (checkboxcallback)="checkedRecordForFlagForMerge($event)"
          [sortascending]="true"
          [isinitaldesc]="false">
        </reports-table>
        <div *ngIf="response" style="display: flex; flex-direction: row; align-self: center;" layout-align="center center">
          <button class="md-button" style="min-width: 88px; margin: 6px 8px;" (click)="navPages('prev')" [disabled]="filterParameters.page===1" aria-label="back">
            <i class="fa fa-chevron-left" aria-hidden="true"></i>
          </button>
          <button class="md-button" style="min-width: 88px; margin: 6px 8px;" (click)="navPages('next')" [disabled]="disableNavigation()" aria-label="forward">
            <i class="fa fa-chevron-right" aria-hidden="true"></i>
          </button>
        </div>
        <div style="display: flex; flex-direction: row; padding:15px 0">
          <div style="display: flex; flex-direction: column; flex: auto">
            <div style="display: flex; flex-direction: row" class="review-text align-self-right">Want to continue reviewing your data?</div>
            <div style="display: flex; flex-direction: row; padding-top:5px" class="align-self-right">
              <a (click)="goToReport('reconNearMatches')" (keypress)="goToReport('reconNearMatches')" tabindex="0">Explore Near Matches</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
