<div class="setChallengeQuestions-page background-awesome" layout="row" layout-align="center center" flex>
  <!-- flex-xs="95" flex-sm="45" flex-md="35" flex-lg="75" flex-xl="70"  -->
  <div class="md-content" [ngClass]="{'layoutMobP': (responsiveUI.isMobile && responsiveUI.isPortrait), 'layoutMobL': (responsiveUI.isMobile && !responsiveUI.isPortrait),
  'layoutTabP': (responsiveUI.isTablet && responsiveUI.isPortrait), 'layoutTabL': (responsiveUI.isTablet && !responsiveUI.isPortrait), 'layoutDesktop': responsiveUI.isDesktop}">

    <div class="title center">
      <h1>Set Challenge Questions</h1>
    </div>

    <div class="errors flex-inline" *ngIf="errors">
      <i class="fa fa-times" aria-hidden="true"></i>&nbsp;
      <p class="validation">{{errors}}</p>
    </div>
    <div style="padding: 15px;">
      <div [ngClass]="{'layout-flex-column': (responsiveUI.isMobile), 'layout-flex-row': !(responsiveUI.isMobile)}">
        <div [ngClass]="{'flex-100': (responsiveUI.isMobile), 'flex-46': !(responsiveUI.isMobile)}">
          <div class="custom-input-container">
            <mat-form-field>
              <mat-label class="custom-label select-label">Challenge Question 1</mat-label>
              <mat-select [(value)]="question1" [ngClass]="{'mat-input-has-value': question1}">
                <mat-option *ngFor="let key of question1Array" [value]="key"
                  (click)="addAndRemoveSelectedQuestion(1, key)">
                  {{allChallengeQuestions[key]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="custom-input-container right-margin" 
            [ngClass]="{'error': (response1FormControl.touched && response1FormControl.errors?.required)
              || response1FormControl.errors?.maxlength}">
            <mat-form-field class="md-input-container-margins" hideRequiredMarker="true">
              <mat-label class="custom-label" [ngClass]>Response 1<span class="required-asterisk" [ngClass]="{'error-asterisk': response1FormControl.touched && (response1FormControl.errors?.required || response1FormControl.errors?.maxlength) }">&nbsp;*</span></mat-label>
              <input matInput class="custom-input" type="text" inputmode="numeric"
                [formControl]="response1FormControl"
                [ngClass]="{'mat-input-has-value': response1FormControl.value, 'input-error': (response1FormControl.touched && response1FormControl.errors?.required) || response1FormControl.errors?.maxlength}"
                required>
              <div class="error-message" *ngIf="response1FormControl.touched && response1FormControl.errors?.required">
                <p>Please provide a response.</p>
              </div>
              <div class="error-message" *ngIf="response1FormControl.errors?.maxlength">
                <p>Responses must be 35 characters or less.</p>
              </div>
            </mat-form-field>
          </div>

          <div class="custom-input-container">
            <mat-form-field placeholder="Challenge Question 2">
              <mat-label class="custom-label select-label">Challenge Question 2</mat-label>
              <mat-select [(value)]="question2" [ngClass]="{'mat-input-has-value': question2}">
                <mat-option *ngFor="let key of question2Array" [value]="key"
                  (click)="addAndRemoveSelectedQuestion(2, key)">
                  {{allChallengeQuestions[key]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="custom-input-container right-margin"
            [ngClass]="{'error': (response2FormControl.touched && response2FormControl.errors?.required) 
              || response2FormControl.errors?.maxlength}">
            <mat-form-field class="md-input-container-margins" hideRequiredMarker="true">
              <mat-label class="custom-label">Response 2<span class="required-asterisk" [ngClass]="{'error-asterisk': response2FormControl.touched && (response2FormControl.errors?.required || response2FormControl.errors?.maxlength) }">&nbsp;*</span></mat-label>
              <input matInput class="custom-input" type="text" inputmode="numeric" 
                [(value)]="response2"
                [formControl]="response2FormControl"
                [ngClass]="{'mat-input-has-value': response2FormControl.value, 'input-error': (response2FormControl.touched && response2FormControl.errors?.required) || response2FormControl.errors?.maxlength}"
                required>
              <div class="error-message" *ngIf="response2FormControl.touched && response2FormControl.errors?.required">
                <p>Please provide a response.</p>
              </div>
              <div class="error-message" *ngIf="response2FormControl.errors?.maxlength">
                <p>Responses must be 35 characters or less.</p>
              </div>
            </mat-form-field>
          </div>

          <div class="custom-input-container right-margin">
            <mat-form-field placeholder="Challenge Question 3">
              <mat-label class="custom-label select-label">Challenge Question 3</mat-label>
              <mat-select [(value)]="question3" [ngClass]="{'mat-input-has-value': question3}">
                <mat-option *ngFor="let key of question3Array" [value]="key"
                  (click)="addAndRemoveSelectedQuestion(3, key)">
                  {{allChallengeQuestions[key]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="custom-input-container right-margin"
            [ngClass]="{'error': (response3FormControl.touched && response3FormControl.errors?.required) 
              || response3FormControl.errors?.maxlength}">
            <mat-form-field class="md-input-container-margins" hideRequiredMarker="true">
              <mat-label class="custom-label">Response 3<span class="required-asterisk" [ngClass]="{'error-asterisk': response3FormControl.touched && (response3FormControl.errors?.required || response3FormControl.errors?.maxlength) }">&nbsp;*</span></mat-label>
              <input matInput class="custom-input" type="text" inputmode="numeric" 
                [(value)]="response3" [formControl]="response3FormControl"
                [ngClass]="{'mat-input-has-value': response3FormControl.value, 'input-error': (response3FormControl.touched && response3FormControl.errors?.required) || response3FormControl.errors?.maxlength}"
                required>
              <div class="error-message" *ngIf="response3FormControl.touched && response3FormControl.errors?.required">
                <p>Please provide a response.</p>
              </div>
              <div class="error-message" *ngIf="response3FormControl.errors?.maxlength">
                <p>Responses must be 35 characters or less.</p>
              </div>
            </mat-form-field>
          </div>
        </div>
        
        <div class="flex-8"></div>
        <div [ngClass]="{'flex-100': (responsiveUI.isMobile), 'flex-46': !(responsiveUI.isMobile)}">
          <div class="custom-input-container">
            <mat-form-field style="display: flex;" placeholder="Challenge Question 4">
              <mat-label class="custom-label select-label">Challenge Question 4</mat-label>
              <mat-select [(value)]="question4" [ngClass]="{'mat-input-has-value': question4}">
                <mat-option *ngFor="let key of question4Array" [value]="key" 
                  (click)="addAndRemoveSelectedQuestion(4, key)">
                  {{allChallengeQuestions[key]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="custom-input-container right-margin"
            [ngClass]="{'error': (response4FormControl.touched && response4FormControl.errors?.required) 
            || response4FormControl.errors?.maxlength}">
            <mat-form-field class="md-input-container-margins" hideRequiredMarker="true">
              <mat-label class="custom-label">Response 4<span class="required-asterisk" [ngClass]="{'error-asterisk': response4FormControl.touched && (response4FormControl.errors?.required || response4FormControl.errors?.maxlength) }">&nbsp;*</span></mat-label>
              <input matInput class="custom-input" type="text" inputmode="numeric" 
                [(value)]="response4"
                [formControl]="response4FormControl"
                [ngClass]="{'mat-input-has-value': response4FormControl.value, 'input-error': (response4FormControl.touched && response4FormControl.errors?.required) || response4FormControl.errors?.maxlength}"
                required>
              <div class="error-message" *ngIf="response4FormControl.touched && response4FormControl.errors?.required">
                <p>Please provide a response.</p>
              </div>
              <div class="error-message" *ngIf="response4FormControl.errors?.maxlength">
                <p>Responses must be 35 characters or less.</p>
              </div>
            </mat-form-field>
          </div>

          <div class="custom-input-container">
            <mat-form-field placeholder="Challenge Question 5">
              <mat-label class="custom-label select-label">Challenge Question 5</mat-label>
              <mat-select [(value)]="question5" [ngClass]="{'mat-input-has-value': question5}">
                <mat-option *ngFor="let key of question5Array" [value]="key"
                  (click)="addAndRemoveSelectedQuestion(5, key)">
                  {{allChallengeQuestions[key]}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div class="custom-input-container right-margin"
            [ngClass]="{'error': (response5FormControl.touched && response5FormControl.errors?.required) 
            || response5FormControl.errors?.maxlength}">
            <mat-form-field class="md-input-container-margins" hideRequiredMarker="true">
              <mat-label class="custom-label">Response 5<span class="required-asterisk" [ngClass]="{'error-asterisk': response5FormControl.touched && (response5FormControl.errors?.required || response5FormControl.errors?.maxlength) }">&nbsp;*</span></mat-label>
              <input matInput class="custom-input" type="text" inputmode="numeric" 
                [(value)]="response5"
                [formControl]="response5FormControl"
                [ngClass]="{'mat-input-has-value': response5FormControl.value, 'input-error': (response5FormControl.touched && response5FormControl.errors?.required) || response5FormControl.errors?.maxlength}"
                required>
              <div class="error-message" *ngIf="response5FormControl.touched && response5FormControl.errors?.required">
                <p>Please provide a response.</p>
              </div>
              <div class="error-message" *ngIf="response5FormControl.errors?.maxlength">
                <p>Responses must be 35 characters or less.</p>
              </div>
            </mat-form-field>

          </div>
        </div>
      </div>

      <div layout="row" class="center">
        <button class="md-button" (click)="submitForm()" type="submit" 
          [disabled]="(!question1 || !question2 || !question3 || !question4 || !question5
          || response1FormControl.errors || response1FormControl.value?.length < 2
          || response2FormControl.errors || response2FormControl.value?.length < 2
          || response3FormControl.errors || response3FormControl.value?.length < 2
          || response4FormControl.errors || response4FormControl.value?.length < 2
          || response5FormControl.errors || response5FormControl.value?.length < 2)">Submit
        </button>
      </div>
    </div>

  </div>
  <!--p class="version-number">{{appVersion}}</p-->
</div>
  