import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { SavedListService } from 'src/app/services/savedlist.service';
import { SearchService } from 'src/app/services/search.service';
import { StateService } from 'src/app/services/state.service';
import { ToastService } from 'src/app/services/toast.service';

@Component({
  selector: 'search-results',
  templateUrl: './search-results.component.html',
  styleUrls: ['./search-results.component.scss']
})
export class SearchResultsComponent implements OnInit {
  private showAdminDetails: boolean = false;
  private adminIndex: number = -1;
  private states: any[];
  private search: {};
  totalRecords: any;
  fullList: any[] = new Array();
  showSearch: boolean = true;
  private newCheckedLists: any[];
  private savedListsNames: any[];
  private savelistObject = {
    msixID: '',
    stateStudentKey: '',
    savedListKeys: [],
    unsavedListKeys: [],
    newListName: ''
  };
  private student = {
    saved: '',
    msixId: '',
    staStuKey: ''
  };
  private stateParams: any;
  private searchObject: searchType;
  pageNum: number;
  pageSize: number;
  private partialListLength: number;
  private errors: any[];
  studentIndex: number = -1;

  constructor(private searchService: SearchService,
    private stateService: StateService,
    private savedListService: SavedListService,
    private toastService: ToastService,
    private route: ActivatedRoute,
    private _router: Router,
    public responsiveUI: ResponsiveUIService,
    private authService: AuthService) {

    this.stateParams = <any>this.route.snapshot.queryParams;

    console.log('search results constructor called');
  }

  ngOnInit(): void {

    this.checkIfLoggedIn();

    if (Object.keys(this.stateParams).length > 0) {
      this.searchObject = this.stateParams;
      this.searchForStudents(this.searchObject, false);
    } else {
      this.resetSearch();
    }

    /* if (searchObject) {
      this.query = searchObject;
    }
    this.searchForStudents(searchObject); */
    this.getSavedListsNames();

    console.log('search results init called');
  }

  checkIfLoggedIn() {
    if (!this.authService.isAuthenticated()) { 
      this._router.navigate(['/']);
    }
  }

  resetSearch() {
    this.searchObject = {
      firstName: '',
      lastName: '',
      lastName2: '',
      middleName: '',
      gender: '',
      month: '',
      day: '',
      year: '',
      multiBirth: '',
      parentFirstName: '',
      parentLastName: '',
      username: '',
      state: '',
      birthState: '',
      birthCountry: '',
      schoolDistrict: '',
      schoolProjectName: '',
      facilityName: '',
      identifier: '',
      identifierType: '',
      pageNum: 1
    }
  }

  searchForStudents(searchObject, isLoadMore: boolean = false) {


    if (!isLoadMore) {
      this.fullList = new Array();
    }

    console.log('search-results.searchForStudents() called.');
    if (searchObject && (searchObject.identifier || searchObject.firstName || searchObject.lastName
      || searchObject.year || searchObject.parentFirstName || searchObject.parentLastName)) {

      this.searchAgain();

      console.log('search results searchForStudents called');

      let apiCall = this.searchService.postSearchResults(searchObject);
      let subscribed = apiCall.subscribe({
        // Callback for success
        next: result => {
          let res: any = result;

          this.totalRecords = res.results.totalRecords;
          this.pageNum = res.query.pageNum;
          this.pageSize = res.query.pageSize;
          this.partialListLength = res.results.partialList.length;
          this.fullList = this.fullList.concat(res.results.partialList);
          this.searchObject = res.query;
          this.showSearch = false;

          subscribed.unsubscribe();
        },
        error: error => {
          console.error(error.message);
          this.errors = error.error;
          this.toastService.showToast(error.error.developerMessage, 0);

          subscribed.unsubscribe();
        }
      });
    }
  }

  //Retrieves a list of the names of a user's saved lists
  getSavedListsNames() {
    let apiCall = this.savedListService.getSaveListsNames();
    let subscribed = apiCall.subscribe({
      // Callback for success
      next: result => {
        let res: any = result;

        console.debug(res);
        this.savedListsNames = res.data;

        subscribed.unsubscribe();
      },
      error: error => {
        console.error(error);
        this.toastService.showToast(error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }

  viewDetails(student) {
    let userActivity = {
      msixID: student.msixId,
      pageName: 'APP_STU_VW_CONS',
      activityNotes: '',
      sessionID: ''
    }
    
    this.searchService.logUserActivity(userActivity);

    this._router.navigate(['studentDetails'], {
      queryParams: {
        msixId: student.msixId,
        name: student.stuName
      }
    });
  }

  loadMoreResults() {
    var increasedPageNum = this.pageNum + 1;
    this.searchObject.pageNum = increasedPageNum;
    this.searchForStudents(this.searchObject, true);
  }

  showStudentDetailsFn(index) {
    if (this.studentIndex === index) {
      this.studentIndex = -1;
    } else {
      this.studentIndex = index;
    }
  }

  //adds student to user's My Default List when button is selected in mobile/tablet mode
  addToMyDefaultList(msixId) {
    for (let list of this.savedListsNames) {
      if (list.saveListName === 'My Default List') {
        this.newCheckedLists.push(list.saveListKey);
      }
    }

    this.savelistObject = {
      msixID: msixId,
      savedListKeys: this.newCheckedLists,
      unsavedListKeys: [],
      newListName: '',
      stateStudentKey: null
    }

    let apiCall = this.savedListService.updateStudentToSavedLists(this.savelistObject);
    let subscribed = apiCall.subscribe({
      // Callback for success
      next: result => {
        console.debug(result);
        this.newCheckedLists = [];

        subscribed.unsubscribe();
      },
      error: error => {
        console.error(error);
        this.toastService.showToast(error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }

  searchAgain() {
    this.totalRecords = -1;
    this.showSearch = true;
  }
}

export interface searchType {
  firstName: string,
  lastName: string,
  lastName2: string,
  middleName: string,
  gender: string,
  month: string,
  day: string,
  year: string,
  multiBirth: string,
  parentFirstName: string,
  parentLastName: string,
  username: string,
  state: string,
  birthState: string,
  birthCountry: string,
  schoolDistrict: string,
  schoolProjectName: string,
  facilityName: string,
  identifier: string,
  identifierType: string,
  pageNum: number
};
  /*
TODO - OLD CODE - Do we need this function?
searchResultsSearch() {
  this.$location.search('query', angular.toJson(this.search));
  this.searchForStudents(this.search);
}
*/