import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppConstants }  from '../config/index.constants';
import * as angular from 'angular';

@Injectable({
  providedIn: 'root'
})
export class HelpPagesService {

  private apiUrl = AppConstants.api;

  constructor(private http: HttpClient) {
  }

  // This function makes the API call to get the secured documents
  getPPTXFile(link) {
    console.log(link);
    let apiCall = this.http.post<any>(this.apiUrl + 'helppages/getPPTX/' + link, '', { responseType: 'blob' as 'json' });
    let subscribed = apiCall.subscribe(
      data => {

        var file = new Blob([data], {type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'});

        var downloadLink = angular.element('<a style="display:none"></a>');
        downloadLink.attr('href', window.URL.createObjectURL(file));
        downloadLink.attr('target', '_blank');
        downloadLink.attr('download', link + '.pptx');
        downloadLink[0].click();
      }
    );
  }
}