import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchService } from 'src/app/services/search.service';
import { StateService } from 'src/app/services/state.service';
import { UserService } from 'src/app/services/user.service';
import { ReportInfoService } from 'src/app/services/reportinfo.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ResponsiveUIService } from 'src/app/services/responsiveUI.service';
import { ToastService } from 'src/app/services/toast.service';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'userAdmin',
  templateUrl: './userAdmin.component.html',
  styleUrls: ['./userAdmin.component.scss']
})
export class UserAdminComponent implements OnInit {

  private showAdminDetails: boolean;
  private adminIndex: number;
  private state: string[];
  search: SearchObject;
  private showResults: boolean;
  private fullList: string[];
  private recordSearch: string;
  isAuthenticatedUser: boolean;
  private userIndex: number;
  private userList: any;
  private totalRecords: number;
  private stateParams: SearchObject;
  
  constructor(private _User: UserService,
    private _Search: SearchService,
    private _State: StateService,
    private _ReportInfoService: ReportInfoService,
    private _router: Router,
    public responsiveUI: ResponsiveUIService,
    private toastService: ToastService,
    private route: ActivatedRoute) {
    this.adminIndex = -1;
    this.showResults = false;
    this.isAuthenticatedUser = false;
    this.search = {
      firstName: '',
      lastName: '',
      username: ''
    };

    this.stateParams = <any>this.route.snapshot.queryParams;
  }

  ngOnInit() {
    /* let usrObj = this._ReportInfoService.getReportInfo(['stateAdmin', 'regionalAdmin']);
    console.log(usrObj); */

    this.setAuthenticatedStatus();

    if (this.stateParams.firstName || this.stateParams.lastName || this.stateParams.username) {
      this.search = cloneDeep(this.stateParams);
      this.userSearch();
    }

    /* let subscribed = apiCall.subscribe({
      // Callback for success
      next: res => {
        this.isAuthenticatedUser = true;

        //if (this.$stateParams.firstName || this.$stateParams.lastName || this.$stateParams.username) {
        if (this.firstName || this.lastName || this.username) {
          this.search.firstName = this.firstName;
          this.search.lastName = this.lastName;
          this.search.username = this.username;
          this.userSearch();
        }

        subscribed.unsubscribe();
      },
      error: error => {
        // TODO - Need to update when Toast message service has been implemented
        //this._Toast.showToast(err.data.developerMessage, 0);
        console.log('Unaunthenticated user.');

        subscribed.unsubscribe();
      }
    }); */
  }

  private async setAuthenticatedStatus() {
    const userObj = await this._ReportInfoService.getReportInfo(['stateAdmin', 'regionalAdmin']);
    this.isAuthenticatedUser = userObj.isAuthenticated;
  }

/* class userAdminController {
  constructor($scope, $state, Search, State, $stateParams, $log, $mdMedia, User, $window, Toast, ReportInfoService) {
    'ngInject'; */
    //this.$scope = $scope;
    //this.$window = $window;
    //this.$state = $state;
    //this.$mdMedia = $mdMedia;
    //this.$stateParams = $stateParams;
    //this._Toast = Toast;
    
    // TODO - Need to figure out the following code - START
    /* toggleSearch = function() {
      recordSearch = !recordSearch;
    };
    $scope.$watch('recordSearch', function() {
      $scope.getText = $scope.recordSearch ? 'Basic Search' : 'Advanced Search';
    }); */
    // TODO - END

  resetPassword(username) {

    let apiCall = this._User.adminResetPassword(username);
    let subscribed = apiCall.subscribe({
      // Callback for success
      next: res => {
        console.log(res);
        this.toastService.showToast('Password reset!');

        subscribed.unsubscribe();
      },
      error: error => {
        console.log(error);
        this.toastService.showToast(error.data.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }

  clearSearchFields() {
    this.search = {
      firstName: '',
      lastName: '',
      username: ''
    };
  }

  showUserDetailsFn(index) {
    if (this.userIndex === index) {
      this.userIndex = -1;
    } else {
      this.userIndex = index;
    }
  }

  searchAgain() {
    this.showResults = false;
  }

  goToNewUserPage() {
    this._router.navigate(['newUser']);
  }

  userSearch() {
    this._router.navigate(['userAdmin', {
      firstName: this.search.firstName,
      lastName: this.search.lastName,
      username: this.search.username
    }]);

    let apiCall = this._User.getUsers(this.search.username, this.search.firstName, this.search.lastName);
    let subscribed = apiCall.subscribe({
      // Callback for success
      next: res => {
        console.log(res);

        let result: any = res;
        this.userList = result;
        this.totalRecords = result.length;
        this.showResults = true;

        subscribed.unsubscribe();
      },
      error: error => {
        console.log(error);
        error = error.errors;
        this.toastService.showToast(error.developerMessage, 0);

        subscribed.unsubscribe();
      }
    });
  }

  // Navigate to User Details page
  viewDetails(userId) {
    this._router.navigate(['userDetails', {
      username: userId
    }]);
  }
}

interface SearchObject {
  firstName: string,
  lastName: string,
  username: string
}