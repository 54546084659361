<div class="saved-list-modals">
  <div class="confirmDeactivateDialog" tabindex="-1">
    <div class="toolbar">
      <h2>Edit/Delete List</h2>
      <span class="flex"></span>
      <button class="md-icon-button md-button" type="button" (click)="onClose()" (keypress)="onClose()" style="margin-left: auto; margin-right: 0;">
        <i class="fa fa-times ng-scope" aria-hidden="true"></i>
      </button>
    </div>

    <div class="md-dialog-content">
      <div class="layout-row">
        <p>Edit the name of this list by entering a new list name in the “List Name” field.
          Delete this list by selecting “Delete List”.
          Any action on this list will only affect this list.
        </p>
      </div>
      <div class="center editListNameInput layout-row">
        <div class="custom-input-container required" style="width:420px">
          <custom-mat-input 
            [isdisabled]="deleteListisChecked"
            customlabel="List Name" 
            [(ngModel)]="editListName" 
            name="editListName"
            [userform]="false">
          </custom-mat-input>
        </div>
      </div>
      <div class="edit-delete-error-message">
        <p *ngIf="editListName.length > 60">List names must be 60 characters or less.</p>
        <p *ngIf="checkEditListName()">List name already exists. Enter a different name.</p>
      </div>
      <div class="layout-row deleteList">
        <mat-checkbox (click)="toggleDelete()" (keypress)="toggleDelete()" tabindex="0" type="checkbox">
          <p class="filtertext">Delete List</p>
        </mat-checkbox>
      </div>
      <p class="deleteListText" *ngIf="deleteListisChecked">
        Selecting Delete List will permanently delete this list.
        This action cannot be undone.
      </p>
    </div>

    <div class="layout-row filter-row">
      <hr class="thinHr">
      <a tabindex="0" (click)="onClose()" (keypress)="onClose()">
        <span>Cancel</span>
      </a>
      <span class="flex"></span>
      <button class="md-button right-align" type="submit" (click)="editDeleteList()"
        [disabled]="!editListName || editListName.length > 60 || editListNameExists">Submit</button>
    </div>
  </div>
</div>