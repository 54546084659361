<div class="dataAdmin-page">
  <div aria-label="Confirm Merge" class="editAddDialog filter-dialog">
    <!-- TODO - need to add *ngIf to replace ng-cloak-->
    <!-- <form ng-cloak> -->
    <form>
      <mat-toolbar>
        <div class="md-toolbar-tools toolbar md-toolbar">
          <h2 style="font-size: inherit !important; font-weight: inherit !important">Add Region</h2>
          <span style="display: flex; flex: auto"></span>
          <button class="md-button md-icon-button" (click)="closeDialog()" aria-label="close dialog">
            <i class="fa fa-times" aria-hidden="true"></i>
          </button>
        </div>
      </mat-toolbar>

      <mat-dialog-content>
        <div class="md-dialog-content">

          <div layout="column" class="layout-padding-l-r-17">
            <p>
              Please open a state below to select the location for the new region.
            </p>
            <div class="custom-input-container worklist-commentbox-padding layout-column">
              <!-- <mat-form-field class="md-block md-input-container-margins background-white">
                <mat-label class="custom-label">Name</mat-label>
                <textarea matInput class="custom-input" [(ngModel)]="addedRegionName" rows="1" md-select-on-focus></textarea>
              </mat-form-field> -->
              <div class="custom-input-container">
                <mat-form-field style="height: 70px; width: 100%;" class="example-full-width textarea">
                  <mat-label class="custom-label" [ngClass]="{'box-selected': inputField.focused || addRegionParameters.addedRegionName}"
                    for="inputFieldName">Name</mat-label>
                  <textarea matInput #inputField="matInput" style="height: 55px; border: 1px solid #888;"
                    class="comment-text" [ngClass]="{'mat-input-has-value': addRegionParameters.addedRegionName}" 
                    [(ngModel)]="addRegionParameters.addedRegionName" name="inputFieldName" maxlength="250"></textarea>
                </mat-form-field>
              </div>
              <div class="custom-input-container">
                <mat-form-field style="height: 150px; width: 100%;" class="example-full-width textarea">
                  <mat-label class="custom-label" [ngClass]="{'box-selected': inputField.focused || addRegionParameters.addedRegionDesc}"
                    for="inputFieldDesc">Description</mat-label>
                  <textarea matInput #inputField="matInput" style="height: 140px; border: 1px solid #888;"
                    class="comment-text" [ngClass]="{'mat-input-has-value': addRegionParameters.addedRegionDesc}" [(ngModel)]="addRegionParameters.addedRegionDesc"
                    name="inputFieldDesc" maxlength="250"></textarea>
                  <div class="md-errors-spacer" style="color: rgba(0,0,0,0.87);">
                    <div class="md-char-counter">{{addRegionParameters.addedRegionDesc.length}} / 250</div>
                  </div>
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </mat-dialog-content>

      <mat-dialog-actions class="layout-row" style="padding: 0px 8px">
        <hr class="thinHr">
        <a (click)="closeDialog()">
          <span>Cancel</span>
        </a>
        <span style="display: flex; flex: auto"></span>
        <button (click)="submitAddedRegion()" [disabled]="!addRegionParameters.addedRegionName" class="md-button confirmButton">
          CONFIRM
        </button>
      </mat-dialog-actions>
    </form>
  </div>
</div>
